export function validateEmail(email: string): string | null {
  if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)) {
    return 'error_invalid_email';
  }
  return null;
}

export function validatePassword(password: string): string | null {
  if (!/^(?=.*[A-Za-zåäö])[A-Za-zåäö\d!@#$*-=_+.?]{10,}$/.test(password)) {
    return 'error_incorrect_password';
  }
  return null;
}
