import { Switch, SwitchProps } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { JSX } from 'react/jsx-runtime';

import { readCurrentDarkModeState } from '../store/appSlice';

const CustomSwitch = (props: JSX.IntrinsicAttributes & SwitchProps) => {
  const isDarkMode = useSelector(readCurrentDarkModeState);

  return (
    <Switch
      {...props}
      sx={{
        width: 42,
        height: 26,
        padding: 0,

        '& .MuiSwitch-switchBase': {
          padding: 0,
          margin: 2,
          transitionDuration: '300ms',
          '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              backgroundColor: isDarkMode ? '#65C466' : '#65C466',
              opacity: 1,
              border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.5,
            },
          },
          '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
          },
          // '&.Mui-disabled .MuiSwitch-thumb': {
          //   color: !isDarkMode ? theme.palette.grey[100] : theme.palette.grey[600],
          // },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: !isDarkMode ? 0.7 : 0.3,
          },
        },
        '& .MuiSwitch-thumb': {
          boxSizing: 'border-box',
          width: 20,
          height: 20,
          mt: -1.4,
          ml: -1.2,
          border: '1px solid',
          borderColor: 'custom.paperBorder',
          backgroundColor: 'white',
        },
        '& .MuiSwitch-track': {
          borderRadius: 26 / 2,
          backgroundColor: !isDarkMode ? 'background.default' : 'rgba(255,255,255,0.4npm )',
          opacity: 1,
          border: '1px solid',
          borderColor: 'custom.paperBorder',
          // transition: theme.transitions.create(['background-color'], {
          //   duration: 500,
          // }),
        },
      }}
    />
  );
};

export default CustomSwitch;
