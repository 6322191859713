import AddRoundedIcon from '@mui/icons-material/AddRounded';
import CloseIcon from '@mui/icons-material/Close';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import { Box, Button, DialogActions } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { doc, updateDoc } from 'firebase/firestore';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import { db } from '../../firebase/firebaseConfig';
import { getCurrentResults, setResultsGoal } from '../../store/resultsSlice';
import { getCurrentUser } from '../../store/userSlice';

type Props = {
  displayDialog: boolean;
  setDisplayDialog: Dispatch<SetStateAction<boolean>>;
  title: string;
  content: string;
  callback?: () => void;
  currentGoal?: number;
};

const DialogDailyGoal = ({ title, content, displayDialog, setDisplayDialog, callback, currentGoal = 1 }: Props) => {
  const { t } = useTranslation();
  const [goal, setGoal] = useState<number>(currentGoal);

  const dispatch = useDispatch();

  const currentUser = useSelector(getCurrentUser);
  const currentResults = useSelector(getCurrentResults);

  const updateGoal = async () => {
    const userResultDoc = doc(db, 'results', `${currentUser?.uid}`);

    try {
      await updateDoc(userResultDoc, {
        goal: goal,
      });

      dispatch(setResultsGoal(goal));
      setDisplayDialog(false);
    } catch (e) {}
  };

  return (
    <Dialog
      open={Boolean(displayDialog)}
      onClose={() => {
        setDisplayDialog(false);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        {title}
        <IconButton
          aria-label="close"
          onClick={() => {
            setDisplayDialog(false);
            if (callback) {
              callback();
            }
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Typography variant="body1">{content}</Typography>

        <Box sx={{ mt: 2, mb: 4 }}>
          <Typography variant="body2">{t('dialog_set_a_goal')}</Typography>
        </Box>

        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', width: '160px' }}>
            <Button
              variant="outlined"
              aria-label="decrease goal"
              disabled={goal < 2}
              sx={{
                p: 1,
                minWidth: 46,
                borderWidth: '2px',
              }}
              onClick={() => {
                setGoal(goal - 1);
              }}
            >
              <RemoveRoundedIcon fontSize="large" />
            </Button>

            <Box
              sx={{
                pl: 2,
                pr: 2,
                pt: 2,
                pb: 2,
                width: 70,
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                gap: 0,
                borderRadius: 2,
              }}
            >
              <Typography variant="h4">{goal}</Typography>
            </Box>
            <Button
              data-testid="button-assignments-goal-increase"
              variant="outlined"
              aria-label="increase goal"
              disabled={goal >= currentResults?.assignmentsResults.length}
              sx={{
                p: 1,
                minWidth: 46,
                borderWidth: '2px',
              }}
              onClick={() => {
                setGoal(goal + 1);
              }}
            >
              <AddRoundedIcon fontSize="large" />
            </Button>
          </Box>
        </Box>
      </DialogContent>

      {callback && (
        <DialogActions sx={{ p: 4, justifyContent: 'flex-start' }}>
          <Button
            variant="outlined"
            onClick={() => {
              callback();
            }}
          >
            {t('button_cancel')}
          </Button>
          <Button
            data-testid="button-assignments-update-goal"
            variant="contained"
            onClick={() => {
              updateGoal();
            }}
          >
            {t('button_update_goal')}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default DialogDailyGoal;
