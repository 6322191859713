import { Box, Button, DialogActions } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import handClap from '../../assets/onboarding/handClap.svg';
import handClapDark from '../../assets/onboarding/handClapDark.svg';
import { readCurrentDarkModeState } from '../../store/appSlice';

type Props = {
  displayDialog: boolean;
  setDisplayDialog: Dispatch<SetStateAction<boolean>>;
  title?: string;
  content: string;
  callback?: () => void;
};

const DialogCompetitionComplete = ({ title, content, displayDialog, setDisplayDialog, callback }: Props) => {
  const { t } = useTranslation();
  const isDarkMode = useSelector(readCurrentDarkModeState);

  return (
    <Dialog
      data-testid="dialog-assignments-competition-complete"
      open={Boolean(displayDialog)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {title && <DialogTitle>{title}</DialogTitle>}

      <Box
        sx={{
          width: '100%',
          height: '40%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img
          src={isDarkMode ? handClapDark : handClap}
          alt="Hälsokuppen logotype looking like a trophy made of broccoli"
          width="220"
          height="220"
        />
      </Box>

      <DialogContent>
        <Typography variant="body1">{content}</Typography>
      </DialogContent>

      {callback && (
        <DialogActions sx={{ p: 4 }}>
          <Button
            data-testid="button-assignments-competition-complete"
            variant="contained"
            onClick={() => {
              callback();
            }}
          >
            {t('button_ok')}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default DialogCompetitionComplete;
