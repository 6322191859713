import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Box, Container, Divider, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Link from '@mui/material/Link';
import OutlinedInput from '@mui/material/OutlinedInput';
import * as DOMPurify from 'dompurify';
import React, { useState } from 'react';
import { Controller, FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import Loader from '../../components/Loader';
import DialogIntegrityPolicy from '../../components/dialogs/DialogIntegrityPolicy';
import DialogWelcome from '../../components/dialogs/DialogWelcome';
import AuthContainer from '../../components/stylish/AuthContainer';
import Logo from '../../components/stylish/Logo';
import { signInWithGoogle, signin } from '../../firebase/auth';
import { validateEmail, validatePassword } from '../../utilities/validators';

declare global {
  interface Window {
    isNativeDevice: boolean;
  }
}

const Signin: React.FC = () => {
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm();

  const [showPassword, setShowPassword] = useState(false);
  const [signinError, setSigninError] = useState('');
  const [isLoading, setIsLoading] = useState<Boolean>(false);
  const [displayDialog, setDisplayDialog] = useState<boolean>(false);
  const [displayWelcomeDialog, setDisplayWelcomeDialog] = useState<boolean>(
    !Boolean(localStorage.getItem('show-welcome')),
  );

  const { t } = useTranslation();

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    const emailError = validateEmail(data.email);
    const passwordError = validatePassword(data.password);

    if (emailError) {
      setError('email', {
        type: 'manual',
        message: t(emailError) || 'Error',
      });
    } else if (passwordError) {
      setError('password', {
        type: 'manual',
        message: t(passwordError) || 'Error',
      });
    } else {
      setIsLoading(true);
      try {
        await signin(DOMPurify.sanitize(data.email.trim()), DOMPurify.sanitize(data.password.trim()));
        setIsLoading(false);
      } catch (error) {
        if (error instanceof Object && 'message' in error) {
          const signinError = t('error_signin');
          setSigninError(signinError);
          setTimeout(() => {
            setIsLoading(false);
          }, 500);
        } else {
          setTimeout(() => {
            setIsLoading(false);
          }, 500);
          throw error;
        }
      }
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      await signInWithGoogle();
    } catch (error) {
      if (error instanceof Object && 'message' in error) {
        const signinError = t('error_signin');
        setSigninError(signinError);
      } else {
        throw error;
      }
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  function isReactNativeWebview() {
    return window.isNativeDevice;
  }

  return (
    <>
      {isLoading && <Loader />}
      <DialogIntegrityPolicy displayDialog={displayDialog} setDisplayDialog={setDisplayDialog} />
      {displayWelcomeDialog && (
        <DialogWelcome
          displayDialog={Boolean(displayWelcomeDialog)}
          callBack={() => {
            setDisplayWelcomeDialog(false);
            localStorage.setItem('show-welcome', 'false');
          }}
        />
      )}

      <Container
        data-testid="main-container"
        component="main"
        maxWidth="sm"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
        style={{
          paddingLeft: 0,
          paddingRight: 0,
        }}
      >
        <Logo />
        <AuthContainer>
          <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Typography data-testid="label-login" variant="h5" sx={{ fontWeight: 600, mt: 2, mb: 2 }}>
                {t('label_login')}
              </Typography>

              <FormControl variant="outlined" fullWidth sx={{ mt: 1, mb: 2 }}>
                <Controller
                  control={control}
                  name="email"
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field: { onChange, value, name } }) => (
                    <>
                      <label style={{ fontSize: '1rem', marginBottom: 2 }} htmlFor={name}>
                        {t('textfield_label_email')}
                      </label>
                      <OutlinedInput
                        data-testid="input-email-login"
                        sx={{ bgcolor: 'background.default' }}
                        value={value}
                        onChange={onChange}
                        name={name}
                        type="text"
                        required={true}
                        id={name}
                        autoComplete="off"
                        error={!!errors.email}
                      />
                      {errors.email && typeof errors.email.message === 'string' && (
                        <FormHelperText sx={{ ml: 0 }} error={true}>
                          {errors.email.message}
                        </FormHelperText>
                      )}
                    </>
                  )}
                />
              </FormControl>

              <FormControl variant="outlined" fullWidth sx={{ mt: 1 }}>
                <Controller
                  control={control}
                  name="password"
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field: { onChange, value, name } }) => (
                    <>
                      <label style={{ fontSize: '1rem', marginBottom: 2 }} htmlFor={name}>
                        {t('textfield_label_password')}
                      </label>
                      <OutlinedInput
                        data-testid="input-password-login"
                        sx={{ bgcolor: 'background.default' }}
                        value={value}
                        onChange={onChange}
                        name={name}
                        type={showPassword ? 'text' : 'password'}
                        required={true}
                        id={name}
                        autoComplete="off"
                        error={!!errors.password}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {errors.password && typeof errors.password.message === 'string' && (
                        <FormHelperText sx={{ ml: 0 }} error={true}>
                          {errors.password.message}
                        </FormHelperText>
                      )}
                    </>
                  )}
                />
              </FormControl>

              {signinError && (
                <Typography color="error" variant="body2" ml={2}>
                  {signinError}
                </Typography>
              )}

              <Box sx={{ mt: 2 }}>
                <Link
                  component={RouterLink}
                  to="/app/aterstall-losenord"
                  variant="body2"
                  underline="none"
                  color="secondary.main"
                  sx={{ fontWeight: 600 }}
                >
                  {t('link_forgot_password')}
                </Link>
              </Box>

              <Button
                data-testid="button-login"
                type="submit"
                fullWidth
                size="large"
                variant="contained"
                color="primary"
                sx={{
                  mt: 4,
                  mb: 2,
                }}
              >
                {t('button_login')}
              </Button>
            </form>
            {!isReactNativeWebview() && (
              <>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 3, justifyContent: 'center' }}>
                  <Divider sx={{ width: '30%' }} />
                  <Typography variant="body1" sx={{ fontWeight: 600, mt: 2, mb: 2, textAlign: 'center' }}>
                    {t('label_or')}
                  </Typography>
                  <Divider sx={{ width: '30%' }} />
                </Box>

                <Box>
                  <Button
                    onClick={handleGoogleSignIn}
                    fullWidth
                    size="large"
                    variant="contained"
                    sx={{
                      mt: 3,
                      mb: 2,
                      bgcolor: '#3F81EE',
                    }}
                  >
                    {t('button_login_with_google')}
                  </Button>

                  <Typography variant="body2" sx={{ fontSize: '12px', fontWeight: 400 }}>
                    {t('label_continue_with_google_integrity')}{' '}
                    <Link
                      underline="none"
                      onClick={() => {
                        setDisplayDialog(true);
                      }}
                      sx={{
                        '&:hover': {
                          cursor: 'pointer',
                        },
                        pl: '4',
                        fontWeight: 600,
                      }}
                      color="secondary.main"
                    >
                      {t('link_privacy_policy_2')}
                    </Link>
                    .
                  </Typography>
                </Box>
              </>
            )}
          </Box>

          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt={2}>
            <Button
              data-testid="button-tosignup"
              fullWidth
              size="large"
              variant="outlined"
              color="primary"
              sx={{
                mt: 0,
                mb: 4,
              }}
              onClick={() => {
                navigate('/app/registrera');
              }}
            >
              {t('button_create_account')}
            </Button>
          </Box>
        </AuthContainer>
      </Container>
    </>
  );
};

export default Signin;
