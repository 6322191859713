import { DocumentData } from 'firebase/firestore';

import { AssignmentResult, Results } from '../../../utilities/types';

/**
 *
 *
 */
export const getPointsOnlyArrays = (currentResults: Results | DocumentData | null) => {
  let _pointsOnlyArrays: any[] = [];
  currentResults?.assignmentsResults?.forEach((assignment: AssignmentResult, i: number) => {
    if (assignment && typeof assignment.points) {
      _pointsOnlyArrays.push(assignment.points.slice(0, -1));
    }
  });

  return _pointsOnlyArrays;
};

/**
 *
 *
 */
export const getSumPointsOnlyArrays = (_pointsOnlyArrays: number[][]) => {
  const _sumPointsOnlyArrays = [];

  for (let i = 0; i < _pointsOnlyArrays[0]?.length; i++) {
    let sum = 0;
    for (let j = 0; j < _pointsOnlyArrays.length; j++) {
      sum += _pointsOnlyArrays[j][i];
    }
    _sumPointsOnlyArrays.push(sum);
  }

  return _sumPointsOnlyArrays;
};

/**
 *
 *
 */
export const getTotalPoints = (_sumPointsOnlyArrays: number[]) => {
  return _sumPointsOnlyArrays.reduce((accumulator: any, point: number) => accumulator + point, 0);
};

/**
 *
 *
 */
export const getNrOfDaysGoalAchieved = (
  _sumPointsOnlyArrays: number[],
  currentResults: Results | DocumentData | null,
) => {
  let _nrOfDaysGoalAchieved = 0;

  _sumPointsOnlyArrays.forEach((point: any) => {
    if (currentResults && currentResults.goal) {
      if (point >= currentResults.goal) {
        _nrOfDaysGoalAchieved += 1;
      }
    }
  });

  return _nrOfDaysGoalAchieved;
};

/**
 *
 *
 */
export const getBadgeStyling = (condition: number, badgeCondition: number): {} => {
  if (condition < badgeCondition) {
    return {
      opacity: 0.4,
      filter: 'grayscale(80%)',
      width: '33.33%',
      height: '100px',
      position: 'relative',
      '&:hover': {
        cursor: 'pointer',
      },
    };
  } else {
    return {
      opacity: 1,
      filter: 'none',
      width: '33.33%',
      height: '100px',
      position: 'relative',
      '&:hover': {
        cursor: 'pointer',
      },
    };
  }
};
