import { Backdrop, Box } from '@mui/material';
import debounce from 'lodash.debounce';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import GeneralError from '../../components/GeneralError';
import MainContainer from '../../components/MainContainer';
import Snackybar from '../../components/Snackybar';
import TopBarContainer from '../../components/TopBarContainer';
import DialogCompetitionComplete from '../../components/dialogs/DialogCompetitionComplete';
import { updateAssignmentsResultsFirebase } from '../../firebase/db/results';
import { getScoresSingleTeamFirebase, updateUserScoreFirebase } from '../../firebase/db/scores';
import { updateTeamScoresFirebase } from '../../firebase/db/teamScores';
import { readCurrentDeviceState, setErrorMsg, setIsSaving } from '../../store/appSlice';
import { getCurrentCompetition } from '../../store/competitionSlice';
import { getCurrentCustomAssignment } from '../../store/customAssignmentSlice';
import { getCurrentDaysState, setActiveDay, setDays } from '../../store/daysHandlerSlice';
import { getCurrentResults, setAssignmentsResults } from '../../store/resultsSlice';
import { getCurrentUser } from '../../store/userSlice';
import { checkIfIsFuture, getTodaysDate } from '../../utilities/time';
import { AssignmentResult } from '../../utilities/types';
import AssignmentCalendarTabs from './assignmentsScreenComponents/AssignmentCalendarTabs';
import AssignmentList from './assignmentsScreenComponents/AssignmentList';
import AssignmentResults from './assignmentsScreenComponents/AssignmentResults';
import AssignmentSubmitButton from './assignmentsScreenComponents/AssignmentSubmitButton';
import CustomAssignmentCard from './assignmentsScreenComponents/CustomAssignmentCard';
import HiddenAssignmentsCard from './assignmentsScreenComponents/HiddenAssignmentCard';

type AssignmentsScreenProps = {};

const AssignmentsScreen: React.FC<AssignmentsScreenProps> = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isDesktop = useSelector(readCurrentDeviceState);

  const currentUser = useSelector(getCurrentUser);
  const currentCompetition = useSelector(getCurrentCompetition);
  const currentResults = useSelector(getCurrentResults);
  const currentCustomAssignment = useSelector(getCurrentCustomAssignment);
  const currentDaysState = useSelector(getCurrentDaysState);

  const [displayCompletionDialog, setDisplayCompletionDialog] = useState<boolean>(
    currentResults?.status === 'completed',
  );
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState<boolean>(false);

  /**
   *
   *
   */
  const debouncedSave = React.useRef(
    debounce(async (newAssignmentsResults) => {
      saveResultsOnCheck(newAssignmentsResults);
    }, 1000),
  ).current;

  /**
   *
   *
   */
  useEffect(() => {
    return () => {
      debouncedSave.cancel();
    };
  }, [debouncedSave]);

  /**
   *
   * Changing day
   *
   */
  const onDayChange = (newValue: number) => {
    if (newValue > currentDaysState.lastDayIndex || newValue === currentDaysState.activeDayIndex) {
      return;
    }
    dispatch(setActiveDay(newValue));
  };

  /**
   *
   * Go through all days and summerize scores
   */
  const calcNewScore = () => {
    let score = 0;

    currentResults?.assignmentsResults.forEach((assignment: any) => {
      assignment.points.forEach((point: any) => {
        score += point;
      });
    });

    return score;
  };

  /**
   * Check basic values for screen to work
   *
   */
  const checkValidData = () =>
    !!(
      currentCompetition &&
      Array.isArray(currentCompetition.assignmentsDetails) &&
      currentResults &&
      Array.isArray(currentResults.assignmentsResults) &&
      currentUser &&
      currentUser.uid
    );

  /**
   *
   *
   *
   */
  const calculateTeamScore = (scores: any[]) => {
    let totalScore = 0;
    let totalDays = 0;

    scores.forEach((score) => {
      totalScore += score.score;
      totalDays += score.nrOfDaysSubmitted;
    });

    return { totalScore, totalDays };
  };

  /**
   *
   * Update assignments results
   *
   */
  const updateAssignmentsResults = async () => {
    const newAssignmentsResults: AssignmentResult[] = currentResults?.assignmentsResults.map(
      (assignmentResult: AssignmentResult) => ({
        ...assignmentResult,
        points: [...assignmentResult.points, 0],
      }),
    );

    await updateAssignmentsResultsFirebase({
      userId: currentUser?.uid || '',
      status: currentCompetition?.duration - currentDaysState?.activeDayIndex === 1 ? 'completed' : 'ongoing',
      newAssignmentsResults: newAssignmentsResults,
    });

    return newAssignmentsResults;
  };

  /**
   *
   * Update user and team scores
   *
   */
  const updateUserAndTeamScores = async () => {
    if (currentDaysState?.activeDayIndex < currentCompetition?.duration || currentCompetition?.duration === 0) {
      await updateUserScoreFirebase({
        score: calcNewScore(),
        nrOfDaysSubmitted: currentResults?.assignmentsResults[0].points.length,
        userId: currentUser?.uid || '',
      });

      if (currentResults?.team) {
        const querySnapshotCompetitions = await getScoresSingleTeamFirebase({
          competitionId: currentResults?.competitionId,
          team: currentResults?.team,
        });

        const scores = querySnapshotCompetitions?.docs.map((doc) => doc.data()) || [];
        const { totalScore, totalDays } = calculateTeamScore(scores);
        await updateTeamScoresFirebase({
          competitionId: currentResults?.competitionId,
          team: currentResults?.team,
          score: totalScore,
          days: totalDays,
        });
      }
    }
  };

  /**
   *
   * Submit day
   *
   */
  const submitDay = async () => {
    if (!checkValidData()) return;

    dispatch(setIsSaving(true));

    try {
      const newAssignmentsResults = await updateAssignmentsResults();
      await updateUserAndTeamScores();

      dispatch(
        setDays({
          activeDayIndex: currentDaysState.activeDayIndex + 1,
          lastDayIndex: currentDaysState.lastDayIndex + 1,
        }),
      );

      dispatch(setAssignmentsResults(newAssignmentsResults));
      setShowSuccessSnackbar(true);

      if (currentCompetition?.duration - currentDaysState.activeDayIndex === 1) {
        setDisplayCompletionDialog(true);
      }
    } catch (error) {
      dispatch(setErrorMsg(t('error_text_general')));
    } finally {
      dispatch(setIsSaving(false));
    }
  };

  /**
   *
   *
   *
   */
  const onCheckAssignmentChange = async (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    if (!checkValidData()) return;

    dispatch(setIsSaving(true));

    const currentAssignmentPoints = currentResults?.assignmentsResults[index].points[currentDaysState.activeDayIndex];

    const newAssignmentsResults: AssignmentResult[] = currentResults?.assignmentsResults.map(
      (assignmentResult: AssignmentResult, i: number) => {
        if (i !== index) return assignmentResult;

        const updatedPoints = assignmentResult.points.map((points: any, j: number) =>
          j === currentDaysState.activeDayIndex ? (currentAssignmentPoints === 0 ? 1 : 0) : points,
        );

        return {
          ...assignmentResult,
          points: updatedPoints,
        };
      },
    );

    dispatch(setAssignmentsResults(newAssignmentsResults));
    debouncedSave(newAssignmentsResults);
  };

  /**
   *
   * Save results on check to firebase
   *
   */
  async function saveResultsOnCheck(assignmentsResults: [AssignmentResult]) {
    try {
      await updateAssignmentsResultsFirebase({
        userId: currentUser?.uid || '',
        status: 'ongoing',
        newAssignmentsResults: assignmentsResults,
      });
    } catch (error) {
      dispatch(setErrorMsg(t('error_text_general')));
    } finally {
      dispatch(setIsSaving(false));
    }
  }

  /**
   *
   * Updates the visibilaty of assignments
   *
   */
  const onDisplayAssignmentChange = (index: number | undefined) => {
    if (typeof index !== 'number') return;
    dispatch(setIsSaving(true));

    const newAssignmentsResults: AssignmentResult[] = currentResults?.assignmentsResults.map(
      (assignmentResult: AssignmentResult, i: number) => {
        if (i === index) {
          return {
            ...assignmentResult,
            displayAssignment: !assignmentResult.displayAssignment,
          };
        }
        return assignmentResult;
      },
    );

    dispatch(setAssignmentsResults(newAssignmentsResults));
    debouncedSave(newAssignmentsResults);
    window.scrollTo(0, document.body.scrollHeight);
  };

  /**
   *
   * Preparing data for rendering
   *
   */
  const isSubmitted = currentDaysState.activeDayIndex < currentDaysState.lastDayIndex;
  const todaysDate = getTodaysDate(currentCompetition?.startDate, currentDaysState.activeDayIndex);
  const isFuture = checkIfIsFuture(todaysDate);

  const customAssignment: AssignmentResult = currentResults?.assignmentsResults.find((e: any) => e.type === 'custom');
  const hasCustomUnsetAssignment = currentCompetition?.allowCustomAssignment && currentCustomAssignment === null;

  return (
    <>
      <DialogCompetitionComplete
        content={t('dialog_competition_complete')}
        displayDialog={displayCompletionDialog}
        setDisplayDialog={setDisplayCompletionDialog}
        callback={() => {
          saveResultsOnCheck(currentResults?.assignmentsResults);
          setDisplayCompletionDialog(false);
        }}
      />

      {currentCompetition ? (
        <>
          <Snackybar
            dataTestid="snackbar-assignment-submit-day"
            open={Boolean(showSuccessSnackbar)}
            onClose={() => {
              setShowSuccessSnackbar(false);
            }}
            severity="success"
            text={t('alert_text_day_submitted')}
          />

          {checkValidData() ? (
            <>
              <TopBarContainer>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    pb: isDesktop ? 4 : 0,
                    width: '100%',
                    pt: 1,
                    pr: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <AssignmentCalendarTabs
                      activeDayIndex={currentDaysState.activeDayIndex}
                      lastDayIndex={currentDaysState.lastDayIndex}
                      dayChange={onDayChange}
                      competition={currentCompetition}
                    />
                    <AssignmentSubmitButton
                      activeDayIndex={currentDaysState.activeDayIndex}
                      lastDayIndex={currentDaysState.lastDayIndex}
                      isFuture={isFuture}
                      submitDay={submitDay}
                    />
                  </Box>
                  <Box>
                    <AssignmentResults
                      assignmentsResults={currentResults?.assignmentsResults}
                      activeDayIndex={currentDaysState.activeDayIndex}
                      goal={currentResults?.goal}
                    />
                  </Box>
                </Box>
              </TopBarContainer>
              <MainContainer>
                <Box mt={32} mb={26}>
                  <Backdrop
                    sx={{
                      zIndex: '1099',
                      bgcolor: 'custom.backdrop',
                    }}
                    open={isSubmitted}
                  />

                  <AssignmentList
                    activeDayIndex={currentDaysState.activeDayIndex}
                    isSubmitted={isSubmitted}
                    assignmentsResults={currentResults?.assignmentsResults}
                    assignmentsDetails={currentCompetition?.assignmentsDetails}
                    checkBoxChange={onCheckAssignmentChange}
                    isFuture={isFuture}
                    isEditMode={false}
                    displayAssignmentChange={onDisplayAssignmentChange}
                    customAssignment={currentCustomAssignment}
                  />

                  {hasCustomUnsetAssignment && currentUser && currentResults && (
                    <CustomAssignmentCard
                      user={currentUser}
                      results={currentResults}
                      competition={currentCompetition}
                      customAssignment={customAssignment}
                    />
                  )}

                  <HiddenAssignmentsCard
                    assignmentsResults={currentResults?.assignmentsResults}
                    assignmentsDetails={currentCompetition?.assignmentsDetails}
                    displayAssignmentChange={onDisplayAssignmentChange}
                    customAssignment={currentCustomAssignment}
                  />
                </Box>
              </MainContainer>
            </>
          ) : (
            <GeneralError />
          )}
        </>
      ) : (
        <Navigate to="/app/utmaningar" replace />
      )}
    </>
  );
};

export default AssignmentsScreen;
