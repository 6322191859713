import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import { Box, Button, Chip, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import SimpleCard from '../../../components/SimpleCard';
import { AssignmentDetails, AssignmentResult, CustomAssignment } from '../../../utilities/types';

interface HiddenAssignmentsCardProps {
  assignmentsResults: [AssignmentResult];
  assignmentsDetails: [AssignmentDetails];
  displayAssignmentChange?: (index: number | undefined) => void;
  customAssignment?: CustomAssignment | null;
}

function HiddenAssignmentsCard({
  assignmentsResults,
  assignmentsDetails,
  displayAssignmentChange,
  customAssignment,
}: HiddenAssignmentsCardProps) {
  const { t, i18n } = useTranslation();
  const lang = i18n.language === 'en' ? 'en' : 'se';

  const [showHiddenAssignment, setShowHiddenAssignment] = useState<boolean>(false);

  const _tempIndexes: number[] = [];
  const hiddenAssignments: any = assignmentsResults
    .filter((assignmentResult: any, index: number) => {
      if (assignmentResult.displayAssignment === false) {
        _tempIndexes.push(index);
        return true; // Include the assignment in the filtered result
      }
      return false; // Exclude the assignment from the filtered result
    })
    .map((assignment: any, index: number) => ({
      ...assignment,
      assignmentIndex: _tempIndexes[index],
    }));

  return (
    <>
      {hiddenAssignments.length > 0 && (
        <SimpleCard styles={{ mb: 4 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
              onClick={() => {
                setShowHiddenAssignment(!showHiddenAssignment);
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Chip label={hiddenAssignments.length} sx={{ mr: 2, fontWeight: 500, fontSize: 18 }} />
                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                  {t('label_hidden_assignments')}
                </Typography>
              </Box>
              {showHiddenAssignment ? (
                <IconButton aria-label="delete">
                  <KeyboardArrowUpRoundedIcon />
                </IconButton>
              ) : (
                <IconButton aria-label="delete">
                  <KeyboardArrowDownRoundedIcon />
                </IconButton>
              )}
            </Box>
            {showHiddenAssignment && (
              <Box mt={3}>
                {hiddenAssignments.map((hiddenAssignment: any) => {
                  const details: AssignmentDetails | undefined = assignmentsDetails.find(
                    (details: AssignmentDetails) => hiddenAssignment.id === details.id,
                  );

                  let title = details?.title[lang];

                  if (hiddenAssignment.type === 'custom' && customAssignment?.title) {
                    title = customAssignment.title;
                  }
                  return (
                    <Box
                      key={hiddenAssignment.id}
                      sx={{ display: 'flex', justifyContent: 'space-between', p: 2, alignItems: 'center', mt: 2 }}
                    >
                      <Typography variant="body2" sx={{ fontWeight: 700 }}>
                        {title}
                      </Typography>
                      {displayAssignmentChange && (
                        <Button
                          size="small"
                          variant="contained"
                          onClick={() => {
                            displayAssignmentChange(hiddenAssignment?.assignmentIndex);
                          }}
                        >
                          {t('button_show_again')}
                        </Button>
                      )}
                    </Box>
                  );
                })}
              </Box>
            )}
          </Box>
        </SimpleCard>
      )}
    </>
  );
}

export default HiddenAssignmentsCard;
