import { Container } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import React from 'react';
import { useSelector } from 'react-redux';

import { readCurrentDarkModeState, readCurrentDeviceState } from '../store/appSlice';

interface TopBarContainerProps {
  children?: React.ReactNode;
  position?: 'fixed' | 'relative' | 'absolute' | 'sticky' | 'static';
  styles?: {};
}

const TopBarContainer: React.FC<TopBarContainerProps> = ({ children, position = 'fixed', styles }) => {
  const isDesktop = useSelector(readCurrentDeviceState);
  const isDarkMode = useSelector(readCurrentDarkModeState);

  return (
    <AppBar
      elevation={0}
      position={position}
      sx={{
        flexGrow: 1,
        minHeight: 64,
        width: '100%', // Fix IE 11 issue.
        mb: 0,
        pb: 0,
        pt: 2,
        pl: isDesktop ? 66 : 2,
        pr: 2,
        bgcolor: !isDarkMode && isDesktop ? 'white' : 'background.default',
        borderBottom: isDesktop ? '1px solid' : 'none',
        borderColor: 'custom.paperBorder',
        color: 'text.primary',
        zIndex: '1100',
        ...styles,
      }}
    >
      <Container maxWidth={isDesktop ? 'md' : 'sm'} style={{ paddingLeft: 0, paddingRight: 0 }}>
        <Toolbar>{children}</Toolbar>
      </Container>
    </AppBar>
  );
};

export default TopBarContainer;
