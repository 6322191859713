import DirectionsWalkRoundedIcon from '@mui/icons-material/DirectionsWalkRounded';
import Diversity1RoundedIcon from '@mui/icons-material/Diversity1Rounded';
import RamenDiningRoundedIcon from '@mui/icons-material/RamenDiningRounded';
import SelfImprovementRoundedIcon from '@mui/icons-material/SelfImprovementRounded';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import broccoflat from '../../assets/broccoflat.svg';
import mockup from '../../assets/website/mockup.png';
import Loader from '../../components/Loader';
import { getCurrentUser } from '../../store/userSlice';

// Assuming you're using React Router for navigation

const Nav = () => {
  const navigate = useNavigate();

  return (
    <AppBar position="static" elevation={0} sx={{ bgcolor: 'background.default', py: 3 }}>
      <Container maxWidth="xl">
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
            <Box
              sx={{
                width: '100%',
                height: 64,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2,
                pl: 4,
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
              onClick={() => {
                navigate('/app');
              }}
            >
              <img
                src={broccoflat}
                alt="Hälsokuppen logotype looking like a trophy made of broccoli"
                width={24}
                height={24}
              />
              <Typography
                variant="h5"
                component="h1"
                sx={{ fontFamily: '"Reem Kufi Fun", sans-serif;', pt: 1, fontWeight: 700 }}
                color="secondary.main"
                textAlign="center"
              >
                Hälsokuppen
              </Typography>
            </Box>
          </Link>
          <Box sx={{ display: 'flex', gap: 3, mr: 4 }}>
            <Button component={Link} to="/">
              Komma igång
            </Button>
            <Button component={Link} to="/resume">
              Priser
            </Button>
            <Button component={Link} to="/projects">
              Kontakt
            </Button>
            <Button component={Link} to="/app" variant="outlined">
              Logga in
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

const Content = () => {
  const aboutRef = useRef<HTMLDivElement | null>(null);

  const handleAboutClick = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    if (aboutRef.current) {
      aboutRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <Box
        sx={{
          py: 3,
          minHeight: 'calc(100vh - 64px)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: { xs: 'column-reverse', md: 'row' },
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Box sx={{ width: { xs: '90%', md: '70%' }, display: 'flex', flexDirection: 'column', gap: 4 }}>
              <Box>
                <Typography
                  variant="h2"
                  sx={{
                    fontWeight: 'bold',
                    color: '#149263',
                    fontSize: { xs: 36, sm: 46, md: 56 },
                    mt: 3,
                  }}
                >
                  Hälsosamma vanor
                </Typography>

                <Typography
                  variant="h2"
                  sx={{
                    fontWeight: 'bold',
                    color: '#0E6847',
                    mt: -2,
                    fontSize: { xs: 50, sm: 60, md: 80 },
                  }}
                >
                  Tillsammans
                </Typography>
              </Box>

              <Typography variant="h5" component="p" sx={{ fontSize: { xs: 20, sm: 22, md: 24 } }}>
                Boosta hälsa och teamanda på företaget med Hälsokuppen! Vår lättanvända app erbjuder en inkluderande
                hälsoutmaning med aktiviteter som sträcker sig bortom endast motion och rörelse.
              </Typography>

              <Box
                sx={{
                  mt: 6,
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'row' },
                  alignItems: 'center',
                  gap: 3,
                  '& > :not(style)': { width: { xs: '100%', sm: 'auto' } },
                }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  component={Link}
                  to="/app"
                  sx={{ width: { xs: '100%', sm: 'auto' } }}
                >
                  Så funkar det
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAboutClick}
                  size="large"
                  sx={{ width: { xs: '100%', sm: 'auto' } }}
                >
                  Kom igång
                </Button>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: 'flex', justifyContent: 'center', mr: { md: 20 } }}>
              <img src={mockup} alt="" width="100%" style={{ maxWidth: '800px' }} />
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/**
       *
       *
       *  Categories
       *
       *
       */}
      <Box sx={{ py: 12 }}>
        <Container>
          <Grid container spacing={8}>
            {[
              {
                icon: <SelfImprovementRoundedIcon sx={{ fontSize: 48 }} />,
                heading: 'Vila & Reflektion',
                text: 'Knoppen är lika viktig som kroppen, ta tid för stillhet, reflektion. Exempelvis meditation, yoga eller skriva dagbok.',
              },
              {
                icon: <DirectionsWalkRoundedIcon sx={{ fontSize: 48 }} />,
                heading: 'Rörelse',
                text: 'Motion, träning och att undika för mycket stillasittande under dagen.',
              },
              {
                icon: <RamenDiningRoundedIcon sx={{ fontSize: 48 }} />,
                heading: 'Äta näringsrikt',
                text: 'Öka intaget av näringsrika grönsaker och frukter.',
              },
              {
                icon: <Diversity1RoundedIcon sx={{ fontSize: 48 }} />,
                heading: 'Socialt',
                text: 'Ta hand och boosta varandra, hålla kontakten.',
              },
            ].map((item, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    py: 4,
                    px: 8,
                    border: '1px solid',
                    borderColor: 'divider',
                    borderRadius: 1,
                    bgcolor: 'white',
                    height: '400px',
                  }}
                >
                  {item.icon}
                  <Typography variant="h6" component="h3" sx={{ mt: 2 }}>
                    {item.heading}
                  </Typography>
                  <Typography variant="body1" sx={{ mt: 1 }}>
                    {item.text}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/**
       *
       *
       *  About
       *
       *
       */}
      <Box
        ref={aboutRef}
        sx={{
          py: 10,
          minHeight: '50vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          bgcolor: '#EAEAEA',
          borderRadius: 4,
        }}
      >
        <Container>
          <Typography variant="h4" component="h3" sx={{ fontWeight: 'bold', mb: 4, textAlign: 'center' }}>
            Om Hälsokuppen
          </Typography>
          <Typography variant="h6" sx={{ fontWeight: 'light', mb: 4, textAlign: 'center' }}>
            Lagom - inte för mycket, inte för lite. Hälsokuppen är en hälsoutmaning för företag som vill främja olika
            personer och förutsättningar. Vi tror på att skapa en inkluderande och hållbar hälsokultur som passar alla.
            <br />
            <br />
          </Typography>
        </Container>
      </Box>

      {/**
       *
       *
       *  Categories
       *
       *
       */}
      <Box sx={{ py: 8 }}>
        <Container>
          <Typography variant="body1" color="text.secondary" fontWeight={500} align="center">
            Copyright &copy; Hälsokuppen {new Date().getFullYear()}
          </Typography>
        </Container>
      </Box>

      {/**
       *
       *
       *  Kontakt
       *
       *
       */}
      <Box
        ref={aboutRef}
        sx={{
          py: 10,
          minHeight: '50vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          bgcolor: '#EAEAEA',
          borderRadius: 4,
        }}
      >
        <Container>
          <Typography variant="h4" component="h3" sx={{ fontWeight: 'bold', mb: 4, textAlign: 'center' }}>
            Kontakt
          </Typography>
          <Typography variant="h6" sx={{ fontWeight: 'light', mb: 4, textAlign: 'center' }}>
            Lagom - inte för mycket, inte för lite. Hälsokuppen är en hälsoutmaning för företag som vill främja olika
            personer och förutsättningar. Vi tror på att skapa en inkluderande och hållbar hälsokultur som passar alla.
            <br />
            <br />
          </Typography>
        </Container>
      </Box>
    </>
  );
};

const HomeScreen = () => {
  const navigate = useNavigate();
  const currentUser = useSelector(getCurrentUser);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (currentUser) {
      navigate('/app', { replace: true });
    }
    setIsLoading(false);
  }, [currentUser, navigate]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Nav />
          <Container maxWidth="xl">
            <Content />
          </Container>
        </>
      )}
    </>
  );
};

export default HomeScreen;
//  <Typography
//               variant="h4"
//               component="h1"
//               sx={{ fontWeight: 'bold', textAlign: { xs: 'center', md: 'left' } }}
//               color="secondary"
//             >
//               Hälsokuppen
//             </Typography> <Typography
//               variant="h2"
//               sx={{
//                 fontWeight: 'bold',
//                 color: '#117D55',
//                 mt: -2,
//                 fontSize: { xs: 48, sm: 58, md: 68 },
//                 textAlign: { xs: 'center', md: 'left' },
//               }}
//             >
//               Hållbara
//             </Typography>
