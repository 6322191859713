import { DocumentData, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';

import { db } from '../firebaseConfig';

/**
 *
 * fetch all teamScores
 *
 */
export const getAllTeamScoresFirebase = async (competitionId: string): Promise<DocumentData | undefined> => {
  const allTeamScoresSnap = await getDoc(doc(db, 'teamScores', competitionId));

  if (allTeamScoresSnap?.exists() && allTeamScoresSnap.data()) {
    return allTeamScoresSnap.data();
  }

  return undefined;
};

/**
 *
 * Set new teamScores
 *
 */
type TeamScoreData = {
  competitionId: string;
  teamScores: DocumentData;
};

export const setTeamScoresFirebase = async ({ competitionId, teamScores }: TeamScoreData): Promise<string> => {
  await setDoc(doc(db, 'teamScores', competitionId), teamScores);

  return 'team-score-set';
};

/**
 *
 * Update existing team score
 *
 */
type NewTeamScoreData = {
  competitionId: string;
  team: string;
  days: number;
  score: number;
};

export const updateTeamScoresFirebase = async ({
  competitionId,
  team,
  days,
  score,
}: NewTeamScoreData): Promise<string> => {
  const allTeamScores = await getAllTeamScoresFirebase(competitionId);

  if (allTeamScores) {
    const teamScoresDoc = await doc(db, 'teamScores', competitionId);

    await updateDoc(teamScoresDoc, {
      [team]: {
        days: days,
        score: score,
      },
    });
  }

  return 'team-score-updated';
};
