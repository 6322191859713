import { Alert, AlertColor, Snackbar } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import { readCurrentDeviceState } from '../store/appSlice';

interface SnackybarProps {
  dataTestid: string;
  open: boolean;
  autoHideDuration?: number;
  onClose: () => void;
  severity: string;
  text: string | null;
}

const Snackybar: React.FC<SnackybarProps> = ({
  dataTestid,
  open,
  autoHideDuration = 4000,
  onClose,
  severity,
  text,
}) => {
  const isDesktop = useSelector(readCurrentDeviceState);

  return (
    <Snackbar
      data-testid={dataTestid}
      sx={{
        marginBottom: isDesktop ? 0 : 22,
        minWidth: isDesktop ? 600 : 'auto',
        ml: isDesktop ? 30 : 0,
      }}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert
        variant="filled"
        severity={severity as AlertColor}
        onClose={onClose}
        sx={{
          width: '100%',
          minHeight: isDesktop ? 80 : 'auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {text}
      </Alert>
    </Snackbar>
  );
};

export default Snackybar;
