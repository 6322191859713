import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { AssignmentDetails, AssignmentResult } from '../../utilities/types';

type Props = {
  selectedAssignment: {
    assignmentResult: AssignmentResult;
    assignmentDetails: AssignmentDetails | undefined;
    dbIndex: number;
  } | null;
  displayDialog: boolean;
  setDisplayDialog: Dispatch<SetStateAction<boolean>>;
  displayAssignmentChange?: (index: number | undefined) => void;
};

const DialogAssignment = ({ selectedAssignment, displayDialog, setDisplayDialog, displayAssignmentChange }: Props) => {
  const { i18n, t } = useTranslation();

  const lang = i18n.language === 'en' ? 'en' : 'se';

  return (
    <Dialog
      open={Boolean(displayDialog)}
      onClose={() => {
        setDisplayDialog(false);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        {selectedAssignment?.assignmentDetails && selectedAssignment?.assignmentDetails.title[lang]}
        <IconButton
          aria-label="close"
          onClick={() => {
            setDisplayDialog(false);
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        {selectedAssignment?.assignmentDetails && (
          <>
            <Typography variant="body1" sx={{ mb: 2 }}>
              {selectedAssignment?.assignmentDetails.description[lang]}
            </Typography>
            <Typography variant="body2">{selectedAssignment?.assignmentDetails.information[lang]}</Typography>
          </>
        )}
      </DialogContent>
      <DialogActions sx={{ p: 4 }}>
        {displayAssignmentChange && (
          <Button
            disableRipple
            variant="outlined"
            autoFocus
            onClick={() => {
              if (displayAssignmentChange) {
                displayAssignmentChange(selectedAssignment?.dbIndex);
                setDisplayDialog(false);
              }
            }}
          >
            {t('button_hide_assignment')}
          </Button>
        )}
        <Button
          disableRipple
          variant="contained"
          autoFocus
          onClick={() => {
            setDisplayDialog(false);
          }}
        >
          {t('button_close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogAssignment;
