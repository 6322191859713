import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { Box, Button, IconButton, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { DocumentData } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import Loader from '../../components/Loader';
import MainContainer from '../../components/MainContainer';
import TopBar from '../../components/TopBar';
import { getCompetitionsFirebase } from '../../firebase/db/competitions';
import { readCurrentDeviceState, setErrorMsg, setShowSettingsDrawer } from '../../store/appSlice';
import { getCurrentCompetition } from '../../store/competitionSlice';
import { setJoinCompetitionData } from '../../store/joinCompetitionSlice';
import { shortenString } from '../../utilities/stringHandling';
import { checkTimeDifference } from '../../utilities/time';
import { Competition } from '../../utilities/types';

type CompetitionsScreenProps = {};

const CompetitionsScreen: React.FC<CompetitionsScreenProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isDesktop = useSelector(readCurrentDeviceState);
  const currentCompetition = useSelector(getCurrentCompetition);

  const [competitions, setCompetitions] = useState<Array<Competition | DocumentData> | null>(null);
  const [isLoading, setIsLoading] = useState<Boolean>(true);

  /**
   *  Lifecycle
   */
  useEffect(() => {
    if (currentCompetition) {
      return navigate('/app/aktiviteter');
    }

    const fetchCompetitions = async () => {
      try {
        const querySnapshotCompetitions = await getCompetitionsFirebase();

        const fetchedCompetitions = (querySnapshotCompetitions?.docs || []).map((doc: any) => ({
          competitionId: doc.id,
          ...(doc.data() as Competition | DocumentData),
        }));

        const CompetitionsSortedByDate = fetchedCompetitions.sort((a: any, b: any) =>
          new Date(a.startDate).getTime() < new Date(b.startDate).getTime() ? 1 : -1,
        );

        setCompetitions(CompetitionsSortedByDate);
      } catch (e) {
      } finally {
        setTimeout(() => {
          setIsLoading(false);
        }, 300);
      }
    };

    fetchCompetitions();
  }, [currentCompetition, dispatch, navigate, t]);

  /**
   *
   * Navigate to the competition
   *
   */
  const navigateToCompetition = (competition: Competition | DocumentData) => {
    if (checkTimeDifference(competition.startDate) >= competition.registrationOpen) {
      dispatch(setErrorMsg(t('error_text_registration_closed')));
      return;
    }

    if (competition.passCode !== null) {
      dispatch(setJoinCompetitionData({ currentJoinCompetitionData: { ...competition, goal: 0 } }));
      navigate('/app/utmaning-losenord', {
        state: competition,
      });
    } else {
      dispatch(setJoinCompetitionData({ currentJoinCompetitionData: { ...competition, goal: 0 } }));
      navigate('/app/utmaning-valkommen');
    }
  };

  return (
    <>
      <TopBar
        title={t('title_choose_competition')}
        position="static"
        rightAction={
          !isDesktop && (
            <IconButton
              edge="start"
              data-testid="button-settings"
              aria-label="settings"
              onClick={() => {
                dispatch(setShowSettingsDrawer(true));
              }}
            >
              <MenuRoundedIcon fontSize="medium" />
            </IconButton>
          )
        }
      />

      <MainContainer>
        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
          <Button
            data-testid="button-halsokuppen-info"
            variant="outlined"
            color="secondary"
            size="small"
            sx={{ mb: 1, mt: 4 }}
            onClick={() => {
              navigate('/app/about');
            }}
            endIcon={<InfoOutlinedIcon />}
          >
            {t('button_halsokuppen_info')}
          </Button>
        </Box>

        {isLoading ? (
          <Loader />
        ) : (
          <Box mt={3}>
            {competitions &&
              competitions.map((competition) => (
                <Box
                  data-testid="container-competitions-screen"
                  key={competition.competitionName}
                  onClick={() => {
                    navigateToCompetition(competition);
                  }}
                  sx={{
                    mb: 4,
                    borderRadius: 3,
                    py: 3,
                    px: 4,
                    border: '1px solid',
                    borderColor: 'custom.paperBorder',
                    bgcolor: 'background.paper',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    opacity: checkTimeDifference(competition.startDate) >= competition.registrationOpen ? 0.5 : 1,
                    '&:hover': {
                      cursor: 'pointer',
                      borderColor: 'primary.main',
                    },
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box>
                      <Typography variant="body1" sx={{ mb: 0, fontWeight: 700 }}>
                        {isDesktop ? competition.competitionName : shortenString(competition.competitionName, 22)}
                      </Typography>

                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, mt: 1 }}>
                        {checkTimeDifference(competition.startDate) >= 0 ? (
                          <Typography variant="caption" fontSize="0.7rem">
                            {`${t('label_started')}: `}
                            <span style={{ fontWeight: 600 }}>
                              {`${dayjs(Date.parse(competition.startDate)).format('D MMM')}`}
                            </span>
                          </Typography>
                        ) : (
                          <Typography variant="caption" fontSize="0.7rem" mb={-1}>
                            {`${t('label_starting')}: `}
                            <span style={{ fontWeight: 600 }}>
                              {`${dayjs(Date.parse(competition.startDate)).format('D MMM')}`}
                            </span>
                          </Typography>
                        )}

                        {checkTimeDifference(competition.startDate) < competition.registrationOpen && (
                          <Typography variant="caption">
                            {`${t('label_duration')}:`}{' '}
                            <span style={{ fontWeight: 600 }}>
                              {competition.duration === 0
                                ? t('label_ongoing')
                                : `${competition.duration} ${t('label_days')}`}
                            </span>
                          </Typography>
                        )}

                        <Typography variant="caption">
                          {`${t('label_participants')}:`}{' '}
                          <span style={{ fontWeight: 600 }}>
                            {competition.maxNrOfParticipants !== 0
                              ? `${competition.nrOfParticipants}/${competition.maxNrOfParticipants}`
                              : competition.nrOfParticipants}
                          </span>
                        </Typography>

                        {checkTimeDifference(competition.startDate) >= competition.registrationOpen && (
                          <Typography variant="caption" sx={{ fontWeight: 700 }}>
                            {t('label_registration_closed')}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 2,
                      alignItems: 'center',
                    }}
                  >
                    {competition.passCode && <LockRoundedIcon />}
                    <IconButton sx={{ mr: 0, pr: 0 }}>
                      <ArrowForwardIosRoundedIcon
                        data-testid={`button-${competition.competitionName}`}
                        fontSize="small"
                      />
                    </IconButton>
                  </Box>
                </Box>
              ))}
          </Box>
        )}
      </MainContainer>
    </>
  );
};

export default CompetitionsScreen;
