import dayjs from 'dayjs';

export const checkTimeDifference = (date: string): number => {
  const today = dayjs().startOf('day').add(2, 'hours');
  const diff = today.diff(dayjs(Date.parse(date)), 'days');

  return diff;
};

export const getTodaysDate = (startDate: string, activeDayIndex: number): string => {
  const todayDate = dayjs(new Date(startDate as string))
    .add(activeDayIndex, 'd')
    .startOf('day')
    .add(2, 'hours')
    .toString();

  return todayDate;
};

export const checkIfIsFuture = (todayDate: string): boolean => {
  if (checkTimeDifference(todayDate) < 0) {
    return true;
  } else {
    return false;
  }
};
