import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { IconButton, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import DialogAssignment from '../../../components/dialogs/DialogAssignment';
import ListCard from '../../../components/stylish/ListCard';
import { setErrorMsg } from '../../../store/appSlice';
import { shortenString } from '../../../utilities/stringHandling';
import { AssignmentDetails, AssignmentResult, CustomAssignment } from '../../../utilities/types';
import AssignmentStreak from './AssignmentStreak';

export interface AssignmentListProps {
  checkBoxChange: (event: React.ChangeEvent<HTMLInputElement>, index: number) => void;
  displayAssignmentChange?: (index: number | undefined) => void;
  assignmentsResults: [AssignmentResult];
  assignmentsDetails: [AssignmentDetails];
  activeDayIndex: number;
  isSubmitted: boolean;
  isEditMode: boolean;
  isFuture: boolean;
  customAssignment?: CustomAssignment | null;
}

const AssignmentList: React.FC<AssignmentListProps> = ({
  checkBoxChange,
  assignmentsResults,
  assignmentsDetails,
  activeDayIndex,
  isSubmitted,
  isFuture,
  displayAssignmentChange,
  customAssignment,
}) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const isSmallMobile = useMediaQuery('(max-width:405px)');

  const [displayDialog, setDisplayDialog] = useState<boolean>(false);
  const [selectedAssignment, setSelectedAssignment] = useState<{
    assignmentResult: AssignmentResult;
    assignmentDetails: AssignmentDetails | undefined;
    dbIndex: number;
  } | null>(null);

  const lang = i18n.language === 'en' ? 'en' : 'se';

  const showDialog = (
    assignmentResult: AssignmentResult,
    assignmentDetails: AssignmentDetails | undefined,
    i: number,
  ) => {
    setSelectedAssignment({ assignmentResult: assignmentResult, assignmentDetails: assignmentDetails, dbIndex: i });
    setDisplayDialog(true);
  };

  const commonListCardStyles = {
    px: 5,
    py: 2,
    '&:hover': {
      cursor: 'pointer',
    },
  };
  /**
   *
   *
   *
   */
  return (
    <>
      <DialogAssignment
        selectedAssignment={selectedAssignment}
        displayDialog={displayDialog}
        setDisplayDialog={setDisplayDialog}
        displayAssignmentChange={displayAssignmentChange}
      />

      <List sx={{ display: 'flex', flexDirection: 'column' }}>
        {assignmentsResults &&
          assignmentsDetails &&
          assignmentsResults.map((assignment, i) => {
            if (!assignment.displayAssignment) return null;

            if (assignment.type === 'custom' && customAssignment === null) return null;

            const details = assignmentsDetails.find((details) => assignment.id === details.id);
            let title = details?.title[lang];
            let description = details?.description[lang];

            if (assignment.type === 'custom' && customAssignment?.title) {
              title = customAssignment.title;
            }

            return (
              <ListCard
                styles={
                  assignmentsResults[i].points[activeDayIndex] === 1
                    ? {
                        ...commonListCardStyles,
                        borderColor: 'secondary.main',
                      }
                    : {
                        ...commonListCardStyles,
                      }
                }
                key={assignment.id}
                onClick={(event: React.ChangeEvent<HTMLInputElement>) => {
                  if (!isFuture) {
                    checkBoxChange(event, i);
                  } else {
                    dispatch(setErrorMsg(t('error_text_too_soon')));
                  }
                }}
              >
                <ListItemText
                  primary={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box
                        sx={{
                          width: '4px',
                          height: '52px',
                          borderRadius: '4px',
                          bgcolor:
                            assignmentsResults[i].points[activeDayIndex] === 1 ? 'secondary.main' : details?.color,
                          opacity: isFuture ? '.1' : '.8',
                          ml: -3,
                          mr: 3,
                          mt: -2,
                          mb: -2,
                        }}
                      />
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Typography
                            variant="body1"
                            sx={{
                              fontWeight: 500,
                              padding: 2,
                              paddingTop: 1,
                              paddingBottom: 1,
                              borderRadius: 2,
                              '&:hover': {
                                cursor: 'pointer',
                                bgcolor: 'background.default',
                              },
                            }}
                            onClick={(event) => {
                              event.stopPropagation();
                              showDialog(
                                assignment,
                                assignmentsDetails.find((details) => assignment.id === details?.id),
                                i,
                              );
                            }}
                          >
                            {title}
                          </Typography>
                          {
                            <IconButton
                              onClick={(event) => {
                                event.stopPropagation();
                                showDialog(assignment, details, i);
                              }}
                              sx={{
                                ml: -2,
                              }}
                              size="medium"
                            >
                              <InfoOutlinedIcon fontSize="small" />
                            </IconButton>
                          }
                        </Box>
                        <Typography
                          variant="caption"
                          sx={{
                            paddingLeft: 2,
                            mt: -2,
                            fontWeight: 400,
                            '&:hover': {
                              cursor: 'pointer',
                              bgcolor: 'background.default',
                            },
                          }}
                          onClick={(event) => {
                            event.stopPropagation();
                            showDialog(
                              assignment,
                              assignmentsDetails.find((details) => assignment.id === details?.id),
                              i,
                            );
                          }}
                        >
                          {isSmallMobile ? shortenString(description as string, 34) : description}
                        </Typography>
                      </Box>
                    </Box>
                  }
                />

                {!isSubmitted && (
                  <AssignmentStreak
                    assignmentResult={assignmentsResults[i]}
                    assignmentPoint={assignmentsResults[i].points[activeDayIndex]}
                  />
                )}

                <Checkbox
                  data-testid={`checkbox-assignment-${
                    assignmentsDetails.find((details) => assignment.id === details?.id)?.index
                  }`}
                  disabled={isFuture}
                  name={`assignment ${i}`}
                  edge="end"
                  color="secondary"
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                  checked={assignmentsResults[i].points[activeDayIndex] === 0 ? false : true}
                  inputProps={{ 'aria-labelledby': 'label' }}
                />
              </ListCard>
            );
          })}
      </List>
    </>
  );
};

export default AssignmentList;
