import DisplaySettingsRoundedIcon from '@mui/icons-material/DisplaySettingsRounded';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import InsertChartRoundedIcon from '@mui/icons-material/InsertChartRounded';
import LogoutIcon from '@mui/icons-material/Logout';
import ReorderRoundedIcon from '@mui/icons-material/ReorderRounded';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import broccoflat from '../assets/broccoflat.svg';
import { signout } from '../firebase/auth';
import { getNavigationItems } from '../routing/navigationItems';
import { setShowSettingsDrawer } from '../store/appSlice';
import { getCurrentCompetition, unsetCompetition } from '../store/competitionSlice';
import { unsetCustomAssignment } from '../store/customAssignmentSlice';
import { unsetDays } from '../store/daysHandlerSlice';
import { getCurrentResults } from '../store/resultsSlice';
import { getCurrentUser } from '../store/userSlice';
import { shortenString } from '../utilities/stringHandling';
import DialogCompetitionLeave from './dialogs/DialogCompetitionLeave';

type SideBarNavigationProps = {};

const SideBarNavigation: React.FC<SideBarNavigationProps> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const currentUser = useSelector(getCurrentUser);
  const currentCompetition = useSelector(getCurrentCompetition);
  const currentResults = useSelector(getCurrentResults);

  const [displayDialog, setDisplayDialog] = useState<boolean>(false);

  const resetCompetition = () => {
    dispatch(unsetCompetition());
    dispatch(unsetCustomAssignment());
    dispatch(unsetDays());
  };

  const logout = () => {
    resetCompetition();
    signout();
  };

  if (!currentUser) return null;

  const navigationItems = currentCompetition
    ? getNavigationItems({ currentCompetition: currentCompetition, currentResults: currentResults })
    : [];

  const drawer = (
    <div>
      <DialogCompetitionLeave
        title={t('title_confirm_leave_competition')}
        content={t('dialog_confirm_leave_competition')}
        displayDialog={displayDialog}
        setDisplayDialog={setDisplayDialog}
        currentResults={currentResults}
        currentUser={currentUser}
      />

      <Toolbar sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', p: 5 }}>
        <Box
          sx={{
            width: '100%',
            height: 64,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2,
            '&:hover': {
              cursor: 'pointer',
            },
          }}
          onClick={() => {
            navigate('/app');
          }}
        >
          <img
            src={broccoflat}
            alt="Hälsokuppen logotype looking like a trophy made of broccoli"
            width={24}
            height={24}
          />
          <Typography
            variant="h5"
            component="h1"
            sx={{ fontFamily: '"Reem Kufi Fun", sans-serif;', pt: 1, fontWeight: 700 }}
            color="secondary.main"
            textAlign="center"
          >
            Hälsokuppen
          </Typography>
        </Box>
      </Toolbar>
      <Divider />

      {currentCompetition && (
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              p: 4,
            }}
          >
            <Typography variant="caption">{t('label_active_competition')}</Typography>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant="body1" fontWeight="bold">
                {shortenString(currentCompetition?.competitionName, 17)}
              </Typography>

              <IconButton
                aria-label="leave competition"
                onClick={() => {
                  setDisplayDialog(true);
                }}
                color="error"
              >
                <ExitToAppIcon />
              </IconButton>
            </Box>
          </Box>
          <Divider />
        </>
      )}

      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
        <List>
          {navigationItems.map((navigationItem, i) => {
            if (!navigationItem) return null;
            return (
              <ListItem key={navigationItem.label} disablePadding>
                <ListItemButton
                  data-testid={`bottom-navigation-button-${i}`}
                  component={Link}
                  to={navigationItem.route}
                  selected={location?.pathname === navigationItem.route}
                >
                  <ListItemIcon>
                    {location?.pathname === navigationItem.route
                      ? navigationItem.iconActive
                      : navigationItem.iconInActive}
                  </ListItemIcon>
                  <ListItemText primary={t(navigationItem.label)} />
                </ListItemButton>
              </ListItem>
            );
          })}

          {!currentCompetition && (
            <ListItem key={t('tab_competititons')} disablePadding>
              <ListItemButton
                data-testid={`bottom-navigation-button-${4}`}
                component={Link}
                to="/app/utmaningar"
                selected={location?.pathname === '/app/utmaningar'}
              >
                <ListItemIcon>
                  {location?.pathname === '/app/utmaningar' ? (
                    <ReorderRoundedIcon sx={{ fontSize: 28 }} />
                  ) : (
                    <ReorderRoundedIcon sx={{ fontSize: 28 }} />
                  )}
                </ListItemIcon>
                <ListItemText primary={t('tab_competititons')} />
              </ListItemButton>
            </ListItem>
          )}
          {currentUser.role === 'owner' && (
            <ListItem key={t('tab_owner')} disablePadding>
              <ListItemButton
                data-testid={`bottom-navigation-button-${3}`}
                component={Link}
                to="/app/utmaningsinfo"
                selected={location?.pathname === '/app/utmaningsinfo'}
              >
                <ListItemIcon>
                  {location?.pathname === '/app/utmaningsinfo' ? (
                    <DisplaySettingsRoundedIcon sx={{ fontSize: 28 }} />
                  ) : (
                    <DisplaySettingsRoundedIcon sx={{ fontSize: 28 }} />
                  )}
                </ListItemIcon>
                <ListItemText primary={t('tab_owner')} />
              </ListItemButton>
            </ListItem>
          )}
          {currentUser.role === 'admin' && (
            <ListItem key={t('tab_admin')} disablePadding>
              <ListItemButton
                data-testid={`bottom-navigation-button-admin`}
                component={Link}
                to="/app/admin"
                selected={location?.pathname === '/app/admin'}
              >
                <ListItemIcon>
                  {location?.pathname === '/app/admin' ? (
                    <InsertChartRoundedIcon sx={{ fontSize: 28 }} />
                  ) : (
                    <InsertChartOutlinedIcon sx={{ fontSize: 28 }} />
                  )}
                </ListItemIcon>
                <ListItemText primary={t('tab_admin')} />
              </ListItemButton>
            </ListItem>
          )}

          <ListItem disablePadding>
            <ListItemButton
              data-testid={`sidebar-navigation-button-settings`}
              component={Button}
              onClick={() => {
                dispatch(setShowSettingsDrawer(true));
              }}
            >
              <ListItemIcon>
                <SettingsOutlinedIcon sx={{ fontSize: 28 }} />
              </ListItemIcon>
              <ListItemText primary={t('tab_settings')} />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton data-testid="sidebar-navigation-button-logout" component={Button} onClick={logout}>
              <ListItemIcon>
                <LogoutIcon sx={{ fontSize: 28 }} />
              </ListItemIcon>
              <ListItemText primary={t('tab_logout')} />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </div>
  );

  return (
    <>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
        }}
        open
      >
        {drawer}
      </Drawer>
    </>
  );
};

export default SideBarNavigation;
