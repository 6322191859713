import React from 'react';
import { Navigate } from 'react-router-dom';

interface PublicRouteProps {
  authenticated: boolean;
  children: JSX.Element;
}

const PublicRoute: React.FC<PublicRouteProps> = ({ authenticated, children }) => {
  if (authenticated) {
    return <Navigate to="/app/utmaningar" replace />;
  }

  return <>{children}</>;
};

export default PublicRoute;
