import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import React, { Dispatch, SetStateAction } from 'react';

type Props = {
  displayDialog: boolean;
  setDisplayDialog: Dispatch<SetStateAction<boolean>>;
};

const DialogIntegrityPolicy = ({ displayDialog, setDisplayDialog }: Props) => {
  return (
    <Dialog
      open={Boolean(displayDialog)}
      onClose={() => {
        setDisplayDialog(false);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setDisplayDialog(false);
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box p={4}>
          <Typography variant="h6">Senast uppdaterad: 17 Februari, 2023</Typography>
          <Box mt={2}>
            <Typography variant="body1">
              I denna integritetspolicy finns information om bland annat hur Hälsokuppen (härefter refererat till ”vi”,
              ”oss” ”vår”, ”våra”, ”Hälsokuppen”) behandlar personaktiviteter som vi får tillgång till, i samband med
              att en fysisk person (härefter kallad ”du”, ”dig”, ”Användare”) kontaktar oss eller använder vår
              applikation Hälsokuppen (härefter kallad ”Applikationen”).
            </Typography>
            <Typography variant="body1">
              Syftet med denna integritetspolicy är att säkerställa att vi behandlar personaktiviteter enligt GDPR.
              Genom att godkänna denna integritetspolicy vid skapande ett användarkonto i Applikationen godkänner du att
              vår behandling av personaktiviteter sker i enlighet med denna integritetspolicy. 
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography
              sx={{
                mt: 2,
              }}
              variant="h6"
            >
              Insamling av personaktiviteter:
            </Typography>
            <Typography variant="body1">
              <ul>
                <li>
                  När en användare registrerar sig för vår applikation kommer vi att samla in grundläggande information
                  som användarnamn och e-postadress.{' '}
                </li>
                <li>
                  Vi kan också samla in annan information som användaren väljer att lägga till i sin profil, till
                  exempel en profilbild och kontaktaktiviteter.
                </li>
                <li>
                  Vid deltagande i en utmaning i applikationen anger användaren olika aktiveteter som användaren utför.
                </li>
                <li>
                  Dessa aktiviteter behövs för att kunna möjliggöra inloggning och att utmaningarna i applikationen
                  skall kunna gå framåt.
                </li>
              </ul>
            </Typography>
            <Typography
              sx={{
                mt: 2,
              }}
              variant="h6"
            >
              Användning av personaktiviteter:
            </Typography>
            <Typography
              sx={{
                mt: 2,
              }}
              variant="body1"
            >
              <ul>
                <li>
                  Vi kan att använda den insamlade informationen för att tillhandahålla och förbättra våra tjänster,
                  samt för att kontakta användaren i samband med våra tjänster.
                </li>
                <li>
                  Vi kan också att använda personaktiviteterna för att uppfylla våra juridiska och regulatoriska
                  skyldigheter.
                </li>
                <li>
                  Aktiviteterna som användaren anger är endast synliga för den egna användaren och endast den
                  sammanlagda poängen är synlig för övriga användare.
                </li>
              </ul>
            </Typography>
            <Typography variant="h6">Lagringstid</Typography>
            <Typography variant="body1">
              Vi kan lagra dina personaktiviteter så länge som det är nödvändigt för att fullgöra de ändamål som de blev
              insamlade för. När personaktiviteterna inte längre behöver lagras, kommer de att bli raderade. Vi kan
              lagra personaktiviteter under en längre tid vid behov, exempelvis för att vi ska tillvarata våra rättsliga
              intressen, förhindra och/eller anmäla missbruk, bedrägeri eller brott som sker via Applikationen, uppfylla
              våra rättsliga förpliktelser samt för att i övrigt följa gällande lagstiftning och myndighetsbeslut. I
              sådana fall bevaras enbart nödvändiga personaktiviteter.
            </Typography>
            <Typography variant="body1">
              De personaktiviteter som är kopplade till ett specifikt användarkonto i Applikationen, lagras så länge som
              det användarkontot är aktivt och upp till trettio (30) dagar efter avslutat användarkonto (backuplagring).
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="h5">Säkerhet</Typography>
            <Typography
              sx={{
                mt: 2,
              }}
              variant="body1"
            >
              Vår applikation använder Firebase Authentication och Firebase Real Time Database för att hantera
              användaridentiteter och data. Google Firebase är en etablerad och pålitlig leverantör av backend för
              mobila tjänster, och levererar hög driftsäkerhet och datasäkerhet. Google Firebase skapar automatiskt
              dagliga backups vilka sparas i trettio (30) dagar bakåt.
            </Typography>
            <Typography
              sx={{
                mt: 2,
              }}
              variant="body1"
            >
              Det är endast vår datasäkerhetsansvarig som har tillgång till Applikationen fullständiga data. Eftersom
              all data som är registrerad i Applikationen hanteras av Google Firebase har vi också per automatik deras
              höga nivå av skydd. Vi har inte hittat på egna skydds-algoritmer, vi använder istället adekvata, reliabla
              lösningar som rekommenderas av Google i kombination med deras tjänster.
            </Typography>
            <Typography
              sx={{
                mt: 2,
              }}
              variant="body1"
            >
              Vi följer även de grundläggande dataskyddsprinciperna enligt GDPR vid all vår behandling av
              personaktiviteter. Vi tillämpar olika lämpliga organisatoriska och tekniska åtgärder för att skydda de
              personaktiviteter som vi behandlar.
            </Typography>
            <Typography
              sx={{
                mt: 2,
              }}
              variant="h6"
            >
              Rättigheter
            </Typography>
            <Typography variant="body1">
              <ul>
                <li>
                  Användare har rätt att begära tillgång till, rätta eller radera sina personaktiviteter. Användare kan
                  också när som helst invända mot viss behandling av sina personaktiviteter eller begära att
                  behandlingen begränsas.
                </li>

                <li>
                  Om en användare vill utöva någon av dessa rättigheter eller om de har frågor om vår hantering av
                  personaktiviteter, vänligen kontakta oss på robin.lundin@protonmail.com
                </li>
              </ul>
            </Typography>
            <Typography
              sx={{
                mt: 2,
              }}
              variant="h6"
            >
              Ändringar
            </Typography>
            <Typography variant="body1">
              Vi förbehåller oss rätten att ändra denna policy när som helst. Eventuella ändringar i denna policy träder
              i kraft så snart de publiceras på vår webbplats eller i vår applikation.
            </Typography>
            <Typography
              sx={{
                mt: 2,
              }}
              variant="h6"
            >
              Kontakt
            </Typography>
            <Typography variant="body1">
              Om du har frågor eller kommentarer om vår hantering av personaktiviteter, vänligen kontakta oss via e-post
              till robin.lundin@protonmail.com
            </Typography>
            <Typography variant="h6">Denna policy gäller från och med den 17-01-2023.</Typography>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DialogIntegrityPolicy;
