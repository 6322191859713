import { Visibility, VisibilityOff } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import {
  // Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, // Divider,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from '@mui/material';
import * as DOMPurify from 'dompurify';
import { FirebaseError } from 'firebase/app';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { Controller, FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { reAuthWithPassword } from '../../firebase/auth';
//reAuthWithGoogle
import { auth } from '../../firebase/firebaseConfig';

type Props = {
  displayDialog: boolean;
  setDisplayDialog: Dispatch<SetStateAction<boolean>>;
  confirmCallback: () => void;
};

const DialogDeleteAccount = ({ displayDialog, setDisplayDialog, confirmCallback }: Props) => {
  const {
    handleSubmit,
    control,
    setError,
    reset,
    formState: { errors },
  } = useForm();

  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();

  const user = auth.currentUser;
  const signInMethods: string[] = [];

  if (user) {
    user.providerData.forEach((profile) => {
      signInMethods.push(profile.providerId);
    });
  }

  /**
   * Verify deletion of account with Google redirect
   *
   */
  // const verifyWithGoogle = () => {
  //   try {
  //     reAuthWithGoogle();
  //   } catch (error) {}
  // };

  /**
   * Submit password to delete account
   *
   * @param data form data
   */
  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    try {
      if (signInMethods.includes('password')) {
        await reAuthWithPassword(DOMPurify.sanitize(data.password.trim()));
      }

      confirmCallback();
    } catch (error) {
      let errorMessage = t('error_text_general');
      if (error instanceof FirebaseError && error.code === 'auth/wrong-password') {
        errorMessage = t('error_incorrect_password');
      } else if (error instanceof FirebaseError && error.code === 'auth/too-many-requests') {
        errorMessage = t('error_many_failed_login_attempts');
      }
      setError('password', {
        type: 'manual',
        message: errorMessage,
      });
    }
  };

  /**
   * Reset form on dialog close
   */
  const handleCloseDialog = () => {
    reset();
    setShowPassword(false);
    setDisplayDialog(false);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <Dialog
      open={Boolean(displayDialog)}
      onClose={handleCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          {t('title_confirm_delete_account')}
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent sx={{ pb: 0 }}>
          <Typography variant="body1">{t('dialog_confirm_delete_account')}</Typography>

          {/* {signInMethods.includes('google.com') && (
            <Button fullWidth variant="outlined" color="error" sx={{ mt: 4 }} onClick={verifyWithGoogle}>
              {t('button_verify_with_google')}
            </Button>
          )} */}

          {/* {signInMethods.includes('password') && signInMethods.includes('google.com') && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 3, justifyContent: 'center', mt: 3 }}>
              <Divider sx={{ width: '30%' }} />
              <Typography variant="body1" sx={{ fontWeight: 600, mt: 2, mb: 2, textAlign: 'center' }}>
                {t('label_or')}
              </Typography>
              <Divider sx={{ width: '30%' }} />
            </Box>
          )} */}

          {signInMethods.includes('password') && (
            <>
              <FormControl variant="outlined" fullWidth sx={{ mt: 1 }}>
                <Controller
                  control={control}
                  name="password"
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field: { onChange, value, name } }) => (
                    <>
                      <label style={{ fontSize: '1rem', marginBottom: 2 }} htmlFor={name}>
                        {t('textfield_label_confirm_with_password')}
                      </label>
                      <OutlinedInput
                        data-testid="input-password-delete-account"
                        sx={{ bgcolor: 'background.default' }}
                        value={value}
                        onChange={onChange}
                        name={name}
                        type={showPassword ? 'text' : 'password'}
                        required={true}
                        id={name}
                        autoComplete="off"
                        error={!!errors.password}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {errors.password && typeof errors.password.message === 'string' && (
                        <FormHelperText sx={{ ml: 0 }} error={true}>
                          {errors.password.message}
                        </FormHelperText>
                      )}
                    </>
                  )}
                />
              </FormControl>
            </>
          )}
        </DialogContent>
        <DialogActions sx={{ p: 4, display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Button variant="contained" fullWidth color="primary" onClick={handleCloseDialog}>
            {t('button_cancel')}
          </Button>

          <Button
            data-testid="button-delete-account-confirmation"
            fullWidth
            type="submit"
            variant="outlined"
            color="error"
            style={{ marginLeft: 0 }}
          >
            {t('button_delete_account')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogDeleteAccount;
