import { Button, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { Dispatch, SetStateAction } from 'react';
import { useSelector } from 'react-redux';

import SimpleCard from '../../../components/SimpleCard';
import { getCurrentResults } from '../../../store/resultsSlice';
import { getNrOfDaysGoalAchieved, getPointsOnlyArrays, getSumPointsOnlyArrays } from './achievementsMethods';

interface AchievementsGoalCardProps {
  hasGoal: boolean;
  setDisplayGoalDialog: Dispatch<SetStateAction<boolean>>;
}

const AchievementsGoalCard: React.FC<AchievementsGoalCardProps> = ({ hasGoal, setDisplayGoalDialog }) => {
  const currentResults = useSelector(getCurrentResults);

  const pointsOnlyArrays = getPointsOnlyArrays(currentResults);
  const sumPointsOnlyArrays = getSumPointsOnlyArrays(pointsOnlyArrays);
  const nrOfDaysGoalAchieved = getNrOfDaysGoalAchieved(sumPointsOnlyArrays, currentResults);

  let nrOfDaysSubmitted: number | undefined;

  if (currentResults?.assignmentsResults[0].points) {
    nrOfDaysSubmitted = currentResults?.assignmentsResults[0].points.length - 1;
  }

  if (hasGoal) {
    return (
      <SimpleCard
        styles={{
          bgcolor: 'background.paper',
          flexGrow: 1,
        }}
      >
        <Typography
          data-testid="text-number-of-days-goal-achieved"
          component="h2"
          variant="h4"
          sx={{ fontWeight: 700 }}
        >
          {nrOfDaysGoalAchieved}
          <span style={{ marginLeft: '4px', fontSize: '14px' }}>/ {nrOfDaysSubmitted}</span>
        </Typography>
        <Typography component="h2" variant="caption">
          {t('label_goals_achieved')}
        </Typography>
      </SimpleCard>
    );
  } else {
    return (
      <SimpleCard styles={{ display: 'flex', flexDirection: 'column', gap: 3, pl: 8, pr: 8 }}>
        <Typography variant="caption" sx={{ fontSize: '0.7rem' }}>
          {t('label_no_goal')}
        </Typography>
        <Button
          variant="outlined"
          size="small"
          onClick={() => {
            setDisplayGoalDialog(true);
          }}
        >
          {t('button_add_goal')}
        </Button>
      </SimpleCard>
    );
  }
};

export default AchievementsGoalCard;
