import { DocumentData, doc, getDoc, setDoc } from 'firebase/firestore';

import { db } from '../firebaseConfig';

/**
 *
 * fetch user
 *
 */
export const getUserFirebase = async (userId: string): Promise<DocumentData | undefined> => {
  const userSnap = await getDoc(doc(db, 'users', userId));

  if (userSnap?.exists() && userSnap.data()) {
    return userSnap.data();
  }

  return undefined;
};

/**
 *
 * Set new User
 *
 */
type NewUserData = {
  userId: string;
  role: string;
  email: string | null;
};

export const setUserFirebase = async ({ role, email, userId }: NewUserData): Promise<string> => {
  await setDoc(doc(db, 'users', userId), {
    role: role,
    email: email,
  });

  return 'user-created';
};
