import { Box } from '@mui/material';
import { Container } from '@mui/system';
import React from 'react';
import { useSelector } from 'react-redux';

import { readCurrentDeviceState } from '../store/appSlice';

interface Props {
  style?: React.CSSProperties;
  children?: React.ReactNode;
  desktopMaxWidth?: 'sm' | 'md' | 'lg' | 'xl';
  contentMaxWidth?: number | string;
}

const MainContainer: React.FC<Props> = ({ children, desktopMaxWidth = 'md', contentMaxWidth, style }) => {
  const isDesktop = useSelector(readCurrentDeviceState);

  return (
    <Box
      sx={{
        pl: isDesktop ? 66 : 2,
        pr: 2,
      }}
    >
      <Container
        data-testid="main-container"
        component="main"
        maxWidth={isDesktop ? desktopMaxWidth : 'sm'}
        style={{
          paddingTop: isDesktop ? '12px' : 0,
          ...style,
        }}
      >
        {contentMaxWidth ? <Box sx={{ maxWidth: contentMaxWidth }}>{children}</Box> : children}
      </Container>
    </Box>
  );
};

export default MainContainer;
