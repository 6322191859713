import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RootState } from './store';

interface AppState {
  isSaving: boolean;
  isDesktop: boolean;
  isDarkMode: boolean;
  errorMsg: string | null;
  showSettingsDrawer: boolean;
  isIPhone: boolean;
}

const initialState: AppState = {
  isSaving: false,
  isDesktop: false,
  isDarkMode: localStorage.getItem('isDarkMode') === 'true',
  errorMsg: null,
  showSettingsDrawer: false,
  isIPhone: false,
};

const appSlice = createSlice({
  name: 'appSlice',
  initialState,
  reducers: {
    setIsSaving: (state, action: PayloadAction<boolean>) => {
      state.isSaving = action.payload;
    },
    setIsDesktop: (state, action: PayloadAction<boolean>) => {
      state.isDesktop = action.payload;
    },
    toggleDarkMode: (state) => {
      state.isDarkMode = !state.isDarkMode;
      localStorage.setItem('isDarkMode', String(state.isDarkMode));
    },
    setErrorMsg: (state, action: PayloadAction<string | null>) => {
      state.errorMsg = action.payload;
    },
    setShowSettingsDrawer: (state, action: PayloadAction<boolean>) => {
      state.showSettingsDrawer = action.payload;
    },
    setIsIPhone: (state, action: PayloadAction<boolean>) => {
      state.isIPhone = action.payload;
    },
  },
});

export const { setIsSaving, setIsDesktop, toggleDarkMode, setErrorMsg, setShowSettingsDrawer, setIsIPhone } =
  appSlice.actions;

export const readCurrentSavingState = (state: RootState) => state.appSlice.isSaving;
export const readCurrentDeviceState = (state: RootState) => state.appSlice.isDesktop;
export const readCurrentDarkModeState = (state: RootState) => state.appSlice.isDarkMode;
export const readCurrentErrorState = (state: RootState) => state.appSlice.errorMsg;
export const readCurrentSettingsDrawerState = (state: RootState) => state.appSlice.showSettingsDrawer;
export const readCurrentIPhoneState = (state: RootState) => state.appSlice.isIPhone;

export default appSlice.reducer;
