import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { User } from '../utilities/types';
import { RootState } from './store';

interface UserState {
  currentUser: User | null;
}

const initialState: UserState = {
  currentUser: null,
};

const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.currentUser = action.payload;
    },
    logout: (state) => {
      state.currentUser = null;
    },
  },
});

export const { setUser, logout } = userSlice.actions;

export const getCurrentUser = (state: RootState) => state.userSlice.currentUser;

export default userSlice.reducer;
