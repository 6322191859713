import Box from '@mui/material/Box';
import { useWindowHeight } from '@react-hook/window-size';
import React from 'react';
import { useSelector } from 'react-redux';

import { readCurrentDeviceState } from '../../store/appSlice';

interface AuthContainerProps {
  children: React.ReactNode;
  styles?: { [key: string]: any };
}

const AuthContainer: React.FC<AuthContainerProps> = ({ children, styles, ...rest }) => {
  const onlyHeight = useWindowHeight();
  const isDesktop = useSelector(readCurrentDeviceState);

  const height = (onlyHeight / 10) * 9 + 'px';

  return (
    <Box
      sx={{
        width: '100%', // Fix IE 11 issue.
        height: height,
        p: isDesktop ? 8 : 6,
        pt: isDesktop ? 6 : 5,
        pb: 6,
        bgcolor: 'background.paper',
        border: '1px solid',
        borderColor: 'custom.paperBorder',
        borderRadius: '12px 12px 0 0',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      {children}
    </Box>
  );
};

export default AuthContainer;
