import { FormControl, FormHelperText, OutlinedInput, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import MainContainer from '../../../components/MainContainer';
import TopBar from '../../../components/TopBar';
import { readCurrentDeviceState } from '../../../store/appSlice';
import { getCurrentJoinCompetitionData, setJoinCompetitionData } from '../../../store/joinCompetitionSlice';
import JoinCompetitionButtons from './JoinCompetitionButtons';

type CompetitionJoinNicknameScreenProps = {};

const CompetitionJoinNicknameScreen: React.FC<CompetitionJoinNicknameScreenProps> = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isDesktop = useSelector(readCurrentDeviceState);
  const currentJoinCompetitionData = useSelector(getCurrentJoinCompetitionData);

  const [title, setTitle] = useState('');
  const [titleError, setTitleError] = useState('');

  useEffect(() => {
    if (!currentJoinCompetitionData) {
      return navigate('/app/utmaningar');
    }

    if (currentJoinCompetitionData?.customAssignment) {
      setTitle(currentJoinCompetitionData.customAssignment.title);
    }
  }, [currentJoinCompetitionData, navigate]);

  /**
   *
   *
   *
   */
  const validateTitle = () => {
    if (title === '') {
      setTitleError(`${t('error_custom_assignment_title_join')}`);
      return false;
    }

    setTitleError('');
    return true;
  };

  /**
   * Save title for user custom assignment
   *
   */
  async function proceedToNextStep(hasCustom: boolean) {
    if (hasCustom) {
      const isValid = validateTitle();
      if (!isValid) return;

      const updatedAssignementResults = currentJoinCompetitionData?.assignmentsResults.map((_assignment: any) => {
        if (_assignment.type === 'custom') {
          return {
            ..._assignment,
            displayAssignment: true,
          };
        }
        return _assignment;
      });

      dispatch(
        setJoinCompetitionData({
          currentJoinCompetitionData: {
            ...currentJoinCompetitionData,
            assignmentsResults: updatedAssignementResults,
            customAssignment: { title: title },
          },
        }),
      );
      if (currentJoinCompetitionData?.allowTeams) {
        navigate('/app/utmaning-valj-lag-info');
      } else {
        navigate('/app/utmaning-smeknamn');
      }
    } else {
      if (currentJoinCompetitionData?.allowTeams) {
        navigate('/app/utmaning-valj-lag-info');
      } else {
        navigate('/app/utmaning-smeknamn');
      }
    }
  }

  return (
    <>
      <TopBar position="fixed" hasBackButton />

      <MainContainer contentMaxWidth={600}>
        <Box sx={{ mt: 22 }}>
          <Typography variant="h6" component="p" mt={4} mb={6} fontWeight={600}>
            {t('text_intro_sub_custom_assignment')}
          </Typography>

          <FormControl variant="outlined" fullWidth sx={{ mt: 4, mb: 8 }}>
            <label style={{ fontSize: '1rem', marginBottom: 2 }} htmlFor="title">
              {t('textfield_label_assignment_title')}
            </label>
            <OutlinedInput
              data-testid="input-custom-assignment"
              fullWidth
              id="title"
              name="title"
              autoComplete="off"
              value={title}
              onChange={(event) => setTitle(event.target.value)}
              error={Boolean(titleError)}
              sx={{ bgcolor: 'background.paper' }}
            />
            {titleError && <FormHelperText error={true}>{titleError}</FormHelperText>}
          </FormControl>

          <JoinCompetitionButtons isDesktop={isDesktop} proceedToNextStep={proceedToNextStep} showSkipButton={true} />
        </Box>
      </MainContainer>
    </>
  );
};

export default CompetitionJoinNicknameScreen;
