import { Box, Button } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import DialogDailyGoal from '../../../components/dialogs/DialogDailyGoal';
import { AssignmentResult } from '../../../utilities/types';

interface AssignmentResultsProps {
  assignmentsResults: [AssignmentResult] | null;
  activeDayIndex: number;
  goal: number;
}

function AssignmentResults({ assignmentsResults, goal, activeDayIndex }: AssignmentResultsProps) {
  const { t } = useTranslation();

  const [displayGoalDialog, setDisplayGoalDialog] = useState<boolean>(false);

  const getActiveDayPoints = (): number => {
    if (!assignmentsResults) {
      return 0; // no results for the specified day
    }

    const dayPoints = assignmentsResults.reduce((totalPoints, assignment) => {
      const points = assignment.points[activeDayIndex];
      return totalPoints + points;
    }, 0);

    return dayPoints;
  };

  const getTotalPoints = () => {
    if (!assignmentsResults) {
      return 0; // no assignments for the specified day
    }

    let totalPoints = 0;

    assignmentsResults?.forEach((assignment) => {
      assignment.points.forEach((assignmentPoint) => {
        totalPoints += assignmentPoint;
      });
    });

    return totalPoints;
  };

  let dayPoints = getActiveDayPoints();
  let totalPoints = getTotalPoints();

  const dayGoal = (dayPoints / goal) * 100;
  const dayProgress = Math.min((dayPoints / goal) * 100, 100);

  return (
    <>
      <DialogDailyGoal
        title=""
        content=""
        displayDialog={displayGoalDialog}
        setDisplayDialog={setDisplayGoalDialog}
        currentGoal={goal}
        callback={() => {
          setDisplayGoalDialog(false);
        }}
      />

      <Box sx={{ display: 'flex', gap: 1 }}>
        <Box
          sx={{
            pl: 3,
            pr: 3,
            pt: 2,
            pb: 2,
          }}
        >
          <Typography variant="h4" fontWeight={600}>
            {`${totalPoints}`}
            <span style={{ fontSize: '1.6rem' }}>p</span>
          </Typography>
        </Box>

        {goal !== 0 && typeof dayGoal == 'number' && (
          <Box
            sx={{
              display: 'flex',
              flexGrow: 1,
              flexDirection: 'column',
              justifyContent: 'center',
              padding: 2,
              pr: 2,
              minWidth: '180px',
              '&:hover': {
                cursor: 'pointer',
              },
            }}
            onClick={() => {
              setDisplayGoalDialog(true);
            }}
          >
            <Box>
              <Typography data-testid="current-goal" variant="caption" sx={{ fontSize: '0.6rem' }}>
                {goal - dayPoints <= 0
                  ? t('progress_daily_goal_achieved')
                  : `${t('progress_left_to_daily_goal')} ${goal - dayPoints}`}
              </Typography>{' '}
              <LinearProgress
                sx={{ borderRadius: 3 }}
                variant="determinate"
                value={dayProgress}
                color={goal - dayPoints <= 0 ? 'secondary' : 'primary'}
              />
            </Box>
          </Box>
        )}

        {!goal && (
          <Box
            sx={{
              display: 'flex',
              flexGrow: 1,
              flexDirection: 'row',
              justifyContent: 'end',
              padding: 2,
              pr: 0,
              width: '100%',
              minWidth: '180px',
              '&:hover': {
                cursor: 'pointer',
              },
            }}
          >
            <Button
              data-testid="button-assignments-add-goal"
              variant="outlined"
              size="small"
              sx={{ alignSelf: 'end' }}
              onClick={() => {
                setDisplayGoalDialog(true);
              }}
            >
              {t('button_add_daily_goal')}
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
}

export default AssignmentResults;
