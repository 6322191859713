// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { GoogleAuthProvider, getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

let firebaseConfig = {};

if (process.env.REACT_APP_ENV === 'dev') {
  firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY_DEV,
    authDomain: process.env.REACT_APP_AUTHDOMAIN_DEV,
    projectId: process.env.REACT_APP_POJECT_ID_DEV,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET_DEV,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID_DEV,
    appId: process.env.REACT_APP_APP_ID_DEV,
  };
} else if (process.env.REACT_APP_ENV === 'prod') {
  firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY_PROD,
    authDomain: process.env.REACT_APP_AUTHDOMAIN_PROD,
    projectId: process.env.REACT_APP_POJECT_ID_PROD,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET_PROD,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID_PROD,
    appId: process.env.REACT_APP_APP_ID_PROD,
  };
}

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);
export const provider = new GoogleAuthProvider();

// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(firebaseApp);
