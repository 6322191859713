import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Box, Button, Divider, IconButton, List, ListItem, SwipeableDrawer, Typography } from '@mui/material';
import { getRedirectResult } from 'firebase/auth';
import { deleteDoc, doc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import CustomSwitch from '../components/CustomSwitch';
import LanguageSwitcher from '../components/LanguageSwitcher';
import { deleteAccount, signout } from '../firebase/auth';
import { getScoreFirebase } from '../firebase/db/scores';
import { getAllTeamScoresFirebase, updateTeamScoresFirebase } from '../firebase/db/teamScores';
import { auth, db } from '../firebase/firebaseConfig';
import {
  readCurrentDarkModeState,
  readCurrentSettingsDrawerState,
  setShowSettingsDrawer,
  toggleDarkMode,
} from '../store/appSlice';
import { getCurrentCompetition, unsetCompetition } from '../store/competitionSlice';
import { unsetCustomAssignment } from '../store/customAssignmentSlice';
import { unsetDays } from '../store/daysHandlerSlice';
import { getCurrentResults } from '../store/resultsSlice';
import { getCurrentUser } from '../store/userSlice';
import Loader from './Loader';
import UserCompetitionDetailsCard from './UserCompetitionDetails';
import DialogDeleteAccount from './dialogs/DialogDeleteAccount';

type SettingsDrawerProps = {};
const SettingsDrawer: React.FC<SettingsDrawerProps> = () => {
  const currentUser = useSelector(getCurrentUser);
  const currentResults = useSelector(getCurrentResults);
  const showSettingsDrawer = useSelector(readCurrentSettingsDrawerState);
  const currentCompetition = useSelector(getCurrentCompetition);

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [displayDeleteDialog, setDisplayDeleteDialog] = useState<boolean>(false);
  const isDarkMode = useSelector(readCurrentDarkModeState);

  const { t } = useTranslation();

  const resetCompetition = () => {
    dispatch(setShowSettingsDrawer(false));
    setDisplayDeleteDialog(false);
    dispatch(unsetCompetition());
    dispatch(unsetCustomAssignment());
    dispatch(unsetDays());
  };

  const logout = () => {
    resetCompetition();
    signout();
  };

  const deleteUser = async () => {
    try {
      setIsLoading(true);

      if (currentUser?.uid && currentResults?.team) {
        const participantScore = await getScoreFirebase(currentUser?.uid);
        const _teamScores = await getAllTeamScoresFirebase(currentResults?.competitionId);
        if (_teamScores && participantScore && currentResults) {
          updateTeamScoresFirebase({
            competitionId: currentResults?.competitionId,
            team: currentResults?.team,
            days: _teamScores[currentResults?.team].days - participantScore?.nrOfDaysSubmitted,
            score: _teamScores[currentResults?.team].score - participantScore?.score,
          });
        }
      }

      await deleteDoc(doc(db, 'scores', `${currentUser?.uid}`));
      await deleteDoc(doc(db, 'results', `${currentUser?.uid}`));
      await deleteDoc(doc(db, 'customAssignments', `${currentUser?.uid}`));
      await deleteDoc(doc(db, 'users', `${currentUser?.uid}`));
      await deleteAccount();

      resetCompetition();
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  /**
   * If the user re-authenticated, then delete user
   */
  useEffect(() => {
    const onRedirect = async () => {
      try {
        const result = await getRedirectResult(auth);
        if (result?.operationType === 'reauthenticate') {
          deleteUser();
        }
      } catch (error) {}
    };
    onRedirect();
    // eslint-disable-next-line
  }, []);

  const handleToggleDarkMode = () => {
    dispatch(toggleDarkMode());
  };

  return (
    <>
      {isLoading && <Loader />}

      <DialogDeleteAccount
        displayDialog={displayDeleteDialog}
        setDisplayDialog={setDisplayDeleteDialog}
        confirmCallback={deleteUser}
      />

      <SwipeableDrawer
        anchor={'right'}
        open={showSettingsDrawer}
        onClose={() => {
          dispatch(setShowSettingsDrawer(false));
        }}
        onOpen={() => {
          dispatch(setShowSettingsDrawer(true));
        }}
      >
        <Box role="presentation" sx={{ minWidth: 300 }}>
          <List>
            <ListItem>
              <IconButton
                data-testid="button-close-settings"
                sx={{ ml: -2 }}
                onClick={() => {
                  dispatch(setShowSettingsDrawer(false));
                }}
                aria-label="stäng meny"
              >
                <CloseRoundedIcon />
              </IconButton>
            </ListItem>

            <ListItem>
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: 80, pt: 6 }}>
                <Typography variant="caption">{t('label_email')}</Typography>
                <Typography variant="body1" sx={{ fontWeight: 700 }}>
                  {currentUser?.email}
                </Typography>
              </Box>
            </ListItem>
            <Divider sx={{ ml: 2, mr: 2, mt: 1, mb: 1 }} />

            <ListItem>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Typography variant="body1">{t('settings_label_language')}</Typography>

                <LanguageSwitcher />
              </Box>
            </ListItem>

            <Divider sx={{ ml: 2, mr: 2, mt: 1, mb: 1 }} />

            <ListItem>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Typography variant="body1">{t('settings_label_darkmode')}</Typography>
                <CustomSwitch
                  checked={isDarkMode}
                  onChange={handleToggleDarkMode}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </Box>
            </ListItem>

            <Divider sx={{ ml: 2, mr: 2, mt: 1, mb: 1 }} />

            <Button
              data-testid="button-delete-account"
              sx={{ mt: 2 }}
              size="small"
              disableElevation
              color="error"
              onClick={() => {
                setDisplayDeleteDialog(true);
              }}
            >
              {t('button_delete_account')}
            </Button>

            {currentCompetition && currentResults && currentUser && (
              <Box sx={{ p: 2, mt: 4 }}>
                <UserCompetitionDetailsCard
                  competition={currentCompetition}
                  onCloseSettingsDrawer={() => dispatch(setShowSettingsDrawer(false))}
                />
              </Box>
            )}
          </List>
          <Box paddingX={4} position="absolute" bottom={32} width="100%">
            <Button
              data-testid="button-logout"
              fullWidth
              variant="outlined"
              disableElevation
              color="primary"
              onClick={logout}
            >
              {t('button_logout')}
            </Button>
          </Box>
        </Box>
      </SwipeableDrawer>
    </>
  );
};

export default SettingsDrawer;
