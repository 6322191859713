import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import chrome from '../../assets/installing/chrome.jpg';
import safari1 from '../../assets/installing/safari-1.jpg';
import safari2 from '../../assets/installing/safari-2.jpg';
import MainContainer from '../../components/MainContainer';
import TopBar from '../../components/TopBar';

type AboutScreenProps = {
  authenticated: boolean;
};

const AboutScreen: React.FC<AboutScreenProps> = ({ authenticated }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      {authenticated && <TopBar position="static" hasBackButton />}
      <MainContainer>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
          {!authenticated && (
            <Box
              sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap', gap: 4 }}
            >
              <>
                <Typography variant="h4" component="h1" mt={4} fontWeight={600}>
                  {t('title_about_halsokuppen')}
                </Typography>

                <Button
                  data-testid="button-to-signin"
                  size="large"
                  variant="contained"
                  color="primary"
                  sx={{
                    mt: 0,
                  }}
                  onClick={() => {
                    navigate('/app');
                  }}
                  endIcon={<LoginOutlinedIcon />}
                >
                  {t('button_to_app')}
                </Button>
              </>
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <Typography variant="h4" component="h1" fontWeight={600} mb={4}>
              {t('title_about_halsokuppen')}
            </Typography>

            <Typography variant="h5" component="h2" fontWeight={600}>
              {t('heading_about_introduction')}
            </Typography>
            <Typography>{t('text_about_introduction')}</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <Typography variant="h5" component="h2" fontWeight={600}>
              {t('heading_about_rules')}
            </Typography>
            <Typography>{t('text_about_rules_1')}</Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <Typography variant="h5" component="h2" fontWeight={600}>
              {t('heading_about_personal_goal')}
            </Typography>
            <Typography>{t('text_about_personal_goal')}</Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <Typography variant="h5" component="h2" fontWeight={600}>
              {t('heading_about_add_device')}
            </Typography>
            <Typography>{t('text_about_add_device')}</Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <Typography variant="h6" component="h3" fontWeight={600}>
              Safari
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 4 }}>
              <img
                src={safari1}
                alt="Safari installing instructions step one"
                width="281"
                height="609"
                style={{ border: '2px solid grey', borderRadius: '8px' }}
              />
              <img
                src={safari2}
                alt="Safari installing instructions step two"
                width="281"
                height="609"
                style={{ border: '2px solid grey', borderRadius: '8px' }}
              />
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <Typography variant="h6" component="h3" fontWeight={600}>
              Chrome
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 4 }}>
              <img
                src={chrome}
                alt="Safari installing instructions step two"
                width="281"
                height="609"
                style={{ border: '2px solid grey', borderRadius: '8px' }}
              />
            </Box>
          </Box>
        </Box>
      </MainContainer>
    </>
  );
};

export default AboutScreen;
