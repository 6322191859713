import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { DaysState } from '../utilities/types';
import { RootState } from './store';

interface CurrentDaysState {
  currentDaysState: DaysState;
}

const initialState: CurrentDaysState = {
  currentDaysState: {
    lastDayIndex: 0,
    activeDayIndex: 0,
  },
};

const daysHandlerSlice = createSlice({
  name: 'daysSlice',
  initialState,
  reducers: {
    setDays: (state, action: PayloadAction<DaysState>) => {
      state.currentDaysState = action.payload;
    },
    setActiveDay: (state, action: PayloadAction<number>) => {
      state.currentDaysState = {
        lastDayIndex: state.currentDaysState?.lastDayIndex,
        activeDayIndex: action.payload,
      };
    },
    setLastDay: (state, action: PayloadAction<number>) => {
      state.currentDaysState = {
        activeDayIndex: state.currentDaysState?.activeDayIndex,
        lastDayIndex: action.payload,
      };
    },
    unsetDays: (state) => {
      state.currentDaysState = {
        lastDayIndex: 0,
        activeDayIndex: 0,
      };
    },
  },
});

export const { setActiveDay, setLastDay, setDays, unsetDays } = daysHandlerSlice.actions;

export const getCurrentDaysState = (state: RootState) => state.daysSlice.currentDaysState;

export default daysHandlerSlice.reducer;
