import { Box, Typography } from '@mui/material';
import { useWindowWidth } from '@react-hook/window-size';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import handWave from '../../../assets/onboarding/handWave.svg';
import handWaveDark from '../../../assets/onboarding/handWaveDark.svg';
import MainContainer from '../../../components/MainContainer';
import TopBar from '../../../components/TopBar';
import { readCurrentDarkModeState, readCurrentDeviceState } from '../../../store/appSlice';
import { getCurrentJoinCompetitionData } from '../../../store/joinCompetitionSlice';
import JoinCompetitionButtons from './JoinCompetitionButtons';

type CompetitionJoinWelcomeProps = {};

const CompetitionJoinWelcome: React.FC<CompetitionJoinWelcomeProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const windowWidth = useWindowWidth();
  const isDesktop = useSelector(readCurrentDeviceState);
  const isDarkMode = useSelector(readCurrentDarkModeState);

  const currentJoinCompetitionData = useSelector(getCurrentJoinCompetitionData);

  useEffect(() => {
    if (!currentJoinCompetitionData) {
      return navigate('/app/utmaningar');
    }
  }, [currentJoinCompetitionData, navigate]);

  /**
   * Save title for user custom assignment
   *
   */
  async function proceedToNextStep() {
    navigate('/app/utmaning-valj-aktiviteter-info');
  }

  return (
    <>
      <TopBar position="static" hasBackButton />

      <MainContainer contentMaxWidth={600}>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', p: 2, gap: 4 }}>
          <img
            src={isDarkMode ? handWaveDark : handWave}
            alt=""
            width={windowWidth / 1.5}
            height={windowWidth / 1.5}
            style={{
              width: '100%',
              maxWidth: 360,
              maxHeight: 360,
            }}
          />
          <Typography variant="h5" component="h1" mt={4} fontWeight={600}>
            {t('heading_join_competition_welcome')} {currentJoinCompetitionData?.competitionName}!
          </Typography>

          <Typography variant="body1" mt={2}>
            {t('text_join_competition_welcome')}
          </Typography>

          <JoinCompetitionButtons
            customContinueText="button_continue"
            proceedToNextStep={proceedToNextStep}
            isDesktop={isDesktop}
            showSkipButton={false}
          />
        </Box>
      </MainContainer>
    </>
  );
};

export default CompetitionJoinWelcome;
