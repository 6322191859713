import {
  DocumentData,
  QuerySnapshot,
  WithFieldValue,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from 'firebase/firestore';

import { db } from '../firebaseConfig';

/**
 *
 * fetch all defualt assignments
 *
 */
export const getAssignmentsFirebase = async (): Promise<QuerySnapshot<DocumentData> | undefined> => {
  const assignmentsSnapshot = await getDocs(collection(db, 'assignments'));

  if (!assignmentsSnapshot.empty) {
    return assignmentsSnapshot;
  }
  return undefined;
};

/**
 *
 * fetch active defualt assignments
 *
 */
export const getActiveAssignmentsFirebase = async (): Promise<QuerySnapshot<DocumentData> | undefined> => {
  const queryAciveAssignments = query(collection(db, 'assignments'), where('status', '==', 'active'));

  const assignmentsSnapshot = await getDocs(queryAciveAssignments);

  if (!assignmentsSnapshot.empty) {
    return assignmentsSnapshot;
  }
  return undefined;
};

/**
 *
 * fetch a custom assignment from a specific user
 *
 */
export const getCustomAssignmentFirebase = async (userId: string): Promise<DocumentData | undefined> => {
  const customAssignmentSnap = await getDoc(doc(db, 'customAssignments', userId));

  if (customAssignmentSnap?.exists() && customAssignmentSnap.data()) {
    return customAssignmentSnap.data();
  }

  return undefined;
};

/**
 *
 *
 *
 *
 */
/**
 *
 * Add a new competition
 *
 */
export const addAssignmentFirebase = async (assignment: WithFieldValue<DocumentData>): Promise<void | undefined> => {
  if (assignment.id) {
    const assignmentRef = doc(db, 'assignments', assignment.id);
    await setDoc(assignmentRef, assignment);

    return;
  }

  return undefined;
};

/**
 *
 * Set custom assignment
 *
 */
type NewAssignmentData = {
  userId: string;
  competitionId: string;
  title: string;
};

export const setCustomAssignmentFirebase = async ({
  userId,
  competitionId,
  title,
}: NewAssignmentData): Promise<string> => {
  const userCustomAssignment = doc(db, 'customAssignments', userId);

  await setDoc(userCustomAssignment, {
    userId: userId,
    competitionId: competitionId,
    title: title,
  });

  return 'custom-assignment-set';
};
