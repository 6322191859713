import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import * as React from 'react';

const Loader: React.FC = () => {
  return (
    <Backdrop
      data-testid="loader-component"
      sx={{
        zIndex: '9999',
        bgcolor: 'custom.backdrop',
      }}
      open={true}
    >
      <CircularProgress color="primary" />
    </Backdrop>
  );
};

export default Loader;
