import { Box, FormControl, FormHelperText, OutlinedInput, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import MainContainer from '../../../components/MainContainer';
import TopBar from '../../../components/TopBar';
import { readCurrentDeviceState } from '../../../store/appSlice';
import { getCurrentJoinCompetitionData } from '../../../store/joinCompetitionSlice';
import JoinCompetitionButtons from './JoinCompetitionButtons';

type CompetitionJoinPassCodeScreenProps = {};

const CompetitionJoinPassCodeScreen: React.FC<CompetitionJoinPassCodeScreenProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isDesktop = useSelector(readCurrentDeviceState);

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm();

  const currentJoinCompetitionData = useSelector(getCurrentJoinCompetitionData);

  useEffect(() => {
    if (!currentJoinCompetitionData) {
      return navigate('/app/utmaningar');
    }
  }, [currentJoinCompetitionData, navigate]);
  /**
   *
   * Submit form and navigate to next screen
   *
   */
  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    if (data.passCode !== currentJoinCompetitionData?.passCode) {
      setError('passCode', {
        type: 'manual',
        message: t('error_incorrect_passcode') || 'Incorrect passcode',
      });
    } else {
      navigate('/app/utmaning-valkommen');
    }
  };

  return (
    <>
      <TopBar position="static" hasBackButton />

      <MainContainer contentMaxWidth={600}>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', p: 2, gap: 6 }}>
          <Typography variant="h5" component="h1" mt={4} fontWeight={600}>
            {t('heading_enter_password')}
          </Typography>

          <Typography variant="body1" mt={2}>
            {t('text_enter_password')}
          </Typography>

          <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
            <FormControl variant="outlined" fullWidth>
              <Controller
                control={control}
                name="passCode"
                defaultValue=""
                rules={{ required: true }}
                render={({ field: { onChange, value, name } }) => (
                  <>
                    <label style={{ fontSize: '1rem', marginBottom: 2 }} htmlFor={name}>
                      {t('textfield_label_enter_passcode')}
                    </label>
                    <OutlinedInput
                      data-testid="input-join-competition-passcode"
                      sx={{ bgcolor: 'background.paper' }}
                      value={value}
                      onChange={onChange}
                      name={name}
                      type="text"
                      required={true}
                      id={name}
                      autoComplete="off"
                      error={!!errors.passCode}
                    />
                    {errors.passCode && typeof errors.passCode.message === 'string' && (
                      <FormHelperText sx={{ ml: 0 }} error={true}>
                        {errors.passCode.message}
                      </FormHelperText>
                    )}
                  </>
                )}
              />
            </FormControl>

            <JoinCompetitionButtons customContinueText="button_continue" isDesktop={isDesktop} showSkipButton={false} />
          </form>
        </Box>
      </MainContainer>
    </>
  );
};

export default CompetitionJoinPassCodeScreen;
