import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router';
import { Route, Routes } from 'react-router-dom';

import AdminOwnerScreen from '../screens/admin/AdminOwnerScreen';
import AdminScreen from '../screens/admin/AdminScreen';
import CompetitionAdminCreateScreen from '../screens/admin/CompetitionAdminCreateScreen';
import AchievementsScreen from '../screens/private/AchievementsScreen';
import AssignmentEditScreen from '../screens/private/AssignmentEditScreen';
import AssignmentsScreen from '../screens/private/AssignmentsScreen';
import CompetitionUsersCreateScreen from '../screens/private/CompetitionUsersCreateScreen';
import CompetitionsScreen from '../screens/private/CompetitionsScreen';
import LeaderboardScreen from '../screens/private/LeaderboardScreen';
import CompetitionJoinChooseAssignmentsScreen from '../screens/private/joinCompetition/CompetitionJoinChooseAssignmentsScreen';
import CompetitionChooseInfoScreen from '../screens/private/joinCompetition/CompetitionJoinChooseInfoScreen';
import CompetitionJoinCustomAssignmentScreen from '../screens/private/joinCompetition/CompetitionJoinCustomAssignmentsScreen';
import CompetitionJoinCustomInfoScreen from '../screens/private/joinCompetition/CompetitionJoinCustomInfoScreen';
import CompetitionJoinNicknameScreen from '../screens/private/joinCompetition/CompetitionJoinNicknameScreen';
import CompetitionJoinPassCodeScreen from '../screens/private/joinCompetition/CompetitionJoinPassCodeScreen';
import CompetitionJoinTeamInfoScreen from '../screens/private/joinCompetition/CompetitionJoinTeamInfoScreen';
// import CompetitionJoinSetGoalScreen from '../screens/private/joinCompetition/CompetitionJoinSetGoalScreen';
import CompetitionJoinTeamScreen from '../screens/private/joinCompetition/CompetitionJoinTeamScreen';
import CompetitionJoinWelcome from '../screens/private/joinCompetition/CompetitionJoinWelcome';
import AboutScreen from '../screens/public/Aboutscreen';
import HomeScreen from '../screens/public/HomeScreen';
import ResetPasswordScreen from '../screens/public/ResetPasswordScreen';
import SigninScreen from '../screens/public/SigninScreen';
import SignupScreen from '../screens/public/SignupScreen';
import { getCurrentUser } from '../store/userSlice';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

interface AppRoutesProps {}

const AppRoutes: React.FC<AppRoutesProps> = () => {
  const currentUser = useSelector(getCurrentUser);
  const authenticated = Boolean(currentUser?.uid);

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/app" replace />} />
      <Route path="*" element={<Navigate to="/app" replace />} />

      <Route path="/app/home" element={<HomeScreen />} />

      <Route path="/app/about" element={<AboutScreen authenticated={authenticated} />} />
      <Route path="/app/info" element={<AboutScreen authenticated={authenticated} />} />

      <Route
        path="/app"
        element={
          <PublicRoute authenticated={authenticated}>
            <SigninScreen />
          </PublicRoute>
        }
      />
      <Route
        path="/app/login"
        element={
          <PublicRoute authenticated={authenticated}>
            <SigninScreen />
          </PublicRoute>
        }
      />
      <Route
        path="/app/registrera"
        element={
          <PublicRoute authenticated={authenticated}>
            <SignupScreen />
          </PublicRoute>
        }
      />
      <Route
        path="/app/aterstall-losenord"
        element={
          <PublicRoute authenticated={authenticated}>
            <ResetPasswordScreen />
          </PublicRoute>
        }
      />
      <Route
        path="/app/aktiviteter"
        element={
          <PrivateRoute authenticated={authenticated}>
            <AssignmentsScreen />
          </PrivateRoute>
        }
      />

      <Route
        path="/app/redigera-aktiviteter"
        element={
          <PrivateRoute authenticated={authenticated}>
            <AssignmentEditScreen />
          </PrivateRoute>
        }
      />

      <Route
        path="/app/skapa-utmaning"
        element={
          <PrivateRoute authenticated={authenticated}>
            <CompetitionAdminCreateScreen />
          </PrivateRoute>
        }
      />

      <Route
        path="/app/skapa-utmaning-anvandare"
        element={
          <PrivateRoute authenticated={authenticated}>
            <CompetitionUsersCreateScreen />
          </PrivateRoute>
        }
      />

      <Route
        path="/app/utmaningar"
        element={
          <PrivateRoute authenticated={authenticated}>
            <CompetitionsScreen />
          </PrivateRoute>
        }
      />

      <Route
        path="/app/utmaning-losenord"
        element={
          <PrivateRoute authenticated={authenticated}>
            <CompetitionJoinPassCodeScreen />
          </PrivateRoute>
        }
      />

      <Route
        path="/app/utmaning-smeknamn"
        element={
          <PrivateRoute authenticated={authenticated}>
            <CompetitionJoinNicknameScreen />
          </PrivateRoute>
        }
      />

      <Route
        path="/app/utmaning-valkommen"
        element={
          <PrivateRoute authenticated={authenticated}>
            <CompetitionJoinWelcome />
          </PrivateRoute>
        }
      />

      <Route
        path="/app/utmaning-valj-aktiviteter-info"
        element={
          <PrivateRoute authenticated={authenticated}>
            <CompetitionChooseInfoScreen />
          </PrivateRoute>
        }
      />

      <Route
        path="/app/utmaning-valj-aktiviteter"
        element={
          <PrivateRoute authenticated={authenticated}>
            <CompetitionJoinChooseAssignmentsScreen />
          </PrivateRoute>
        }
      />

      <Route
        path="/app/utmaning-egen-aktivitet"
        element={
          <PrivateRoute authenticated={authenticated}>
            <CompetitionJoinCustomAssignmentScreen />
          </PrivateRoute>
        }
      />

      <Route
        path="/app/utmaning-egen-aktivitet-info"
        element={
          <PrivateRoute authenticated={authenticated}>
            <CompetitionJoinCustomInfoScreen />
          </PrivateRoute>
        }
      />

      {/* <Route
        path="/app/utmaning-satt-mal"
        element={
          <PrivateRoute authenticated={authenticated}>
            <CompetitionJoinSetGoalScreen />
          </PrivateRoute>
        }
      /> */}

      <Route
        path="/app/utmaning-valj-lag"
        element={
          <PrivateRoute authenticated={authenticated}>
            <CompetitionJoinTeamScreen />
          </PrivateRoute>
        }
      />

      <Route
        path="/app/utmaning-valj-lag-info"
        element={
          <PrivateRoute authenticated={authenticated}>
            <CompetitionJoinTeamInfoScreen />
          </PrivateRoute>
        }
      />

      <Route
        path="/app/admin"
        element={
          <PrivateRoute authenticated={authenticated}>
            <AdminScreen />
          </PrivateRoute>
        }
      />

      <Route
        path="/app/utmaningsinfo"
        element={
          <PrivateRoute authenticated={authenticated}>
            <AdminOwnerScreen />
          </PrivateRoute>
        }
      />

      <Route
        path="/app/bedrifter"
        element={
          <PrivateRoute authenticated={authenticated}>
            <AchievementsScreen />
          </PrivateRoute>
        }
      />

      <Route
        path="/app/topplistan"
        element={
          <PrivateRoute authenticated={authenticated}>
            <LeaderboardScreen />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};

export default AppRoutes;
