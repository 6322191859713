import { Box, Typography } from '@mui/material';
import { useWindowHeight } from '@react-hook/window-size';
import React from 'react';

import broccoflat from '../../assets/broccoflat.svg';

interface LogoProps {
  styles?: { [key: string]: any };
}

const Logo: React.FC<LogoProps> = ({ styles, ...rest }) => {
  const onlyHeight = useWindowHeight();

  const logoHeight = onlyHeight / 8;
  const logoHeightString = logoHeight / 1.8 + 'px';
  const logoWidthString = logoHeight * 0.8 + 'px';

  return (
    <Box
      sx={{
        width: '100%',
        height: logoHeight,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img
        src={broccoflat}
        alt="Hälsokuppen logotype looking like a trophy made of broccoli"
        width={logoWidthString}
        height={logoHeightString}
      />
      <Typography
        variant="h4"
        component="h1"
        sx={{ fontFamily: '"Reem Kufi Fun", sans-serif;', pb: 4, pt: 6, fontWeight: 700 }}
        color="secondary.main"
        textAlign="center"
      >
        Hälsokuppen
      </Typography>
    </Box>
  );
};

export default Logo;
