import { Typography } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { getNavigationItems } from '../routing/navigationItems';
import { readCurrentIPhoneState, readCurrentSavingState } from '../store/appSlice';
import { getCurrentCompetition } from '../store/competitionSlice';
import { getCurrentUser } from '../store/userSlice';

type BottomBarNavigationProps = {};

const BottomBarNavigation: React.FC<BottomBarNavigationProps> = () => {
  const location = useLocation();
  const [navValue, setNavValue] = React.useState(location?.pathname);
  const { t } = useTranslation();
  const currentUser = useSelector(getCurrentUser);
  const currentCompetition = useSelector(getCurrentCompetition);
  const isIPhone = useSelector(readCurrentIPhoneState);

  const isSaving = useSelector(readCurrentSavingState);
  const navigate = useNavigate();

  React.useEffect(() => {
    setNavValue(location?.pathname);
  }, [location]);

  if (!currentUser || !currentCompetition) return null;

  const navigationItems = getNavigationItems(currentCompetition);

  return (
    <AppBar position="fixed" sx={{ top: 'auto', bottom: 0, bgcolor: 'background.default' }} elevation={0}>
      <BottomNavigation
        showLabels
        value={navValue}
        onChange={(event, newValue) => {
          event.preventDefault();
          if (isSaving === false) {
            navigate(newValue);
            setNavValue(newValue);
          }
        }}
        sx={{
          flexGrow: 1,
          height: isIPhone ? 88 : 80,
          borderTop: '1px solid',
          borderColor: 'custom.paperBorder',
          bgcolor: 'background.paper',
        }}
      >
        {navigationItems.map((navigationItem, i) => {
          if (!navigationItem) return null;

          return (
            <BottomNavigationAction
              key={navigationItem.label}
              sx={{ paddingBottom: isIPhone ? 5 : 3 }}
              component={Link}
              value={navigationItem.route}
              to={navigationItem.route}
              icon={
                location?.pathname === navigationItem.route ? navigationItem.iconActive : navigationItem.iconInActive
              }
              label={
                <Typography data-testid={`bottom-navigation-button-${i}`} variant="caption">
                  {t(navigationItem.label)}
                </Typography>
              }
            />
          );
        })}
      </BottomNavigation>
    </AppBar>
  );
};

export default BottomBarNavigation;
