import { FormHelperText, OutlinedInput, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import dayjs from 'dayjs';
import DOMPurify from 'dompurify';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { Controller, FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getAssignmentsFirebase } from '../../firebase/db/assignments';
import { addNewCompetitionFirebase } from '../../firebase/db/competitions';
import { readCurrentDarkModeState, readCurrentDeviceState, setErrorMsg } from '../../store/appSlice';
import { getCurrentUser } from '../../store/userSlice';
import { AssignmentDetails, Competition } from '../../utilities/types';

interface StartDate {
  date: dayjs.Dayjs;
  displayDate: string;
}

type CompetitionUsersCreateScreenProps = {};

const CompetitionUsersCreateScreen: React.FC<CompetitionUsersCreateScreenProps> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      customAssignment: false,
      duration: 0,
      startDate: t('select_value_today'),
      competitionName: '',
      passCode: '',
      ownerId: '',
    },
  });

  const currentUser = useSelector(getCurrentUser);
  const isDesktop = useSelector(readCurrentDeviceState);
  const isDarkMode = useSelector(readCurrentDarkModeState);
  const [assignments, setAssignments] = useState<any>([]);

  /**
   *  Lifecycle
   */
  useEffect(() => {
    const fetchAssignments = async () => {
      try {
        const fetchedAssignments = await getAssignmentsFirebase();

        let tempAssignments: any = [];

        fetchedAssignments?.forEach((doc) => {
          const data = doc.data();

          tempAssignments.push({
            id: doc.id,
            description: data.description,
            title: data.title,
            information: data.information,
            points: [0],
            color: data.color,
            index: data.index,
            displayAssignment: false,
            type: data.type,
          });
        });

        setAssignments(tempAssignments);
      } catch (errorMsg) {
        dispatch(setErrorMsg(t('error_text_general')));
      }
    };

    fetchAssignments();
  }, [dispatch]);

  const createChosenAssignmentsDetails = (assignments: any[], data: any): AssignmentDetails[] => {
    return assignments.map((assignment: any) => ({
      title: assignment.title,
      description: assignment.description,
      information: assignment.information,
      id: assignment.id,
      color: assignment.color,
      index: assignment.index,
      displayAssignment: false,
      type: assignment.type,
    }));
  };

  /**
   *
   * Submit handler
   *
   */
  const onSubmit: SubmitHandler<FieldValues> = async (formData) => {
    if (currentUser && currentUser.uid) {
      const chosenAssignmentsDetails = createChosenAssignmentsDetails(assignments, formData);

      const startDate =
        startDates && Array.isArray(startDates)
          ? startDates.find((date) => date.displayDate === formData.startDate)?.date?.toString() || ''
          : '';

      const _competition: Competition = {
        competitionName: DOMPurify.sanitize(
          formData.competitionName.charAt(0).toUpperCase() + formData.competitionName.slice(1),
        ),
        startDate: startDate,
        duration: formData.duration * 7,
        ownerId: formData.ownerId,
        assignmentsDetails: chosenAssignmentsDetails,
        allowCustomAssignment: true,
        passCode: formData.passCode !== '' ? DOMPurify.sanitize(formData.passCode) : null,
        teams: null,
        nrOfParticipants: 0,
        maxNrOfParticipants: 6,
        registrationOpen: 14,
        allowTeams: false,
      };

      try {
        await addNewCompetitionFirebase(_competition);

        navigate('/app/utmaningar');
      } catch (e) {
        dispatch(setErrorMsg(t('error_text_create_competition')));
      }
    }
  };

  /**
   * Setting startdates array
   */
  let startDates: StartDate[] = [];
  for (let i = 0; i < 30; i++) {
    startDates.push({
      date: dayjs().add(i, 'days').startOf('day').add(2, 'hours'),
      displayDate:
        i === 0
          ? t('select_value_today')
          : i === 1
          ? t('select_value_tomorrow')
          : dayjs().add(i, 'days').startOf('day').add(2, 'hours').format('D MMM'),
    });
  }

  /**
   *
   *
   *
   */
  return (
    <>
      <Box
        sx={{
          bgcolor: !isDarkMode && isDesktop ? 'white' : 'background.default',
          borderBottom: isDesktop ? '1px solid' : 'none',
          borderColor: 'custom.paperBorder',
          p: 6,
          pl: 68,
        }}
      >
        <Typography variant="h6" component="h1" fontWeight={600}>
          {t('title_create_competition')}
        </Typography>
      </Box>

      <Box sx={{ ml: { md: 66, xs: 0 }, p: 12 }}>
        <Box
          sx={{
            paddingTop: 2,
            paddingBottom: 2,
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
            <FormControl variant="outlined" fullWidth sx={{ mt: 1, mb: 4 }}>
              <Controller
                control={control}
                name="competitionName"
                rules={{ required: true }}
                render={({ field: { onChange, value, name } }) => (
                  <>
                    <label style={{ fontSize: '1rem', marginBottom: 2 }} htmlFor={name}>
                      {t('textfield_label_competition_name')}
                    </label>
                    <OutlinedInput
                      data-testid="input-competition-name"
                      sx={{ bgcolor: 'background.paper' }}
                      value={value}
                      onChange={onChange}
                      name={name}
                      type="text"
                      required={true}
                      id={name}
                      autoComplete="off"
                      error={!!errors.competitionName}
                    />
                  </>
                )}
              />
            </FormControl>

            <Box sx={{ mb: 4, display: 'flex', flexDirection: 'column', gap: 4 }}>
              <FormControl variant="outlined" sx={{ width: '100%' }}>
                <label style={{ fontSize: '1rem', marginBottom: 2 }} htmlFor="start-date-select">
                  {t('textfield_label_start_date')}
                </label>

                <Controller
                  name="startDate"
                  control={control}
                  render={({ field }) => (
                    <Select
                      data-testid="select-start-date"
                      {...field}
                      sx={{ bgcolor: 'background.paper' }}
                      labelId="start-date-label"
                      id="start-date-select"
                    >
                      {startDates.map((startDate, index) => (
                        <MenuItem
                          data-testid={`select-item-start-date-${index}`}
                          key={index}
                          value={startDate.displayDate}
                        >
                          {startDate.displayDate}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>

              <FormControl variant="outlined" sx={{ width: '100%' }}>
                <label style={{ fontSize: '1rem', marginBottom: 2 }} htmlFor="duration-select">
                  {t('label_duration')}
                </label>

                <Controller
                  name="duration"
                  control={control}
                  render={({ field }) => (
                    <Select
                      data-testid="select-duration"
                      {...field}
                      sx={{ bgcolor: 'background.paper' }}
                      labelId="duration-label"
                      id="duration-select"
                    >
                      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((index) => (
                        <MenuItem data-testid={`select-item-duration-${index}`} key={index} value={index}>
                          {index === 0
                            ? t('select_value_continous')
                            : `${index} ${index > 1 ? t('select_value_weeks') : t('select_value_week')}`}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Box>

            <FormControl variant="outlined" fullWidth sx={{ mt: 1, mb: 6 }}>
              <Controller
                control={control}
                name="passCode"
                render={({ field: { onChange, value, name } }) => (
                  <>
                    <label style={{ fontSize: '1rem', marginBottom: 2 }} htmlFor={name}>
                      {t('textfield_label_passcode')}
                    </label>
                    <OutlinedInput
                      data-testid="input-competition-passcode"
                      sx={{ bgcolor: 'background.paper' }}
                      value={value}
                      onChange={onChange}
                      name={name}
                      type="text"
                      id={name}
                      autoComplete="off"
                      error={!!errors.passCode}
                    />
                    {errors.passCode && typeof errors.passCode.message === 'string' && (
                      <FormHelperText sx={{ ml: 0 }} error={true}>
                        {errors.passCode.message}
                      </FormHelperText>
                    )}
                  </>
                )}
              />
            </FormControl>

            <Button
              data-testid="button-create-competition-complete"
              type="submit"
              fullWidth={!isDesktop}
              size="large"
              variant="contained"
              color="primary"
              sx={{ mt: 2, mb: 24 }}
            >
              {t('button_create_competition')}
            </Button>
          </form>
        </Box>
      </Box>
    </>
  );
};

export default CompetitionUsersCreateScreen;
