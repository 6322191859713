import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { FormHelperText, Grid, OutlinedInput, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import dayjs from 'dayjs';
import DOMPurify from 'dompurify';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { Controller, FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import SimpleCard from '../../components/SimpleCard';
import DialogAssignmentSimple from '../../components/dialogs/DialogAssignmentSimple';
import ListCard from '../../components/stylish/ListCard';
import { getAssignmentsFirebase } from '../../firebase/db/assignments';
import { addNewCompetitionFirebase } from '../../firebase/db/competitions';
import { setTeamScoresFirebase } from '../../firebase/db/teamScores';
import { readCurrentDarkModeState, readCurrentDeviceState, setErrorMsg } from '../../store/appSlice';
import { getCurrentUser } from '../../store/userSlice';
import { AssignmentDetails, Competition } from '../../utilities/types';

interface StartDate {
  date: dayjs.Dayjs;
  displayDate: string;
}

type CompetitionAdminCreateScreenProps = {};

const CompetitionAdminCreateScreen: React.FC<CompetitionAdminCreateScreenProps> = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      customAssignment: false,
      duration: 0,
      startDate: t('select_value_today'),
      competitionName: '',
      passCode: '',
      ownerId: '',
    },
  });

  const currentUser = useSelector(getCurrentUser);
  const isDesktop = useSelector(readCurrentDeviceState);
  const isDarkMode = useSelector(readCurrentDarkModeState);

  const [displayDialog, setDisplayDialog] = useState<boolean>(false);
  const [selectedAssignment, setSelectedAssignment] = useState<{ assignment: any; dbIndex: number } | null>(null);
  const [assignments, setAssignments] = useState<any>([]);
  const [teamNames, setTeamNames] = useState<string[]>([]);
  const [teamNameErrors, setTeamNameErrors] = useState<boolean[]>([]);
  const [allowTeams, setAllowTeams] = useState<boolean>(false);

  /**
   *  Lifecycle
   */
  useEffect(() => {
    const fetchAssignments = async () => {
      try {
        const fetchedAssignments = await getAssignmentsFirebase();

        let tempAssignments: any = [];

        fetchedAssignments?.forEach((doc) => {
          const data = doc.data();

          tempAssignments.push({
            id: doc.id,
            description: data.description,
            title: data.title,
            information: data.information,
            chosen: true,
            points: [0],
            color: data.color,
            index: data.index,
            displayAssignment: false,
            type: data.type,
          });
        });

        setAssignments(tempAssignments);
      } catch (errorMsg) {
        dispatch(setErrorMsg(t('error_text_general')));
      }
    };

    fetchAssignments();
  }, [dispatch]);

  /**
   * Team handlers
   *
   *
   */

  const handleAllowTeamsCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAllowTeams(event.target.checked);
  };

  const handleAddTeam = () => {
    const newTeamNames = Array(1).fill('');
    const newTeamNameErrors = Array(1).fill(false);
    setTeamNames([...teamNames, ...newTeamNames]);
    setTeamNameErrors([...teamNameErrors, ...newTeamNameErrors]);
  };

  const handleRemoveTeam = (index: number) => {
    const updatedTeams = [...teamNames];
    const updatedErrors = [...teamNameErrors];
    updatedTeams.splice(index, 1);
    updatedErrors.splice(index, 1);
    setTeamNames(updatedTeams);
    setTeamNameErrors(updatedErrors);
  };

  const handleTeamNameChange = (index: number, value: string) => {
    const updatedTeams = [...teamNames];
    updatedTeams[index] = DOMPurify.sanitize(value);
    setTeamNames(updatedTeams);
  };

  const validateTeamNames = () => {
    const newErrors = teamNames.map((name) => name.trim() === '');
    setTeamNameErrors(newErrors);
    if (newErrors.includes(true)) {
      return false;
    }
    return true;
  };

  /**
   *
   * Other methods
   */
  const showDialogAssignment = (assignment: any, i: number) => {
    setSelectedAssignment({ assignment: assignment, dbIndex: i });
    setDisplayDialog(true);
  };

  const onAssignmentChange = (id: string) => {
    const updatedAssignments = assignments.map((assignment: any) => {
      if (assignment.id === id) {
        return { ...assignment, chosen: !assignment.chosen };
      } else {
        return assignment;
      }
    });

    setAssignments(updatedAssignments);
  };

  const createChosenAssignmentsDetails = (assignments: any[], data: any): AssignmentDetails[] => {
    return assignments
      .filter((assignment: any) => assignment.chosen)
      .filter((assignment: any) => data.customAssignment || assignment.type !== 'custom')
      .map((assignment: any) => ({
        title: assignment.title,
        description: assignment.description,
        information: assignment.information,
        id: assignment.id,
        color: assignment.color,
        index: assignment.index,
        displayAssignment: false,
        type: assignment.type,
      }));
  };

  const createTeamScores = (teamNames: string[]): { [key: string]: { days: number; score: number } } => {
    const teamScores: { [key: string]: { days: number; score: number } } = {};

    teamNames.forEach((teamName) => {
      teamScores[teamName] = {
        days: 0,
        score: 0,
      };
    });

    return teamScores;
  };

  /**
   *
   * Submit handler
   *
   */
  const onSubmit: SubmitHandler<FieldValues> = async (formData) => {
    if (allowTeams && teamNames.length === 0) {
      alert('please add at least 1 team');
      return;
    }

    if (validateTeamNames() && currentUser && currentUser.uid) {
      const chosenAssignmentsDetails = createChosenAssignmentsDetails(assignments, formData);

      const startDate =
        startDates && Array.isArray(startDates)
          ? startDates.find((date) => date.displayDate === formData.startDate)?.date?.toString() || ''
          : '';

      const _competition: Competition = {
        competitionName: DOMPurify.sanitize(
          formData.competitionName.charAt(0).toUpperCase() + formData.competitionName.slice(1),
        ),
        startDate: startDate,
        duration: formData.duration * 7,
        ownerId: formData.ownerId,
        assignmentsDetails: chosenAssignmentsDetails,
        allowCustomAssignment: formData.customAssignment,
        passCode: formData.passCode !== '' ? DOMPurify.sanitize(formData.passCode) : null,
        teams: teamNames ? teamNames : null,
        nrOfParticipants: 0,
        maxNrOfParticipants: 6,
        registrationOpen: 14,
        allowTeams: allowTeams,
      };

      try {
        const newCompetitionRef = await addNewCompetitionFirebase(_competition);

        if (newCompetitionRef) {
          const teamScores = createTeamScores(teamNames);
          await setTeamScoresFirebase({ competitionId: newCompetitionRef.id, teamScores: teamScores });
        }

        navigate('/app/utmaningar');
      } catch (e) {
        dispatch(setErrorMsg(t('error_text_create_competition')));
      }
    }
  };

  /**
   * Setting startdates array
   */
  let startDates: StartDate[] = [];
  for (let i = 0; i < 30; i++) {
    startDates.push({
      date: dayjs().add(i, 'days').startOf('day').add(2, 'hours'),
      displayDate:
        i === 0
          ? t('select_value_today')
          : i === 1
          ? t('select_value_tomorrow')
          : dayjs().add(i, 'days').startOf('day').add(2, 'hours').format('D MMM'),
    });
  }

  /**
   *
   *
   *
   */
  return (
    <>
      <DialogAssignmentSimple
        selectedAssignment={selectedAssignment}
        displayDialog={displayDialog}
        setDisplayDialog={setDisplayDialog}
      />

      <Box
        sx={{
          bgcolor: !isDarkMode && isDesktop ? 'white' : 'background.default',
          borderBottom: isDesktop ? '1px solid' : 'none',
          borderColor: 'custom.paperBorder',
          p: 6,
          pl: 68,
        }}
      >
        <Typography variant="h6" component="h1" fontWeight={600}>
          {t('title_create_competition')}
        </Typography>
      </Box>

      <Box sx={{ ml: 66, p: 12 }}>
        <Grid container spacing={12} pt={2}>
          <Grid item xs={6}>
            <Box
              sx={{
                paddingTop: 2,
                paddingBottom: 2,
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
                <FormControl variant="outlined" fullWidth sx={{ mt: 1, mb: 4 }}>
                  <Controller
                    control={control}
                    name="competitionName"
                    rules={{ required: true }}
                    render={({ field: { onChange, value, name } }) => (
                      <>
                        <label style={{ fontSize: '1rem', marginBottom: 2 }} htmlFor={name}>
                          {t('textfield_label_competition_name')}
                        </label>
                        <OutlinedInput
                          data-testid="input-competition-name"
                          sx={{ bgcolor: 'background.paper' }}
                          value={value}
                          onChange={onChange}
                          name={name}
                          type="text"
                          required={true}
                          id={name}
                          autoComplete="off"
                          error={!!errors.competitionName}
                        />
                      </>
                    )}
                  />
                </FormControl>

                <Box sx={{ mb: 4, display: 'flex', flexDirection: 'column', gap: 4 }}>
                  <FormControl variant="outlined" sx={{ width: '100%' }}>
                    <label style={{ fontSize: '1rem', marginBottom: 2 }} htmlFor="start-date-select">
                      {t('textfield_label_start_date')}
                    </label>

                    <Controller
                      name="startDate"
                      control={control}
                      render={({ field }) => (
                        <Select
                          data-testid="select-start-date"
                          {...field}
                          sx={{ bgcolor: 'background.paper' }}
                          labelId="start-date-label"
                          id="start-date-select"
                        >
                          {startDates.map((startDate, index) => (
                            <MenuItem
                              data-testid={`select-item-start-date-${index}`}
                              key={index}
                              value={startDate.displayDate}
                            >
                              {startDate.displayDate}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormControl>

                  <FormControl variant="outlined" sx={{ width: '100%' }}>
                    <label style={{ fontSize: '1rem', marginBottom: 2 }} htmlFor="duration-select">
                      {t('label_duration')}
                    </label>

                    <Controller
                      name="duration"
                      control={control}
                      render={({ field }) => (
                        <Select
                          data-testid="select-duration"
                          {...field}
                          sx={{ bgcolor: 'background.paper' }}
                          labelId="duration-label"
                          id="duration-select"
                        >
                          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((index) => (
                            <MenuItem data-testid={`select-item-duration-${index}`} key={index} value={index}>
                              {index === 0
                                ? t('select_value_continous')
                                : `${index} ${index > 1 ? t('select_value_weeks') : t('select_value_week')}`}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Box>

                <SimpleCard styles={{ p: 4, pl: 2, mb: 4, mt: 1, bgcolor: 'white' }}>
                  <Controller
                    name="customAssignment"
                    control={control}
                    render={({ field }) => (
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <FormControlLabel
                          label={t('label_allow_custom_assignments')}
                          labelPlacement="start"
                          componentsProps={{ typography: { variant: 'caption', sx: { width: '100%', pr: 4 } } }}
                          control={<></>} // Assign an empty React element to the control prop
                        />
                        <Checkbox
                          data-testid="checkbox-competition-allow-custom"
                          {...field}
                          sx={{ '& .MuiSvgIcon-root': { fontSize: 28, mr: 2 } }}
                        />
                      </div>
                    )}
                  />
                </SimpleCard>

                <SimpleCard styles={{ py: 4, px: 3.5, mb: 4, mt: 1, bgcolor: 'white' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <FormControlLabel
                      label={t('label_compete_in_teams')}
                      labelPlacement="start"
                      componentsProps={{ typography: { variant: 'caption', sx: { width: '100%', pr: 4 } } }}
                      control={<></>}
                    />
                    <Checkbox
                      data-testid="checkbox-competition-allow-teams"
                      checked={allowTeams}
                      onChange={handleAllowTeamsCheckboxChange}
                      sx={{ '& .MuiSvgIcon-root': { fontSize: 28, mr: 2 } }}
                    />
                  </div>
                  {allowTeams && (
                    <>
                      {teamNames.map((teamName, index) => (
                        <Box key={index} mt={2} mb={4}>
                          <FormControl variant="outlined" fullWidth sx={{ mt: 1, mb: 2 }}>
                            <label style={{ fontSize: '1rem', marginBottom: 2 }} htmlFor="competition-name">
                              {`${t('label_team')} ${index + 1}`}
                            </label>
                            <OutlinedInput
                              data-testid={`input-competition-team-${index}`}
                              value={teamName}
                              onChange={(e) => handleTeamNameChange(index, e.target.value)}
                              fullWidth
                              id={`Team ${index + 1}`}
                              autoComplete={`Team ${index + 1}`}
                              name={`Team ${index + 1}`}
                              sx={{ bgcolor: 'background.paper' }}
                              error={Boolean(teamNameErrors[index])}
                            />
                            {teamNameErrors[index] && (
                              <FormHelperText error={true}>{t('error_team_name')}</FormHelperText>
                            )}
                          </FormControl>
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={() => handleRemoveTeam(index)}
                            data-testid="button-competition-remove-team"
                          >
                            {t('button_remove_team')}
                          </Button>
                        </Box>
                      ))}

                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAddTeam}
                        sx={{ mt: 2 }}
                        data-testid="button-competition-add-team"
                      >
                        {t('button_add_team')}
                      </Button>
                    </>
                  )}
                </SimpleCard>

                <FormControl variant="outlined" fullWidth sx={{ mt: 1, mb: 6 }}>
                  <Controller
                    control={control}
                    name="passCode"
                    render={({ field: { onChange, value, name } }) => (
                      <>
                        <label style={{ fontSize: '1rem', marginBottom: 2 }} htmlFor={name}>
                          {t('textfield_label_passcode')}
                        </label>
                        <OutlinedInput
                          data-testid="input-competition-passcode"
                          sx={{ bgcolor: 'background.paper' }}
                          value={value}
                          onChange={onChange}
                          name={name}
                          type="text"
                          id={name}
                          autoComplete="off"
                          error={!!errors.passCode}
                        />
                        {errors.passCode && typeof errors.passCode.message === 'string' && (
                          <FormHelperText sx={{ ml: 0 }} error={true}>
                            {errors.passCode.message}
                          </FormHelperText>
                        )}
                      </>
                    )}
                  />
                </FormControl>

                <FormControl variant="outlined" fullWidth sx={{ mt: 1, mb: 4 }}>
                  <Controller
                    control={control}
                    name="ownerId"
                    rules={{ required: false }}
                    render={({ field: { onChange, value, name } }) => (
                      <>
                        <label style={{ fontSize: '1rem', marginBottom: 2 }} htmlFor={name}>
                          {t('textfield_label_competition_owner')}
                        </label>
                        <OutlinedInput
                          data-testid="input-competition-owner-id"
                          sx={{ bgcolor: 'background.paper' }}
                          value={value}
                          onChange={onChange}
                          name={name}
                          type="text"
                          required={false}
                          id={name}
                          autoComplete="off"
                          error={!!errors.ownerId}
                        />
                      </>
                    )}
                  />
                </FormControl>

                <Button
                  data-testid="button-create-competition-complete"
                  type="submit"
                  fullWidth={!isDesktop}
                  size="large"
                  variant="contained"
                  color="primary"
                  sx={{ mt: 2, mb: 24 }}
                >
                  {t('button_create_competition')}
                </Button>
              </form>
            </Box>
          </Grid>

          <Grid item xs={6}>
            {/* List of assignments to choose from */}
            <Box mt={6}>
              <Typography variant="caption">{t('label_choose_assignments_create_competition')}</Typography>
              <List sx={{ display: 'flex', flexDirection: 'column' }}>
                {assignments &&
                  assignments.map((assignment: any, i: number) => {
                    if (assignment.type !== 'custom') {
                      return (
                        <ListCard
                          key={assignment.id}
                          onClick={() => onAssignmentChange(assignment.id)}
                          styles={{ order: assignment.index }}
                        >
                          <InfoOutlinedIcon
                            sx={{
                              mr: 4,
                            }}
                            onClick={(event) => {
                              event.stopPropagation();
                              showDialogAssignment(assignment, i);
                            }}
                          />
                          <ListItemText
                            primary={assignment.title[i18n.language === 'en' ? 'en' : 'se']}
                            secondary={
                              <Typography variant="body2" sx={{ fontSize: '0.7rem' }}>
                                {assignment.description[i18n.language === 'en' ? 'en' : 'se']}
                              </Typography>
                            }
                          />
                          <Checkbox
                            name={assignment.id}
                            edge="end"
                            checked={assignment.chosen ? true : false}
                            sx={{ '& .MuiSvgIcon-root': { fontSize: 28 }, mr: -1 }}
                            inputProps={{ 'aria-labelledby': 'label' }}
                          />
                        </ListCard>
                      );
                    } else return null;
                  })}
              </List>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default CompetitionAdminCreateScreen;
