import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import React, { Dispatch, SetStateAction } from 'react';

type Props = {
  displayDialog: boolean;
  setDisplayDialog: Dispatch<SetStateAction<boolean>>;
  description: string;
  img: string;
  alt: string;
};

const DialogBadge = ({ description, img, alt, displayDialog, setDisplayDialog }: Props) => {
  return (
    <Dialog
      open={Boolean(displayDialog)}
      onClose={() => {
        setDisplayDialog(false);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setDisplayDialog(false);
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Box display="flex" flexDirection="column" p={8} textAlign="center" gap={2}>
          <img src={img} alt={alt} width="100%" />
          <Typography component="p" variant="h6">
            {description}
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DialogBadge;
