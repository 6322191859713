import {
  EmailAuthProvider,
  UserCredential,
  createUserWithEmailAndPassword,
  deleteUser,
  reauthenticateWithCredential,
  reauthenticateWithRedirect,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from 'firebase/auth';

import { auth, provider } from './firebaseConfig';

export function signup(email: string, password: string): Promise<UserCredential> {
  return createUserWithEmailAndPassword(auth, email, password);
}

export function signin(email: string, password: string): Promise<UserCredential> {
  return signInWithEmailAndPassword(auth, email, password);
}

export function signout(): Promise<void> {
  return signOut(auth);
}

export function signInWithGoogle(): Promise<UserCredential> {
  return signInWithPopup(auth, provider);
}

export function deleteAccount(): Promise<void> {
  const currentUser = auth.currentUser;
  if (!currentUser) {
    return Promise.reject(new Error('No authenticated user'));
  }
  return deleteUser(currentUser);
}

export function reAuthWithPassword(password: string) {
  const currentUser = auth.currentUser;
  if (!currentUser?.email) {
    return Promise.reject(new Error('No authenticated user'));
  }
  const credentials = EmailAuthProvider.credential(currentUser.email, password);
  return reauthenticateWithCredential(currentUser, credentials);
}

export function reAuthWithGoogle() {
  const currentUser = auth.currentUser;
  if (!currentUser) {
    return Promise.reject(new Error('No authenticated user'));
  }
  return reauthenticateWithRedirect(currentUser, provider);
}
