import { Box } from '@mui/material';
import i18next from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';

import en from '../assets/flags/en.jpg';
import en_bw from '../assets/flags/en_bw.jpg';
import se from '../assets/flags/se.jpg';
import se_bw from '../assets/flags/se_bw.jpg';

const LanguageSwitcher: React.FC = () => {
  const { i18n } = useTranslation();

  const handleChange = (lng: string) => {
    localStorage.setItem('lang', lng);

    i18next.changeLanguage(lng, (err, t) => {
      if (err) return console.log('something went wrong loading', err);
      t('key'); // -> same as i18next.t
    });
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: 8,
        right: 24,
        display: 'flex',
        justifyContent: 'center',
        '&:hover': {
          cursor: 'pointer',
        },
      }}
    >
      <img
        data-testid="flag-settings-se"
        src={i18n.language === 'se' ? se : se_bw}
        width="30"
        height="18.75"
        alt="Swedish flag"
        style={{ borderRadius: '4px', marginRight: 8 }}
        onClick={() => handleChange('se')}
        title="se"
      />
      <img
        data-testid="flag-settings-en"
        src={i18n.language === 'en' ? en : en_bw}
        width="30"
        height="18.75"
        alt="English flag"
        title="en"
        style={{ borderRadius: '4px' }}
        onClick={() => handleChange('en')}
      />
    </Box>
  );
};

export default LanguageSwitcher;

/* <Box position="absolute" bottom="16px" left="0" display="flex" justifyContent="center" width="100%">
    <LanguageSwitcher />
  </Box> */
