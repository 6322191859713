import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DocumentData } from 'firebase/firestore';

import { Competition } from '../utilities/types';
import { RootState } from './store';

interface CompetitionState {
  currentCompetition?: Competition | DocumentData | null;
}

const initialState: CompetitionState = {
  currentCompetition: null,
};

const competitionSlice = createSlice({
  name: 'competitionSlice',
  initialState,
  reducers: {
    setCompetition: (
      state,
      action: PayloadAction<{ currentCompetition: Competition | null | DocumentData | undefined }>,
    ) => {
      state.currentCompetition = action.payload.currentCompetition || null;
    },
    unsetCompetition: (state) => {
      state.currentCompetition = null;
    },
  },
});
export const getCurrentCompetition = (state: RootState) => state.competitionSlice.currentCompetition;

export const { setCompetition, unsetCompetition } = competitionSlice.actions;

export default competitionSlice.reducer;
