import { Box } from '@mui/material';
import { t } from 'i18next';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import streak1 from '../../../assets/badges/streak1.svg';
import streak2 from '../../../assets/badges/streak2.svg';
import streak3 from '../../../assets/badges/streak3.svg';
import streak4 from '../../../assets/badges/streak4.svg';
import streak5 from '../../../assets/badges/streak5.svg';
import DialogBadge from '../../../components/dialogs/DialogBadge';
import { getCurrentCompetition } from '../../../store/competitionSlice';
import { getCurrentResults } from '../../../store/resultsSlice';
import { Badge } from '../../../utilities/types';
import BadgeContainer from './BadgeContainer';
import { getBadgeStyling } from './achievementsMethods';

export const streakBadges: Badge[] = [
  {
    img: streak1,
    alt: 'streak badge',
    label: 'label_badge_streak',
  },
  {
    img: streak2,
    alt: 'streak badge',
    label: 'label_badge_streak',
  },
  {
    img: streak3,
    alt: 'streak badge',
    label: 'label_badge_streak',
  },
  {
    img: streak4,
    alt: 'streak badge',
    label: 'label_badge_streak',
  },
  {
    img: streak5,
    alt: 'streak badge',
    label: 'label_badge_streak',
  },
];

interface DialogContent {
  description: string;
  img: string;
  alt: string;
}

interface AchievementsBadgesStreaksProps {}

const AchievementsBadgesStreaks: React.FC<AchievementsBadgesStreaksProps> = () => {
  const currentCompetition = useSelector(getCurrentCompetition);
  const currentResults = useSelector(getCurrentResults);

  const [displayDialog, setDisplayDialog] = useState<boolean>(false);
  const [dialogContent, setDialogContent] = useState<DialogContent>({
    description: '',
    img: '',
    alt: '',
  });

  /**
   *
   *
   */
  const streakConditions =
    currentCompetition?.duration > 0
      ? streakBadges.map((badge, i) => {
          return Math.round((currentCompetition?.duration / 5) * (1 + i));
        })
      : [7, 14, 28, 48, 100];

  /**
   *
   *
   */
  const getHighestStreak = () => {
    let streaksArray: any[] = [];

    if (currentCompetition && currentResults && currentResults.assignmentsResults) {
      Object.values(currentResults?.assignmentsResults).forEach((assignment: any) => {
        let streaks = assignment.points.reduce(
          // eslint-disable-next-line
          (res: any, n: any) => (
            // eslint-disable-next-line
            n ? res[res.length - 1]++ : res.push(0), res
          ),
          [0],
        );

        const longestStreak = Math.max(...streaks);
        streaksArray.push(longestStreak);
      });

      return Math.max(...streaksArray);
    } else {
      return 0;
    }
  };

  return (
    <>
      <DialogBadge
        description={dialogContent.description}
        alt={dialogContent.alt}
        img={dialogContent.img}
        displayDialog={displayDialog}
        setDisplayDialog={setDisplayDialog}
      />
      <BadgeContainer>
        {streakBadges.map((badge, i) => (
          <Box
            key={i}
            sx={{ ...getBadgeStyling(getHighestStreak(), streakConditions[i]) }}
            onClick={() => {
              setDialogContent({
                description: `${streakConditions[i]} ${t(badge.label)}`,
                img: badge.img,
                alt: badge.alt,
              });
              setDisplayDialog(true);
            }}
          >
            <img
              src={badge.img}
              alt={badge.alt}
              style={{
                position: 'absolute',
                padding: '4px',
                zIndex: '1',
                width: '100%',
                height: '100%',
              }}
            />
          </Box>
        ))}
      </BadgeContainer>
    </>
  );
};

export default AchievementsBadgesStreaks;
