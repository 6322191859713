import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import SimpleCard from '../../../components/SimpleCard';

interface ScoresPanelProps {
  totalPoints: number;
  totalDays: number;
  averageScore: number;
  averageDays: number;
}

function ScoresPanel({ totalPoints, totalDays, averageScore, averageDays }: ScoresPanelProps) {
  const { t } = useTranslation();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, flexGrow: 1 }}>
      <Box sx={{ display: 'flex', gap: 4, mt: 3, pb: 7 }}>
        <SimpleCard styles={{ flexGrow: 1 }}>
          <Typography component="h2" variant="caption">
            {t('label_total_score')}
          </Typography>
          <Typography component="h2" variant="h3" sx={{ fontWeight: 700 }}>
            {totalPoints}
          </Typography>
        </SimpleCard>

        <SimpleCard styles={{ flexGrow: 1 }}>
          <Typography component="h2" variant="caption">
            {t('label_average_score')}
          </Typography>
          <Typography component="h2" variant="h3" sx={{ fontWeight: 700 }}>
            {averageScore}
          </Typography>
        </SimpleCard>

        <SimpleCard styles={{ flexGrow: 1 }}>
          <Typography component="h2" variant="caption">
            {t('label_total_days')}
          </Typography>
          <Typography component="h2" variant="h3" sx={{ fontWeight: 700 }}>
            {totalDays}
          </Typography>
        </SimpleCard>

        <SimpleCard styles={{ flexGrow: 1 }}>
          <Typography component="h2" variant="caption">
            {t('label_average_dags')}
          </Typography>
          <Typography component="h2" variant="h3" sx={{ fontWeight: 700 }}>
            {averageDays}
          </Typography>
        </SimpleCard>
      </Box>
    </Box>
  );
}

export default ScoresPanel;
