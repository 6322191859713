import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { CustomAssignment } from '../utilities/types';
import { RootState } from './store';

interface CustomAssignmentState {
  currentCustomAssignment: CustomAssignment | null;
}

const initialState: CustomAssignmentState = {
  currentCustomAssignment: null,
};

const customAssignmentSlice = createSlice({
  name: 'customAssignmentSlice',
  initialState,
  reducers: {
    setCustomAssignment: (state, action: PayloadAction<CustomAssignment>) => {
      state.currentCustomAssignment = action.payload;
    },
    unsetCustomAssignment: (state) => {
      state.currentCustomAssignment = null;
    },
  },
});

export const { setCustomAssignment, unsetCustomAssignment } = customAssignmentSlice.actions;

export const getCurrentCustomAssignment = (state: RootState) => state.customAssignmentSlice.currentCustomAssignment;

export default customAssignmentSlice.reducer;
