import ListItem from '@mui/material/ListItem';
import React from 'react';

interface ListCardProps {
  children: React.ReactNode;
  styles?: { [key: string]: any };
  [key: string]: any;
}

const ListCard: React.FC<ListCardProps> = ({ children, styles, ...rest }) => {
  return (
    <ListItem
      sx={{
        mb: 4,
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
        border: '1px solid',
        borderColor: 'custom.paperBorder',
        bgcolor: 'background.paper',
        ...styles,
      }}
      {...rest}
    >
      {children}
    </ListItem>
  );
};

export default ListCard;
