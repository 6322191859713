import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const joinCompetitionEnglishTranslations = require('../join_competition_en.json');
const aboutScreenEnglishTranslations = require('../about_screen_en.json');
const errorsEnglishTranslations = require('../errors_en.json');
const onboardingEnglishTranslations = require('../onboarding_en.json');
const buttonsEnglishTranslations = require('../buttons_en.json');
const authEnglishTranslations = require('../auth_en.json');
const titlesEnglishTranslations = require('../titles_en.json');
const dialogsEnglishTranslations = require('../dialogs_en.json');
const restEnglishTranslations = require('../rest_en.json');

const joinCompetitionSwedishTranslations = require('../join_competition_se.json');
const aboutScreenSwedishTranslations = require('../about_screen_se.json');
const errorsSwedishTranslations = require('../errors_se.json');
const onboardingSwedishTranslations = require('../onboarding_se.json');
const buttonsSwedishTranslations = require('../buttons_se.json');
const authSwedishTranslations = require('../auth_se.json');
const titlesSwedishTranslations = require('../titles_se.json');
const dialogsSwedishTranslations = require('../dialogs_se.json');
const restSwedishTranslations = require('../rest_se.json');

const combinedEnglishTranslations = {
  ...joinCompetitionEnglishTranslations,
  ...aboutScreenEnglishTranslations,
  ...errorsEnglishTranslations,
  ...onboardingEnglishTranslations,
  ...buttonsEnglishTranslations,
  ...authEnglishTranslations,
  ...titlesEnglishTranslations,
  ...dialogsEnglishTranslations,
  ...restEnglishTranslations,
};

const combinedSwedishTranslations = {
  ...joinCompetitionSwedishTranslations,
  ...aboutScreenSwedishTranslations,
  ...errorsSwedishTranslations,
  ...onboardingSwedishTranslations,
  ...buttonsSwedishTranslations,
  ...authSwedishTranslations,
  ...titlesSwedishTranslations,
  ...dialogsSwedishTranslations,
  ...restSwedishTranslations,
};

const resources = {
  en: {
    translation: combinedEnglishTranslations,
  },
  se: {
    translation: combinedSwedishTranslations,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'se',
  fallbackLng: 'se',
});

export default i18n;
