import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import ModeEditRoundedIcon from '@mui/icons-material/ModeEditRounded';
import { Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { readCurrentSavingState } from '../../../store/appSlice';

interface AssignmentSubmitButtonProps {
  activeDayIndex: number;
  lastDayIndex: number;
  submitDay: () => {};
  isFuture: boolean;
}

const AssignmentSubmitButton = ({ activeDayIndex, lastDayIndex, submitDay, isFuture }: AssignmentSubmitButtonProps) => {
  const { t } = useTranslation();
  const isSaving = useSelector(readCurrentSavingState);
  const navigate = useNavigate();

  const isSubmitted = activeDayIndex < lastDayIndex;

  if (isSubmitted) {
    return (
      <Button
        data-testid="button-assignment-edit-day"
        variant="contained"
        size="small"
        onClick={() => {
          navigate('/app/redigera-aktiviteter', {
            replace: true,
            state: {
              activeDayIndex: activeDayIndex,
            },
          });
        }}
        endIcon={<ModeEditRoundedIcon />}
      >
        {t('button_edit_day')}
      </Button>
    );
  } else {
    return (
      <Button
        data-testid="button-assignment-submit-day"
        disabled={isSaving || isFuture || isSubmitted}
        variant={'contained'}
        size="small"
        sx={{ pb: 0, pt: 0, height: 32 }}
        disableElevation
        onClick={submitDay}
        endIcon={<ArrowUpwardRoundedIcon />}
      >
        {t('button_submit_day')}
      </Button>
    );
  }
};

export default AssignmentSubmitButton;
