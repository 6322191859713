import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import DOMPurify from 'dompurify';
import { DocumentData } from 'firebase/firestore';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { Controller, FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import SimpleCard from '../../components/SimpleCard';
import SortableTable from '../../components/SortableTable';
import { getOwnedCompetitionFirebase, updateCompetitionTeamsFirebase } from '../../firebase/db/competitions';
import { getScoresCompetitionFirebase } from '../../firebase/db/scores';
import { readCurrentDarkModeState, readCurrentDeviceState } from '../../store/appSlice';
import { getCurrentUser } from '../../store/userSlice';
import { Competition } from '../../utilities/types';

interface UserData {
  user: string;
  points: number;
  days: number;
  team: string;
}

const AdminOwnerScreen = () => {
  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm();

  const [usersData, setUsersData] = useState<UserData[]>([]);
  const [usersTeamData, setUsersTeamData] = useState<UserData[]>([]);

  const [selectedCompetition, setSelectedCompetition] = useState<Competition | DocumentData>();
  const [selectedTeam, setSelectedTeam] = useState<string>('');
  const [teamsForCompetition, setTeamsForCompetition] = useState<string[] | null>(null);
  const [tabValue, setTabValue] = React.useState(0);
  const [showCreateNewTeam, setShowCreateNewTeam] = React.useState(false);

  const handleChangeTab = (event: React.SyntheticEvent, newTabValue: number) => {
    setTabValue(newTabValue);
  };

  const isDesktop = useSelector(readCurrentDeviceState);
  const isDarkMode = useSelector(readCurrentDarkModeState);

  const navigate = useNavigate();
  const currentUser = useSelector(getCurrentUser);

  /**
   * Fetch competition data
   */
  useEffect(() => {
    async function getCompetitionData() {
      try {
        if (currentUser?.role !== 'owner') {
          navigate('/app', { replace: true });
        }

        const queryCompetitionsSnapshot = await getOwnedCompetitionFirebase(currentUser?.uid);

        let _competitions: DocumentData[] = [];

        queryCompetitionsSnapshot?.forEach((doc) => {
          _competitions.push({ competitionId: doc.id, ...(doc.data() as Competition | DocumentData) });
        });

        setSelectedCompetition(_competitions[0]);

        const scoresSnapshot = await getScoresCompetitionFirebase(_competitions[0].competitionId);

        let _usersData: UserData[] = [];

        scoresSnapshot?.forEach((doc) => {
          const scoresData: DocumentData = doc.data();
          const user = scoresData.nickName ?? '';
          const points = scoresData.score ?? 0;
          const days = scoresData.nrOfDaysSubmitted ?? 0;
          const team = scoresData.team;

          _usersData.push({ user, points, days, team });
        });

        const availableTeams: string[] = _competitions[0]?.teams;
        setTeamsForCompetition(availableTeams?.length > 0 ? availableTeams : null);

        setUsersData(_usersData);
        setUsersTeamData(_usersData.filter((user) => user.team === _competitions[0]?.teams[0]));
        setSelectedTeam(_usersData[0].team);
      } catch (e) {}
    }
    getCompetitionData();
  }, [currentUser, navigate]);

  /**
   *  Handle team change
   * @param event
   */
  const handleTeamChange = (event: SelectChangeEvent<string>) => {
    const teamName = event.target.value as string;
    setSelectedTeam(teamName);
    setUsersTeamData(usersData.filter((user) => user.team === teamName));
  };

  /**
   * Get average number, eg. for score or days
   *
   * @param score score or days
   * @param participants number of participants
   * @returns rounded number average
   */
  const getAverage = (score: number, participants: number): number => {
    return score && participants ? Math.round(score / participants) : 0;
  };

  const { totalPoints, totalDays } = usersData.reduce(
    (acc, currentValue) => {
      acc.totalPoints += currentValue.points;
      acc.totalDays += currentValue.days;
      return acc;
    },
    { totalPoints: 0, totalDays: 0 },
  );

  const { totalTeamPoints, totalTeamDays } = usersTeamData.reduce(
    (acc, currentValue) => {
      acc.totalTeamPoints += currentValue.points;
      acc.totalTeamDays += currentValue.days;
      return acc;
    },
    { totalTeamPoints: 0, totalTeamDays: 0 },
  );

  const handleDatabaseOperations = async (data: FieldValues) => {
    try {
      if (data.newTeamName !== '') {
        updateCompetitionTeamsFirebase({
          competitionId: selectedCompetition?.competitionId,
          teams: [...selectedCompetition?.teams, DOMPurify.sanitize(data.newTeamName)],
        });
      }
    } catch (error) {}
  };

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    if (data.newTeamName === '') {
      setError('team', {
        type: 'manual',
        message: t('error_select_team') || 'Error',
      });
    } else {
      await handleDatabaseOperations(data);
    }
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
      tabIndex: 0,
    };
  }

  return (
    <>
      <Box
        sx={{
          bgcolor: !isDarkMode && isDesktop ? 'white' : 'background.default',
          borderBottom: isDesktop ? '1px solid' : 'none',
          borderColor: 'custom.paperBorder',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            p: 8,
            pb: 0,
            pl: 68,
          }}
        >
          <Typography variant="h5" component="h1" sx={{ fontWeight: '700', mb: 4 }}>
            {t('tab_owner')}
          </Typography>

          <Tabs value={tabValue} onChange={handleChangeTab} aria-label="meny">
            <Tab label={t('tab_overview')} {...a11yProps(0)} />
            <Tab label={t('tab_teams')} {...a11yProps(1)} />
          </Tabs>
        </Box>
      </Box>
      <Box sx={{ ml: 66, p: 4 }}>
        {tabValue === 0 ? (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, flexGrow: 1 }}>
            <Box sx={{ display: 'flex', gap: 4, mt: 3, pb: 7 }}>
              <SimpleCard styles={{ flexGrow: 1 }}>
                <Typography component="h2" variant="caption">
                  {t('label_total_score')}
                </Typography>
                <Typography component="h2" variant="h3" sx={{ fontWeight: 700 }}>
                  {totalPoints}
                </Typography>
              </SimpleCard>

              <SimpleCard styles={{ flexGrow: 1 }}>
                <Typography component="h2" variant="caption">
                  {t('label_average_score')}
                </Typography>
                <Typography component="h2" variant="h3" sx={{ fontWeight: 700 }}>
                  {getAverage(totalPoints, usersData.length)}
                </Typography>
              </SimpleCard>

              <SimpleCard styles={{ flexGrow: 1 }}>
                <Typography component="h2" variant="caption">
                  {t('label_total_days')}
                </Typography>
                <Typography component="h2" variant="h3" sx={{ fontWeight: 700 }}>
                  {totalDays}
                </Typography>
              </SimpleCard>

              <SimpleCard styles={{ flexGrow: 1 }}>
                <Typography component="h2" variant="caption">
                  {t('label_average_dags')}
                </Typography>
                <Typography component="h2" variant="h3" sx={{ fontWeight: 700 }}>
                  {getAverage(totalDays, usersData.length)}
                </Typography>
              </SimpleCard>
            </Box>
            <SortableTable data={usersData} />
          </Box>
        ) : (
          <>
            {showCreateNewTeam && (
              <SimpleCard
                styles={{
                  mb: 4,
                }}
              >
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 2 }}>
                    <FormControl variant="outlined">
                      <Controller
                        control={control}
                        name="newTeamName"
                        defaultValue=""
                        rules={{ required: true }}
                        render={({ field: { onChange, value, name } }) => (
                          <>
                            <label style={{ fontSize: '1rem', marginBottom: 2 }} htmlFor={name}>
                              {t('label_new_team')}
                            </label>
                            <OutlinedInput
                              data-testid="input-join-competition-new-team-name"
                              sx={{ bgcolor: 'background.paper', minWidth: 400 }}
                              value={value}
                              onChange={onChange}
                              name={name}
                              type="text"
                              required={true}
                              id={name}
                              autoComplete="off"
                              error={!!errors.newTeamName}
                            />
                            {errors.nickName && typeof errors.nickName.message === 'string' && (
                              <FormHelperText sx={{ ml: 0 }} error={true}>
                                {errors.nickName.message}
                              </FormHelperText>
                            )}
                          </>
                        )}
                      />
                    </FormControl>

                    <Button
                      data-testid="button-add-team"
                      variant="contained"
                      color="primary"
                      size="large"
                      type="submit"
                    >
                      {t('button_create_new_team')}
                    </Button>
                  </Box>
                </form>
              </SimpleCard>
            )}

            <Box sx={{ display: 'flex', justifyContent: 'space-between', py: 2 }}>
              <FormControl variant="outlined">
                <Select
                  labelId="choose-team-label"
                  id="choose-team-select"
                  value={selectedTeam}
                  defaultValue=""
                  onChange={handleTeamChange}
                  sx={{ bgcolor: 'background.paper', minWidth: 180 }}
                  disabled={!teamsForCompetition}
                >
                  {teamsForCompetition?.map((team, i) => (
                    <MenuItem key={`${team}-${i}`} value={team}>
                      {team}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {!showCreateNewTeam && (
                <Button
                  data-testid="button-show-add-team"
                  variant="contained"
                  color="primary"
                  size="medium"
                  onClick={() => setShowCreateNewTeam(true)}
                >
                  {t('button_create_new_team')}
                </Button>
              )}
            </Box>

            <Box sx={{ display: 'flex', gap: 4, mt: 3, pb: 7 }}>
              <SimpleCard styles={{ flexGrow: 1 }}>
                <Typography component="h2" variant="caption">
                  {t('label_total_score')}
                </Typography>
                <Typography component="h2" variant="h3" sx={{ fontWeight: 700 }}>
                  {totalTeamPoints}
                </Typography>
              </SimpleCard>

              <SimpleCard styles={{ flexGrow: 1 }}>
                <Typography component="h2" variant="caption">
                  {t('label_average_score')}
                </Typography>
                <Typography component="h2" variant="h3" sx={{ fontWeight: 700 }}>
                  {getAverage(totalTeamPoints, usersTeamData.length)}
                </Typography>
              </SimpleCard>

              <SimpleCard styles={{ flexGrow: 1 }}>
                <Typography component="h2" variant="caption">
                  {t('label_total_days')}
                </Typography>
                <Typography component="h2" variant="h3" sx={{ fontWeight: 700 }}>
                  {totalTeamDays}
                </Typography>
              </SimpleCard>

              <SimpleCard styles={{ flexGrow: 1 }}>
                <Typography component="h2" variant="caption">
                  {t('label_average_dags')}
                </Typography>
                <Typography component="h2" variant="h3" sx={{ fontWeight: 700 }}>
                  {getAverage(totalTeamDays, usersTeamData.length)}
                </Typography>
              </SimpleCard>
            </Box>

            <SortableTable data={usersTeamData} />
          </>
        )}
      </Box>
    </>
  );
};

export default AdminOwnerScreen;
