import CloseIcon from '@mui/icons-material/Close';
import { Button, DialogActions } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  displayDialog: boolean;
  setDisplayDialog: Dispatch<SetStateAction<boolean>>;
  title: string;
  content: string;
  callback?: () => void;
};

const DialogSimple = ({ title, content, displayDialog, setDisplayDialog, callback }: Props) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={Boolean(displayDialog)}
      onClose={() => {
        setDisplayDialog(false);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        {title}
        <IconButton
          aria-label="close"
          onClick={() => {
            setDisplayDialog(false);
            if (callback) {
              callback();
            }
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Typography variant="body1">{content}</Typography>
      </DialogContent>

      {callback && (
        <DialogActions sx={{ p: 4 }}>
          <Button
            variant="contained"
            onClick={() => {
              callback();
            }}
          >
            {t('button_continue')}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default DialogSimple;
