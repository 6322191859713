import CloseIcon from '@mui/icons-material/Close';
import { Button, DialogActions, FormControl, FormHelperText, OutlinedInput } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import DOMPurify from 'dompurify';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  displayDialog: boolean;
  setDisplayDialog: Dispatch<SetStateAction<boolean>>;
  callback: (title: string) => void;
};

const DialogCustomAssignment = ({ displayDialog, setDisplayDialog, callback }: Props) => {
  const [title, setTitle] = useState('');
  const [titleError, setTitleError] = useState('');
  const { t } = useTranslation();

  const validateTitle = () => {
    if (title === '') {
      setTitleError(`${t('error_custom_assignment_title')}`);
      return;
    }

    setTitleError('');

    callback(DOMPurify.sanitize(title));
    setDisplayDialog(false);
  };

  return (
    <Dialog
      open={Boolean(displayDialog)}
      onClose={() => {
        setDisplayDialog(false);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        {t('dialog_custom_assignment_title')}
        <IconButton
          aria-label="close"
          onClick={() => {
            setDisplayDialog(false);
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ minWidth: '310px' }}>
        <FormControl variant="outlined" fullWidth sx={{ mt: 1, mb: 2 }}>
          <label style={{ fontSize: '1rem', marginBottom: 2 }} htmlFor="title">
            {t('textfield_label_assignment_title')}
          </label>
          <OutlinedInput
            data-testid="dialog-input-custom-assignment"
            fullWidth
            id="title"
            name="title"
            autoComplete="off"
            value={title}
            onChange={(event) => setTitle(event.target.value)}
            error={Boolean(titleError)}
            sx={{ bgcolor: 'background.default' }}
          />
          {titleError && <FormHelperText error={true}>{titleError}</FormHelperText>}
        </FormControl>
      </DialogContent>

      <DialogActions sx={{ p: 4 }}>
        <Button data-testid="dialog-button-save-custom-assignment" variant="contained" onClick={validateTitle}>
          {t('button_save_assignment')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogCustomAssignment;
