import { Button, ListItemText } from '@mui/material';
import Typography from '@mui/material/Typography';
import { DocumentData } from 'firebase/firestore';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import DialogCustomAssignment from '../../../components/dialogs/DialogCustomAssignment';
import ListCard from '../../../components/stylish/ListCard';
import { setCustomAssignmentFirebase } from '../../../firebase/db/assignments';
import { setErrorMsg, setIsSaving } from '../../../store/appSlice';
import { setCustomAssignment } from '../../../store/customAssignmentSlice';
import { AssignmentDetails, AssignmentResult, Competition, Results, User } from '../../../utilities/types';

interface CustomAssignmentCardProps {
  competition: Competition | DocumentData;
  customAssignment: AssignmentResult;
  user: User;
  results: Results | DocumentData;
}

const CustomAssignmentCard = ({ user, results, competition, customAssignment }: CustomAssignmentCardProps) => {
  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();
  const lang = i18n.language === 'en' ? 'en' : 'se';

  const [displayCustomAssignmentDialog, setDisplayCustomAssignmentDialog] = useState<boolean>(false);

  /**
   * Save title for user custom assignment
   *
   */
  async function saveCustomAssignment(title: string) {
    dispatch(setIsSaving(true));

    try {
      if (user?.uid) {
        await setCustomAssignmentFirebase({
          userId: user.uid,
          competitionId: results?.competitionId,
          title: title,
        });

        dispatch(
          setCustomAssignment({
            uid: user.uid,
            competitionId: results?.competitionId,
            title: title,
          }),
        );
      }
    } catch (error) {
      dispatch(setErrorMsg(t('error_text_general')));
    } finally {
      dispatch(setIsSaving(false));
    }
  }

  return (
    <>
      <DialogCustomAssignment
        displayDialog={displayCustomAssignmentDialog}
        setDisplayDialog={setDisplayCustomAssignmentDialog}
        callback={saveCustomAssignment}
      />

      <ListCard
        styles={{
          p: 3,
          pl: 5,
          mb: 3,
          bgcolor: 'background.paper',
        }}
      >
        <ListItemText
          primary={
            <Typography
              variant="body1"
              sx={{ fontWeight: 600 }}
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              {
                competition.assignmentsDetails.find(
                  (details: AssignmentDetails) => customAssignment?.id === details?.id,
                )?.title[lang]
              }
            </Typography>
          }
          secondary={
            <Typography
              variant="caption"
              sx={{ fontWeight: 500 }}
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              {
                competition.assignmentsDetails.find(
                  (details: AssignmentDetails) => customAssignment?.id === details?.id,
                )?.description[lang]
              }
            </Typography>
          }
        />

        <Button
          data-testid="button-add-custom-assignment"
          onClick={() => {
            setDisplayCustomAssignmentDialog(true);
          }}
          variant="outlined"
          name={`assignment`}
          color="primary"
        >
          {t('button_add_assignment')}
        </Button>
      </ListCard>
    </>
  );
};

export default CustomAssignmentCard;
