import { Box } from '@mui/material';
import { t } from 'i18next';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import bronze from '../../../assets/badges/bronze.svg';
import gold from '../../../assets/badges/gold.svg';
import iron from '../../../assets/badges/iron.svg';
import silver from '../../../assets/badges/silver.svg';
import wood from '../../../assets/badges/wood.svg';
import DialogBadge from '../../../components/dialogs/DialogBadge';
import { getCurrentCompetition } from '../../../store/competitionSlice';
import { getCurrentResults } from '../../../store/resultsSlice';
import { Badge } from '../../../utilities/types';
import BadgeContainer from './BadgeContainer';
import { getBadgeStyling, getPointsOnlyArrays, getSumPointsOnlyArrays, getTotalPoints } from './achievementsMethods';

const pointBadges: Badge[] = [
  {
    img: wood,
    alt: 'wood badge',
    label: 'label_badge_points',
  },
  {
    img: iron,
    alt: 'iron badge',
    label: 'label_badge_points',
  },
  {
    img: bronze,
    alt: 'bronze badge',
    label: 'label_badge_points',
  },
  {
    img: silver,
    alt: 'silver badge',
    label: 'label_badge_points',
  },
  {
    img: gold,
    alt: 'gold badge',
    label: 'label_badge_points',
  },
];

interface DialogContent {
  description: string;
  img: string;
  alt: string;
}

interface AchievementsBadgesPointsProps {}

const AchievementsBadgesPoints: React.FC<AchievementsBadgesPointsProps> = () => {
  const currentCompetition = useSelector(getCurrentCompetition);
  const currentResults = useSelector(getCurrentResults);

  const [displayDialog, setDisplayDialog] = useState<boolean>(false);
  const [dialogContent, setDialogContent] = useState<DialogContent>({
    description: '',
    img: '',
    alt: '',
  });

  /**
   *
   *
   */
  const pointsConditions =
    currentCompetition?.duration > 0
      ? pointBadges.map((badge, i) =>
          Math.round(
            (currentCompetition?.duration / 5) *
              (currentCompetition?.assignmentsDetails.length > 8 ? 8 : currentCompetition?.assignmentsDetails.length) *
              (1 + i) *
              0.8,
          ),
        )
      : [50, 100, 200, 500, 1000];

  const pointsOnlyArrays = getPointsOnlyArrays(currentResults);
  const sumPointsOnlyArrays = getSumPointsOnlyArrays(pointsOnlyArrays);
  const totalPoints = getTotalPoints(sumPointsOnlyArrays);

  return (
    <>
      <DialogBadge
        description={dialogContent.description}
        alt={dialogContent.alt}
        img={dialogContent.img}
        displayDialog={displayDialog}
        setDisplayDialog={setDisplayDialog}
      />
      <BadgeContainer>
        {pointBadges.map((badge, i) => (
          <Box
            data-testid={`badge-result-points-${i}`}
            key={i}
            sx={{ ...getBadgeStyling(totalPoints, pointsConditions[i]) }}
            onClick={() => {
              setDialogContent({
                description: `${pointsConditions[i]} ${t(badge.label)}`,
                img: badge.img,
                alt: badge.alt,
              });
              setDisplayDialog(true);
            }}
          >
            <img
              src={badge.img}
              alt={badge.alt}
              style={{
                position: 'absolute',
                padding: '4px',
                zIndex: '1',
                width: '100%',
                height: '100%',
              }}
            />
          </Box>
        ))}
      </BadgeContainer>
    </>
  );
};

export default AchievementsBadgesPoints;
