import {
  DocumentData,
  QuerySnapshot,
  WithFieldValue,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';

import { db } from '../firebaseConfig';

/**
 *
 * fetch score from a single user
 *
 */
export const getScoreFirebase = async (userId: string): Promise<DocumentData | undefined> => {
  const scoresSnap = await getDoc(doc(db, 'scores', userId));

  if (scoresSnap?.exists() && scoresSnap.data()) {
    return scoresSnap.data();
  }

  return undefined;
};

/**
 *
 * fetch scores from team
 *
 */
type TeamData = {
  competitionId: string;
  team: number;
};

export const getScoresSingleTeamFirebase = async ({
  competitionId,
  team,
}: TeamData): Promise<QuerySnapshot<DocumentData> | undefined> => {
  const queryScores = query(
    collection(db, 'scores'),
    where('competitionId', '==', competitionId),
    where('team', '==', team),
  );

  const querySnapshotScores = await getDocs(queryScores);

  if (!querySnapshotScores.empty) {
    return querySnapshotScores;
  }
  return undefined;
};

/**
 *
 * fetch scores from a competition
 *
 */

export const getScoresCompetitionFirebase = async (
  competitionId: string,
): Promise<QuerySnapshot<DocumentData> | undefined> => {
  const queryScores = query(collection(db, 'scores'), where('competitionId', '==', competitionId));

  const querySnapshotScores = await getDocs(queryScores);

  if (!querySnapshotScores.empty) {
    return querySnapshotScores;
  }
  return undefined;
};

/**
 *
 * Update existing score
 *
 */
type NewUserScoreData = {
  userId: string;
  score: number;
  nrOfDaysSubmitted: number;
};

export const updateUserScoreFirebase = async ({
  userId,
  score,
  nrOfDaysSubmitted,
}: NewUserScoreData): Promise<string> => {
  await updateDoc(doc(db, 'scores', userId), {
    score: score,
    nrOfDaysSubmitted: nrOfDaysSubmitted,
  });

  return 'user-score-updated';
};

/**
 *
 * Set custom assignment
 *
 */
type UserScoreData = {
  userId: string;
  scoreData: WithFieldValue<DocumentData>;
};

export const setUserScoreFirebase = async ({ userId, scoreData }: UserScoreData): Promise<string> => {
  await setDoc(doc(db, 'scores', userId), scoreData);

  return 'user-score-set';
};

/**
 *
 * Updating existing Score being anonymous
 *
 */
type AnonymousScoreData = {
  userId: string;
  isAnonymous: boolean;
};

export const updateIsAnonymousScoreFirebase = async ({ userId, isAnonymous }: AnonymousScoreData): Promise<string> => {
  const userScoreDoc = doc(db, 'scores', userId);

  await updateDoc(userScoreDoc, {
    isAnonymous: isAnonymous,
  });

  return 'user-score-updated';
};
