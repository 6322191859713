import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import { Box, Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import TopBarContainer from './TopBarContainer';

interface Props {
  hasBackButton?: boolean;
  backButtonCallback?: () => void;
  rightAction?: React.ReactNode;
  title?: string | React.ReactNode;
  position?: 'fixed' | 'relative' | 'absolute' | 'sticky' | 'static';
}

const TopBar: React.FC<Props> = ({ hasBackButton, backButtonCallback, rightAction, title, position }) => {
  const navigate = useNavigate();

  return (
    <TopBarContainer position={position}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
        {hasBackButton && (
          <Button
            data-testid="back-button"
            aria-label="back"
            variant="outlined"
            sx={{
              ml: -1,
              zIndex: '1100',
            }}
            onClick={
              backButtonCallback
                ? backButtonCallback
                : () => {
                    navigate(-1);
                  }
            }
            startIcon={<ArrowBackIosRoundedIcon />}
          >
            {t('button_back')}
          </Button>
        )}

        {title && (
          <Typography
            variant="h6"
            component="h1"
            sx={{
              flexGrow: 1,
              pl: 2,
              letterSpacing: 2,
              fontWeight: 600,
            }}
          >
            {title}
          </Typography>
        )}
        <Box>{rightAction}</Box>
      </Box>
    </TopBarContainer>
  );
};

export default TopBar;
