import { Box } from '@mui/material';
import React from 'react';

interface SimpleCardProps {
  children: React.ReactNode;
  styles?: { [key: string]: any };
}

const SimpleCard: React.FC<SimpleCardProps> = ({ children, styles, ...rest }) => {
  return (
    <Box
      sx={{
        p: 5,
        borderRadius: 2,
        bgcolor: 'background.paper',
        border: '1px solid',
        borderColor: 'custom.paperBorder',
        ...styles,
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default SimpleCard;
