import WhatshotTwoToneIcon from '@mui/icons-material/WhatshotTwoTone';
import { Box, Typography } from '@mui/material';
import React from 'react';

import { AssignmentResult } from '../../../utilities/types';

interface AssignmentStreakProps {
  assignmentResult: AssignmentResult;
  assignmentPoint: number;
}

const AssignmentStreak: React.FC<AssignmentStreakProps> = ({ assignmentResult, assignmentPoint }) => {
  let streaks: number[] =
    assignmentResult.points.slice(0, -1).reduce(
      (res: number[], n: number) => {
        if (n) {
          res[res.length - 1]++;
        } else {
          res.push(0);
        }
        return res;
      },
      [0],
    ) || [];

  let currentStreak = streaks[streaks.length - 1];

  if (currentStreak && currentStreak > 2) {
    return (
      <Box mr={2} display="flex" alignItems="center">
        <Typography
          variant="body2"
          sx={assignmentPoint === 0 ? { fontSize: '1rem', color: '#bdc3c7' } : { fontSize: '1rem' }}
        >
          {assignmentPoint === 1 ? currentStreak + 1 : currentStreak}
        </Typography>
        <WhatshotTwoToneIcon
          sx={assignmentPoint === 0 ? { fontSize: '1rem', color: '#bdc3c7' } : { fontSize: '1rem', color: '#f1c40f' }}
        />
      </Box>
    );
  } else return null;
};

export default AssignmentStreak;
