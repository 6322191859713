import React from 'react';
import { Navigate } from 'react-router-dom';

interface PrivateRouteProps {
  authenticated: boolean;
  children: JSX.Element;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ authenticated, children }) => {
  if (!authenticated) {
    return <Navigate to="/app/login" replace />;
  }

  return children;
};

export default PrivateRoute;
