import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import { readCurrentDeviceState } from '../store/appSlice';

interface ToggleValue {
  value: string;
  label: string;
}

interface TogglyBarProps {
  selectedValue: string;
  toggleValues: ToggleValue[];
  handleToggleValue: (event: any, newToggleValue: string) => void;
}

const TogglyBar: React.FC<TogglyBarProps> = ({ selectedValue, toggleValues, handleToggleValue }) => {
  const isDesktop = useSelector(readCurrentDeviceState);
  if (!toggleValues) return null;
  return (
    <ToggleButtonGroup
      sx={{
        mb: 2,
        bgcolor: 'background.paper',
        border: '1px solid',
        borderColor: 'custom.inputBorder',
        borderRadius: 16,
        mt: 4,
      }}
      fullWidth={!isDesktop}
      value={selectedValue}
      exclusive
      onChange={handleToggleValue}
      aria-label="toggle-buttons"
      color="primary"
    >
      {toggleValues.map((toggleValue) => (
        <ToggleButton
          key={toggleValue.label}
          size="small"
          sx={{ m: 1, border: 'none', pl: 6, pr: 6, width: '100%', borderRadius: 24 }}
          value={toggleValue.value}
          data-testid={`toggle-button-${toggleValue.value}`}
          aria-label={toggleValue.label}
          style={{ whiteSpace: 'nowrap', borderRadius: 24 }}
        >
          {toggleValue.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default TogglyBar;
