import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { AssignmentDetails } from '../../utilities/types';

type Props = {
  selectedAssignment: { assignment: AssignmentDetails; dbIndex: number } | null;
  displayDialog: boolean;
  setDisplayDialog: Dispatch<SetStateAction<boolean>>;
};

const DialogAssignmentSimple = ({ selectedAssignment, displayDialog, setDisplayDialog }: Props) => {
  const { i18n, t } = useTranslation();

  const lang = i18n.language === 'en' ? 'en' : 'se';

  return (
    <Dialog
      open={Boolean(displayDialog)}
      onClose={() => {
        setDisplayDialog(false);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        {selectedAssignment?.assignment && selectedAssignment?.assignment.title[lang]}
        <IconButton
          aria-label="close"
          onClick={() => {
            setDisplayDialog(false);
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        {selectedAssignment?.assignment && (
          <>
            <Typography variant="body1" sx={{ mb: 2 }}>
              {selectedAssignment?.assignment.description[lang]}
            </Typography>
            <Typography variant="body2">{selectedAssignment?.assignment.information[lang]}</Typography>
          </>
        )}
      </DialogContent>
      <DialogActions sx={{ p: 4 }}>
        <Button
          disableRipple
          variant="contained"
          autoFocus
          onClick={() => {
            setDisplayDialog(false);
          }}
        >
          {t('button_close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogAssignmentSimple;
