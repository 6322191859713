import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DocumentData } from 'firebase/firestore';

import { JoinCompetitionData } from '../utilities/types';
import { RootState } from './store';

interface JoinCompetitionState {
  currentJoinCompetitionData?: JoinCompetitionData | DocumentData | null;
}

const initialState: JoinCompetitionState = {
  currentJoinCompetitionData: null,
};

const joinCompetitionSlice = createSlice({
  name: 'joinCompetitionSlice',
  initialState,
  reducers: {
    setJoinCompetitionData: (
      state,
      action: PayloadAction<{
        currentJoinCompetitionData: JoinCompetitionData | null | DocumentData;
      }>,
    ) => {
      state.currentJoinCompetitionData = action.payload.currentJoinCompetitionData || null;
    },
    unsetJoinCompetitionData: (state) => {
      state.currentJoinCompetitionData = null;
    },
  },
});

export const getCurrentJoinCompetitionData = (state: RootState) =>
  state.joinCompetitionSlice.currentJoinCompetitionData;

export const { setJoinCompetitionData, unsetJoinCompetitionData } = joinCompetitionSlice.actions;

export default joinCompetitionSlice.reducer;
