import { Box, Button, MobileStepper } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { useWindowWidth } from '@react-hook/window-size';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import handClap from '../../assets/onboarding/handClap.svg';
import handClapDark from '../../assets/onboarding/handClapDark.svg';
import onboardingAssignments from '../../assets/onboarding/onboardingAssignments.svg';
import onboardingAssignmentsDark from '../../assets/onboarding/onboardingAssignmentsDark.svg';
// import onboardingCustom from '../../assets/onboarding/onboardingCustom.svg';
// import onboardingCustomDark from '../../assets/onboarding/onboardingCustomDark.svg';
import onboardingGoal from '../../assets/onboarding/onboardingGoal.svg';
import onboardingGoalDark from '../../assets/onboarding/onboardingGoalDark.svg';
import onboardingHide from '../../assets/onboarding/onboardingHide.svg';
import onboardingHideDark from '../../assets/onboarding/onboardingHideDark.svg';
import onboardingShow from '../../assets/onboarding/onboardingShow.svg';
import onboardingShowDark from '../../assets/onboarding/onboardingShowDark.svg';
import { readCurrentDarkModeState, readCurrentDeviceState } from '../../store/appSlice';

interface Step {
  heading: JSX.Element;
  img: JSX.Element;
  description: JSX.Element;
}

type DialogOnboardingProps = {
  displayDialog: boolean;
  setDisplayDialog: Dispatch<SetStateAction<boolean>>;
  competitionName: string;
  callback?: () => void;
  fullScreen?: boolean;
  allowCustomAssignment: boolean;
};

const DialogOnboarding = ({
  competitionName,
  displayDialog,
  setDisplayDialog,
  fullScreen = false,
  allowCustomAssignment,
}: DialogOnboardingProps) => {
  const { t } = useTranslation();
  const windowWidth = useWindowWidth();
  const isDesktop = useSelector(readCurrentDeviceState);
  const isDarkMode = useSelector(readCurrentDarkModeState);

  const [activeStep, setActiveStep] = React.useState(0);

  const navigate = useNavigate();

  const [imgSrc, setImgSrc] = useState(isDarkMode ? onboardingHideDark : onboardingHide);

  useEffect(() => {
    const interval = setInterval(() => {
      setImgSrc((prevSrc) => {
        if (prevSrc === onboardingHide) return onboardingShow;
        if (prevSrc === onboardingShow) return onboardingHide;
        if (prevSrc === onboardingHideDark) return onboardingShowDark;
        if (prevSrc === onboardingShowDark) return onboardingHideDark;
        return prevSrc;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [isDarkMode]);

  const generateImgElement = (imgDetails: any): JSX.Element => (
    <img
      src={imgDetails.src}
      alt={imgDetails.alt}
      width={imgDetails.windowWidth / 1.5}
      height={imgDetails.windowWidth / 1.5}
      style={{
        width: '100%',
        maxWidth: 360,
        maxHeight: 360,
      }}
    />
  );

  const generateSteps = (t: (key: string) => string, competitionName: string, windowWidth: number): Step[] => [
    {
      heading: (
        <Typography variant="h5" component="h1" mt={4} fontWeight={600}>{`${t(
          'heading_onboarding_step1',
        )}`}</Typography>
      ),
      img: generateImgElement({
        src: isDarkMode ? handClapDark : handClap,
        alt: 'decorative illustration',
        windowWidth: windowWidth,
      }),
      description: (
        <Typography variant="h6" fontWeight={400} component="p">{`${t(
          'description_onboarding_step1',
        )} ${competitionName}!`}</Typography>
      ),
    },
    {
      img: generateImgElement({
        src: isDarkMode ? onboardingAssignmentsDark : onboardingAssignments,

        alt: 'Decorative Illustration',
        windowWidth: windowWidth,
      }),
      heading: (
        <Typography variant="h5" component="h1" mt={4} fontWeight={600}>
          {t('heading_onboarding_step2')}
        </Typography>
      ),
      description: (
        <Typography variant="h6" fontWeight={400} component="p">
          {t('description_onboarding_step2')}
        </Typography>
      ),
    },
    {
      img: generateImgElement({
        src: isDarkMode ? onboardingGoalDark : onboardingGoal,

        alt: 'Decorative Illustration',
        windowWidth: windowWidth,
      }),
      heading: (
        <Typography variant="h5" component="h1" mt={4} fontWeight={600}>
          {t('heading_onboarding_step3')}
        </Typography>
      ),
      description: (
        <Typography variant="h6" fontWeight={400} component="p">
          {t('description_onboarding_step3')}
        </Typography>
      ),
    },
    {
      img: generateImgElement({
        src: imgSrc,
        alt: 'Decorative Illustration',
        windowWidth: windowWidth,
      }),
      heading: (
        <Typography variant="h5" component="h1" mt={4} fontWeight={600}>
          {t('heading_onboarding_step4')}
        </Typography>
      ),
      description: (
        <Typography variant="h6" fontWeight={400} component="p">
          {t('description_onboarding_step4')}
        </Typography>
      ),
    },
    // {
    //   img: generateImgElement({
    //     src: isDarkMode ? onboardingCustomDark : onboardingCustom,
    //     alt: 'Decorative Illustration',
    //     windowWidth: windowWidth,
    //   }),
    //   heading: (
    //     <Typography variant="h5" component="h1" mt={4} fontWeight={600}>
    //       {t('heading_onboarding_step5')}
    //     </Typography>
    //   ),
    //   description: (
    //     <Typography variant="h6" fontWeight={400} component="p">
    //       {t('description_onboarding_step5')}
    //     </Typography>
    //   ),
    // },
  ];

  const steps = generateSteps(t, competitionName, windowWidth);
  const maxSteps = allowCustomAssignment ? steps.length : steps.length - 1;

  const handleNext = () => {
    if (maxSteps - 1 === activeStep) {
      navigate('/app/utmaningar', { replace: true });
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handlePrevious = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={Boolean(displayDialog)}
      onClose={() => {
        setDisplayDialog(false);
      }}
      sx={{
        backgroundImage: 'unset',
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: isDesktop ? 'flex-start' : 'space-between',
            maxWidth: 400,
            height: '100%',
            width: '100%',
          }}
        >
          <Box sx={{ minHeight: isDesktop ? '560px' : 'initial' }}>
            <Box sx={{ width: '100%', mb: 2, display: 'flex', justifyContent: 'center' }}>{steps[activeStep].img}</Box>
            <Box sx={{ width: '100%', mt: 4 }}>{steps[activeStep].heading}</Box>
            <Box sx={{ width: '100%', mt: 3 }}>{steps[activeStep].description}</Box>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
            <MobileStepper
              sx={{ bgcolor: 'background.paper' }}
              variant="dots"
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              nextButton={<div></div>}
              backButton={<div></div>}
            />

            {maxSteps - 1 !== activeStep ? (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mb: 2, mt: isDesktop ? 4 : 0 }}>
                <Button data-testid="button-competition-onboarding-next" variant="contained" onClick={handleNext}>
                  {t('button_next')}
                </Button>

                <Button variant="outlined" onClick={handlePrevious} disabled={activeStep === 0}>
                  {t('button_previous')}
                </Button>
              </Box>
            ) : (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mb: 2 }}>
                <Button
                  data-testid="button-competition-onboarding-get-started"
                  variant="contained"
                  onClick={handleNext}
                >
                  {t('button_get_started')}
                </Button>

                <Button variant="outlined" onClick={handlePrevious} disabled={activeStep === 0}>
                  {t('button_previous')}
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DialogOnboarding;
