import { Box, Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { readCurrentIPhoneState } from '../../../store/appSlice';

interface JoinCompetitionButtonsProps {
  isDesktop: boolean;
  customContinueText?: string;
  proceedToNextStep?: (setGoal: boolean) => void;
  showSkipButton: boolean;
  extraContent?: React.ReactNode;
}

const JoinCompetitionButtons: React.FC<JoinCompetitionButtonsProps> = ({
  isDesktop,
  proceedToNextStep,
  showSkipButton,
  customContinueText,
  extraContent,
}) => {
  const { t } = useTranslation();

  const isIPhone = useSelector(readCurrentIPhoneState);

  return (
    <>
      {!isDesktop ? (
        <Box
          onClick={(e) => {
            e.stopPropagation();
          }}
          sx={{
            width: '100%',
            p: 4,
            pb: isIPhone ? 12 : 8,
            position: 'fixed',
            borderTop: '1px solid',
            borderColor: 'custom.paperBorder',
            bottom: 0,
            left: 0,
            bgcolor: 'background.default',
            zIndex: 9999,
          }}
        >
          {extraContent && extraContent}

          <Button
            data-testid="button-join-competition-continue"
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            size="large"
            onClick={() => {
              if (proceedToNextStep) proceedToNextStep(true);
            }}
          >
            {customContinueText ? t(customContinueText) : t('button_save_and_continue')}
          </Button>
          {showSkipButton && (
            <Button
              data-testid="button-join-competition-skip"
              type="submit"
              fullWidth
              variant="outlined"
              color="primary"
              size="large"
              sx={{ mt: 4 }}
              onClick={() => {
                if (proceedToNextStep) proceedToNextStep(false);
              }}
            >
              {t('button_skip')}
            </Button>
          )}
        </Box>
      ) : (
        <Box
          sx={{
            pb: 12,
          }}
        >
          {extraContent && extraContent}

          <Button
            data-testid="button-join-competition-continue"
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            size="large"
            onClick={() => {
              if (proceedToNextStep) proceedToNextStep(true);
            }}
          >
            {customContinueText ? t(customContinueText) : t('button_save_and_continue')}
          </Button>
          {showSkipButton && (
            <Button
              data-testid="button-join-competition-skip"
              type="submit"
              fullWidth
              variant="outlined"
              color="primary"
              size="large"
              sx={{ mt: 4 }}
              onClick={() => {
                if (proceedToNextStep) proceedToNextStep(false);
              }}
            >
              {t('button_skip')}
            </Button>
          )}
        </Box>
      )}
    </>
  );
};

export default JoinCompetitionButtons;
