import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from '@mui/material';
import React, { useEffect, useState } from 'react';

interface UserData {
  user: string;
  points: number;
  days: number;
  team: string;
}

interface SortData {
  key: keyof UserData;
  direction: 'asc' | 'desc';
}

interface Props {
  data?: UserData[]; // Make the data prop optional
}

const SortableTable: React.FC<Props> = ({ data = [] }) => {
  const [sortedData, setSortedData] = useState<UserData[]>(data);
  const [sortConfig, setSortConfig] = useState<SortData>({ key: 'user', direction: 'desc' });

  useEffect(() => {
    setSortedData(data);
    setSortConfig({ key: 'user', direction: 'desc' });
  }, [data]);

  const handleSort = (column: keyof UserData) => {
    const direction = sortConfig.key === column && sortConfig.direction === 'asc' ? 'desc' : 'asc';
    setSortConfig({ key: column, direction });

    const sorted = [...sortedData].sort((a, b) => {
      const valueA = a[column];
      const valueB = b[column];

      if (typeof valueA === 'string' && typeof valueB === 'string') {
        // Case-insensitive string comparison
        const stringA = valueA.toLowerCase();
        const stringB = valueB.toLowerCase();

        if (stringA < stringB) return direction === 'asc' ? -1 : 1;
        if (stringA > stringB) return direction === 'asc' ? 1 : -1;
        return 0;
      } else if (typeof valueA === 'number' && typeof valueB === 'number') {
        // Numeric comparison
        return direction === 'asc' ? valueA - valueB : valueB - valueA;
      } else {
        // Handle other data types, e.g., return 0 for non-comparable types
        return 0;
      }
    });

    setSortedData(sorted);
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel>Index</TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={sortConfig.key === 'user'}
                direction={sortConfig.key === 'user' ? sortConfig.direction : 'asc'}
                onClick={() => handleSort('user')}
              >
                User
              </TableSortLabel>
            </TableCell>
            <TableCell size="small">
              <TableSortLabel
                active={sortConfig.key === 'days'}
                direction={sortConfig.key === 'days' ? sortConfig.direction : 'asc'}
                onClick={() => handleSort('days')}
              >
                Days
              </TableSortLabel>
            </TableCell>
            <TableCell size="small">
              <TableSortLabel
                active={sortConfig.key === 'points'}
                direction={sortConfig.key === 'points' ? sortConfig.direction : 'asc'}
                onClick={() => handleSort('points')}
              >
                Points
              </TableSortLabel>
            </TableCell>
            <TableCell size="small">
              <TableSortLabel
                active={sortConfig.key === 'team'}
                direction={sortConfig.key === 'team' ? sortConfig.direction : 'asc'}
                onClick={() => handleSort('team')}
              >
                Team
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData.length ? (
            sortedData.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{row.user}</TableCell>
                <TableCell>{row.days}</TableCell>
                <TableCell>{row.points}</TableCell>
                <TableCell>{row.team}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell>No data available</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SortableTable;
