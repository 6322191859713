export const translateDay = (day:string, language:string) => {
  switch (day) {
    case 'Mo':
      return language === 'se' ? 'Mån' : 'Mon';
    case 'Tu':
      return language === 'se' ? 'Tis' : 'Tue';
    case 'We':
      return language === 'se' ? 'Ons' : 'Wed';
    case 'Th':
      return language === 'se' ? 'Tor' : 'Thu';
    case 'Fr':
      return language === 'se' ? 'Fre' : 'Fri';
    case 'Sa':
      return language === 'se' ? 'Lör' : 'Sat';
    case 'Su':
      return language === 'se' ? 'Sön' : 'Sun';
    default:
      return language === 'se' ? 'Mån' : 'Mon';
  }
};
