import { Box } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import { readCurrentDeviceState } from '../../../store/appSlice';

interface Props {
  children?: React.ReactNode;
}

const BadgeContainer: React.FC<Props> = ({ children }) => {
  const isDesktop = useSelector(readCurrentDeviceState);

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', pl: 2, pr: 2, mt: 2, maxWidth: isDesktop ? '400px' : 'inherit' }}>
      {children}
    </Box>
  );
};

export default BadgeContainer;
