import { Box, Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import i18next from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';

import broccotrophybubble from '../../assets/onboarding/broccotrophybubble.svg';

type DialogWelcomeProps = {
  displayDialog: boolean;
  callBack: () => void;
};

const DialogWelcome = ({ displayDialog, callBack }: DialogWelcomeProps) => {
  const { t } = useTranslation();

  const handleChange = (lng: string) => {
    localStorage.setItem('lang', lng);

    i18next.changeLanguage(lng, (err, t) => {
      if (err) return console.log('something went wrong loading', err);
      t('key'); // -> same as i18next.t
    });
  };

  return (
    <Dialog
      fullScreen
      open={Boolean(displayDialog)}
      sx={{
        backgroundImage: 'unset',
      }}
      aria-labelledby="alert-dialog-welcome-page"
      aria-describedby="alert-dialog-welcome-page"
    >
      <DialogContent sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            maxWidth: 400,
            height: '100%',
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Box sx={{ width: '100%', mb: 2, display: 'flex', justifyContent: 'center' }}>
              <img src={broccotrophybubble} width="300" height="300" alt="" />
            </Box>
            <Typography variant="h4" component="h1" textAlign="center">
              {t('dialog_title_welcome_to_halsokuppen')}
            </Typography>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4, mb: 2, mt: 8, width: '100%' }}>
              <Typography variant="body2" textAlign="center">
                {t('dialog_label_choose_language')}
              </Typography>
              <Button
                data-testid="button-welcome-select-language-swedish"
                variant="outlined"
                onClick={() => {
                  handleChange('se');
                  callBack();
                }}
              >
                {t('button_language_swedish')}
              </Button>

              <Button
                variant="outlined"
                onClick={() => {
                  handleChange('en');
                  callBack();
                }}
              >
                {t('button_language_english')}
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DialogWelcome;
