import CloseIcon from '@mui/icons-material/Close';
import { Button, DialogActions } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { deleteDoc, doc } from 'firebase/firestore';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { getCompetitionFirebase, updateCompetitionNrOfParticipantsFirebase } from '../../firebase/db/competitions';
import { getScoreFirebase } from '../../firebase/db/scores';
import { getAllTeamScoresFirebase, updateTeamScoresFirebase } from '../../firebase/db/teamScores';
import { db } from '../../firebase/firebaseConfig';
import { unsetCompetition } from '../../store/competitionSlice';
import { unsetCustomAssignment } from '../../store/customAssignmentSlice';
import { unsetDays } from '../../store/daysHandlerSlice';
import Loader from '../Loader';

type Props = {
  displayDialog: boolean;
  setDisplayDialog: Dispatch<SetStateAction<boolean>>;
  title: string;
  content: string;
  currentUser: any;
  currentResults: any;
};

const DialogCompetitionLeave = ({
  title,
  content,
  displayDialog,
  setDisplayDialog,
  currentUser,
  currentResults,
}: Props) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  const resetCompetition = () => {
    dispatch(unsetCompetition());
    dispatch(unsetCustomAssignment());
    dispatch(unsetDays());
  };

  const leaveCompetition = async () => {
    try {
      if (currentUser?.uid && currentResults?.team) {
        const participantScore = await getScoreFirebase(currentUser?.uid);
        const _teamScores = await getAllTeamScoresFirebase(currentResults?.competitionId);
        if (_teamScores && participantScore && currentResults) {
          updateTeamScoresFirebase({
            competitionId: currentResults?.competitionId,
            team: currentResults?.team,
            days: _teamScores[currentResults?.team]?.days - participantScore?.nrOfDaysSubmitted,
            score: _teamScores[currentResults?.team]?.score - participantScore?.score,
          });
        }
      }

      setIsLoading(true);
      await deleteDoc(doc(db, 'scores', `${currentUser?.uid}`));
      await deleteDoc(doc(db, 'results', `${currentUser?.uid}`));
      await deleteDoc(doc(db, 'customAssignments', `${currentUser?.uid}`));

      if (currentResults) {
        const _competition = await getCompetitionFirebase(currentResults.competitionId);

        await updateCompetitionNrOfParticipantsFirebase({
          competitionId: currentResults.competitionId,
          nrOfParticipants: _competition?.nrOfParticipants + -1,
        });
      }

      resetCompetition();
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <Loader />}

      <Dialog
        open={Boolean(displayDialog)}
        onClose={() => {
          setDisplayDialog(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          {title}
          <IconButton
            aria-label="close"
            onClick={() => {
              setDisplayDialog(false);
            }}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Typography variant="body1">{content}</Typography>
        </DialogContent>
        <DialogActions sx={{ p: 4 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setDisplayDialog(false);
            }}
          >
            {t('button_cancel')}
          </Button>
          <Button
            data-testid="button-confirm-leave-competition"
            variant="contained"
            color="error"
            onClick={() => {
              leaveCompetition();
              setDisplayDialog(false);
            }}
          >
            {t('button_continue')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogCompetitionLeave;
