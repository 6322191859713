import { LinearProgress, Typography } from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import { useSelector } from 'react-redux';

import SimpleCard from '../../../components/SimpleCard';
import { getCurrentCompetition } from '../../../store/competitionSlice';
import { getCurrentResults } from '../../../store/resultsSlice';

interface AchievementsProgressProps {
  duration: number;
}

const AchievementsProgress: React.FC<AchievementsProgressProps> = ({ duration }) => {
  const currentResults = useSelector(getCurrentResults);
  const currentCompetition = useSelector(getCurrentCompetition);

  let nrOfDaysSubmitted: number | undefined;
  let competitionIsCompleted: boolean | undefined;
  let progressDays: number | undefined;

  if (currentResults?.assignmentsResults[0].points) {
    nrOfDaysSubmitted = currentResults?.assignmentsResults[0].points.length - 1;
    competitionIsCompleted = nrOfDaysSubmitted > currentCompetition?.duration;
    progressDays = competitionIsCompleted ? 100 : (nrOfDaysSubmitted / currentCompetition?.duration) * 100;
  }

  return (
    <SimpleCard>
      <Typography data-testid="text-number-of-days" component="h3" variant="caption" mb={1}>
        {competitionIsCompleted
          ? t('progress_label_competition_completed')
          : `${t('label_competition_day')} ${nrOfDaysSubmitted} ${t('label_of')} ${duration}`}
      </Typography>
      <LinearProgress sx={{ borderRadius: 3 }} variant="determinate" value={progressDays} color="primary" />
    </SimpleCard>
  );
};

export default AchievementsProgress;
